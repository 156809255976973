import React from 'react';
import { useParams } from 'react-router-dom';
import { DataGrid } from '@mui/x-data-grid';
import { useTheme } from '@mui/material/styles';
import { useAuth } from './AuthContext';
import { Container, Box, Button, Typography } from '@mui/material';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import TitleAndInfo from './TitleAndInfo';
import { exportToCsv } from '../utils/exportToCsv';

const PriceTargetBySymbol = ({ data }) => {
    const { user } = useAuth();
    const { symbol } = useParams();
    const theme = useTheme();

    // Transform the nested data structure into a flat array
    const priceTargets = React.useMemo(() => {
        if (!data || !Array.isArray(data)) return [];

        return data.flatMap(item =>
            item.news.map(newsItem => {
                const percentageChange = ((newsItem.priceTarget - newsItem.priceWhenPosted) / newsItem.priceWhenPosted) * 100;
                return {
                    ...newsItem,
                    analystCompany: item.analystCompany || 'Unknown',
                    symbol: symbol,
                    percentageChange
                };
            })
        );
    }, [data, symbol]);

    const columns = [
        {
            field: 'publishedDate',
            headerName: 'Published Date',
            flex: 1,
            minWidth: 150,
            valueFormatter: (params) => new Date(params.value).toLocaleDateString()
        },
        {
            field: 'analystCompany',
            headerName: 'Analyst Company',
            flex: 1,
            minWidth: 150
        },
        {
            field: 'analystName',
            headerName: 'Analyst Name',
            flex: 1,
            minWidth: 150
        },
        {
            field: 'newsTitle',
            headerName: 'News Title',
            flex: 2,
            minWidth: 250,
            resizable: true
        },
        {
            field: 'priceTarget',
            headerName: 'Price Target',
            flex: 1,
            minWidth: 120
        },
        {
            field: 'priceWhenPosted',
            headerName: 'Price When Posted',
            flex: 1,
            minWidth: 150
        },
        {
            field: 'percentageChange',
            headerName: 'Upside/Downside(%)',
            flex: 1,
            minWidth: 180,
            valueFormatter: (params) => {
                if (typeof params.value === 'number') {
                    return `${params.value.toFixed(2)}%`;
                }
                return 'N/A';
            },
            cellClassName: (params) =>
                params.value > 0
                    ? 'upside'
                    : params.value < 0
                        ? 'downside'
                        : '',
            renderCell: (params) => (
                <span style={{
                    color: params.value > 0
                        ? theme.palette.positive.main
                        : params.value < 0
                            ? theme.palette.negative.main
                            : theme.palette.lightGray.main
                }}>
                    {typeof params.value === 'number' ? `${params.value.toFixed(2)}%` : 'N/A'}
                </span>
            )
        },
        {
            field: 'newsPublisher',
            headerName: 'News Publisher',
            flex: 1,
            minWidth: 150
        },
    ];

    const handleExport = () => {
        exportToCsv('price_targets', columns, priceTargets);
    };

    if (!priceTargets.length) {
        return (
            <Container maxWidth="xl">
                <Box display="flex" justifyContent="center" alignItems="center" minHeight="200px">
                    <Typography variant="h6" color="textSecondary">
                        No price target data available for {symbol}
                    </Typography>
                </Box>
            </Container>
        );
    }

    return (
        <Container maxWidth="xl">
            {/* Optional: Commented out GaugeChart section as in original code */}
            {/*  
            <Grid container justifyContent="center" alignItems="center" spacing={2} mt={4} mb={4}>
                <Grid item xs={12} sm={6} md={4}>
                    <GaugeChart style={{ width: '100%', height: '100%' }} />
                </Grid>
            </Grid>
            */}

            <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                flexDirection={{ xs: 'column', sm: 'row' }}
                mb={2}
                gap={2}
            >
                <TitleAndInfo
                    titleTop=""
                    title=""
                    info="Latest price targets from various analysts"
                />
                {user.plan.name === 'Premium' && data.length > 0 && (
                    <Button
                        variant="contained"
                        color="primary"
                        startIcon={<FileDownloadIcon />}
                        onClick={handleExport}
                    >
                        Export
                    </Button>
                )}
            </Box>

            <DataGrid
                rows={priceTargets}
                columns={columns}
                getRowId={(row) => row._id}
                disableColumnMenu
                pagination
                paginationPosition="middle"
                initialState={{
                    pagination: { paginationModel: { pageSize: 10 } },
                    sorting: { sortModel: [{ field: 'publishedDate', sort: 'desc' }] },
                }}
                pageSizeOptions={[5, 10, 25]}
                sx={{
                    '& .MuiDataGrid-cell': { fontSize: '1rem' },
                    '& .MuiDataGrid-columnHeaders': { fontSize: '0.8rem' },
                    '& .upside': { color: theme.palette.positive.main },
                    '& .downside': { color: theme.palette.negative.main }
                }}
                autoHeight
            />
        </Container>
    );
};

export default PriceTargetBySymbol;