import React, { useState } from 'react';
import { useNavigate, Link as RouterLink } from 'react-router-dom';
import CreateModal from './CreateModal';
import DeleteModal from './DeleteModal';
import RenameModal from './RenameModal';
import {
    Snackbar,
    Modal,
    Typography,
    Button,
    AppBar,
    Tabs,
    Tab,
    Toolbar,
    Box,
    Menu,
    MenuItem,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Card,
    CardContent,
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { Delete as DeleteIcon } from '@mui/icons-material';
import BuildIcon from '@mui/icons-material/Build';
import EditIcon from '@mui/icons-material/Edit';
import Tooltip from '@mui/material/Tooltip';
import { useTheme } from '@mui/material/styles';
import AddPortfolioAsset from './AddPortfolioAsset';
import { exportToCsv } from '../utils/exportToCsv';
import ChangeCurrencyModal from './ChangeCurrencyModal';
import formatCurrency from '../utils/formatCurrency';
import currencySymbols from '../utils/currencySymbols'

const PortfolioTable = ({
    user,
    portfolios,
    selectedPortfolio,
    selectedTabIndex,
    handleTabChange,
    handleCreatePortfolio,
    handlePortfolioNameUpdate,
    handlePortfolioDelete,
    handleAssetDelete,
    fetchPortfolios,
    baseUrl,
    handleBaseCurrencyUpdate,
    setShowUpgradePopup
}) => {
    const navigate = useNavigate();
    const theme = useTheme();
    const [showSnackbar, setShowSnackbar] = useState(false);
    const [snackbarMessage,] = useState('');
    const [showCreateModal, setShowCreateModal] = useState(false);
    const [showRenameModal, setShowRenameModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [portfolioToDelete, setPortfolioToDelete] = useState(null);
    const [showAddPortfolioAssetModal, setShowAddPortfolioAssetModal] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);
    const [selectedAsset, setSelectedAsset] = useState(null);
    const [anchorEl, setAnchorEl] = useState(null);
    const [showCurrencyModal, setShowCurrencyModal] = useState(false);
    const [, setNewCurrency] = useState('');

    const handleMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const handleCreateModalOpen = () => {
        if (user.plan.name === 'Free' && portfolios.length >= 1) {
            setShowUpgradePopup(true);
        } else {
            setShowCreateModal(true);
        }
    };

    const handleCreateModalClose = () => {
        setShowCreateModal(false);
    };

    const handleCreatePortfolioAndClose = async (itemName) => {
        await handleCreatePortfolio(itemName);
        handleCreateModalClose();
    };

    // const handleUpgradePopupClose = () => {
    //     setShowUpgradePopup(false);
    // };

    const handleCloseModalAndRefetchData = () => {
        setShowAddPortfolioAssetModal(false);
        fetchPortfolios();
    };

    const handleAssetEdit = (row) => {
        if (!selectedPortfolio) return;
        const filteredHoldings = selectedPortfolio.holdings.filter(holding => holding.symbol === row.symbol);
        setSelectedAsset({ ...row, holdings: filteredHoldings });
        setShowEditModal(true);
    };

    const handleAssetDeleteAndCloseModal = async (portfolioId, holdingId) => {
        await handleAssetDelete(portfolioId, holdingId);
        setShowEditModal(false);
    };

    const handleDeleteModalOpen = (portfolio) => {
        setPortfolioToDelete(portfolio);
        setShowDeleteModal(true);
    };

    const handleDeleteModalClose = () => {
        setShowDeleteModal(false);
        setPortfolioToDelete(null);
    };

    const handlePortfolioDeleteAndClose = async () => {
        if (portfolioToDelete) {
            await handlePortfolioDelete(portfolioToDelete);
            handleDeleteModalClose();
        }
    };

    const handleCurrencyModalOpen = () => {
        setNewCurrency(selectedPortfolio.baseCurrency);
        setShowCurrencyModal(true);
        handleMenuClose();
    };

    const handleCurrencyModalClose = () => {
        setShowCurrencyModal(false);
    };

    const handleCurrencyChange = (newCurrency) => {
        if (selectedPortfolio && newCurrency) {
            handleBaseCurrencyUpdate(selectedPortfolio._id, newCurrency);
        }
    };

    // const handleCurrencySubmit = () => {
    //     handleBaseCurrencyUpdate(selectedPortfolio._id, newCurrency);
    //     handleCurrencyModalClose();
    // };

    const columns = [
        {
            field: 'symbol',
            headerName: 'Company',
            minWidth: 180,
            flex: 1,
            renderCell: (params) => (
                <div>
                    <RouterLink
                        to={`${baseUrl}/asset/${params.row.symbol}`}
                        style={{ color: theme.palette.primary.main, textDecoration: 'none' }}
                    >
                        {params.row.symbol}
                    </RouterLink>
                    <div style={{ fontSize: '0.9em', color: '#888' }}>{params.row.name}</div>
                </div>
            ),
            align: 'left',
            headerAlign: 'left',
        },
        { field: 'quantity', headerName: 'Position', minWidth: 100, flex: 1 },
        {
            field: 'quotePrice',
            headerName: 'Price',
            minWidth: 100,
            flex: 1,
            renderCell: (params) => `${currencySymbols[params.row.currency]}${params.value}`
        },
        //,{
        //     field: 'quotePrice',
        //     headerName: 'Price',
        //     minWidth: 100,
        //     flex: 1,
        //     renderCell: (params) => formatCurrency(params.value, params.row.currency)
        // },
        { field: 'currency', headerName: 'currency', minWidth: 100, flex: 1 },
        {
            field: 'change',
            headerName: 'Daily P&L',
            minWidth: 100,
            flex: 1,
            renderCell: (params) => (
                <div style={{ color: params.value > 0 ? theme.palette.positive.main : params.value < 0 ? theme.palette.negative.main : theme.palette.lightGray.main }}>
                    {(params.row.quantity * params.value).toFixed(2)}
                </div>
            ),
        },
        {
            field: 'changesPercentage',
            headerName: '%Change',
            minWidth: 100,
            flex: 1,
            renderCell: (params) => (
                <div style={{ color: params.value > 0 ? theme.palette.positive.main : params.value < 0 ? theme.palette.negative.main : theme.palette.lightGray.main }}>
                    {parseFloat(params.value).toFixed(2)}%
                </div>
            ),
        },
        {
            field: 'avgPrice', headerName: 'AVG Price', minWidth: 100, flex: 1,
            renderCell: (params) => (
                <div>
                    {parseFloat(params.value).toFixed(2)}
                </div>
            ),
        },
        {
            field: 'totalValue', headerName: 'Value', minWidth: 100, flex: 1,
            renderCell: (params) => (
                <div>
                    {parseFloat(params.value).toFixed(2)}
                </div>
            ),
        },
        {
            field: 'totalInvestment',
            headerName: 'Cost',
            minWidth: 100,
            flex: 1,
            renderCell: (params) => formatCurrency(params.value, params.row.currency)
        },
        {
            field: 'PnL',
            headerName: 'P&L',
            minWidth: 100,
            flex: 1,
            renderCell: (params) => (
                <div style={{ color: params.value > 0 ? theme.palette.success.main : params.value < 0 ? theme.palette.error.main : theme.palette.text.primary }}>
                    {formatCurrency(params.value, params.row.currency)}
                </div>
            ),
        },
        {
            field: 'actions',
            headerName: 'Actions',
            minWidth: 100,
            flex: 1,
            renderCell: (params) => (
                <div>
                    <Tooltip title="Edit transactions">
                        <EditIcon onClick={() => handleAssetEdit(params.row)} />
                    </Tooltip>
                    <Tooltip title="Delete all">
                        <DeleteIcon onClick={() => handleAssetDelete(selectedPortfolio._id, params.row._id)} />
                    </Tooltip>
                </div>
            ),
        },
    ];

    const aggregatedHoldings = (holdings) => {
        const combinedHoldings = [];
        holdings.forEach((holding) => {
            const existingHolding = combinedHoldings.find(h => h.symbol === holding.symbol);
            if (existingHolding) {
                existingHolding.quantity += holding.quantity;
                existingHolding.totalInvestment += holding.quantity * holding.price;
                existingHolding.avgPrice = existingHolding.totalInvestment / existingHolding.quantity;
                existingHolding.totalValue = existingHolding.quantity * existingHolding.quotePrice;
                existingHolding.PnL = existingHolding.totalValue - existingHolding.totalInvestment;
                existingHolding._id = existingHolding._id !== holding._id ? [existingHolding._id, holding._id] : existingHolding._id;
            } else {
                combinedHoldings.push({
                    ...holding,
                    totalInvestment: holding.quantity * holding.price,
                    avgPrice: holding.price,
                    totalValue: holding.quantity * holding.quotePrice,
                    PnL: (holding.quantity * holding.quotePrice) - (holding.quantity * holding.price)
                });
            }
        });
        return combinedHoldings;
    };

    const handleExport = () => {
        exportToCsv('portfolio.csv', columns, aggregatedHoldings(selectedPortfolio.holdings));
    };

    if (!user) {
        navigate('/login');
        return null;
    }

    return (
        <>
            <div>
                <Modal open={showAddPortfolioAssetModal} onClose={() => setShowAddPortfolioAssetModal(false)}
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}>
                    <Box>
                        <AddPortfolioAsset
                            portfolioId={selectedPortfolio && selectedPortfolio._id}
                            handleCloseModalAndRefetchData={handleCloseModalAndRefetchData}
                        />
                    </Box>
                </Modal>
                <AppBar position="static" sx={{ marginTop: 2 }}>
                    <Toolbar>
                        <Tabs
                            value={selectedTabIndex}
                            onChange={handleTabChange}
                            aria-label="portfolio tabs"
                            variant="scrollable"
                            scrollButtons="auto"
                            sx={{
                                "& .Mui-selected": {
                                    color: theme.palette.mode === 'dark' ? theme.palette.primary.main : theme.palette.secondary.main,
                                },
                                "& .MuiTabs-indicator": {
                                    backgroundColor: theme.palette.mode === 'dark' ? theme.palette.primary.main : theme.palette.secondary.main,
                                },
                            }}
                        >
                            {portfolios.map((portfolio) => (
                                <Tab key={portfolio._id} label={portfolio.name}
                                    sx={{
                                        "&.Mui-selected": {
                                            color: theme.palette.mode === 'dark' ? theme.palette.primary.main : theme.palette.secondary.main,
                                        },
                                    }}
                                />
                            ))}
                        </Tabs>
                        <Button variant="contained"
                            onClick={handleMenuOpen}
                            style={{ minWidth: 0, width: 'auto', marginLeft: 'auto' }}>
                            <BuildIcon fontSize="small">
                                Create / Manage
                            </BuildIcon>
                        </Button>
                        <Menu
                            id="portfolio-menu"
                            anchorEl={anchorEl}
                            keepMounted
                            open={Boolean(anchorEl)}
                            onClose={handleMenuClose}
                        >
                            {portfolios.length > 0 && (
                                <MenuItem onClick={() => setShowAddPortfolioAssetModal(true)}>Add Transaction</MenuItem>
                            )}
                            <MenuItem onClick={handleCreateModalOpen}>Create Portfolio</MenuItem>
                            {showCreateModal === 'PortfolioCreate' && (
                                <CreateModal
                                    open={true}
                                    onClose={handleCreateModalClose}
                                    onCreate={handleCreatePortfolio}
                                    title="Create Portfolio"
                                />
                            )}
                            {portfolios.length > 0 && (
                                <MenuItem onClick={() => setShowRenameModal(true)}>Rename Portfolio</MenuItem>
                            )}
                            {showRenameModal && selectedPortfolio && (
                                <RenameModal
                                    open={showRenameModal}
                                    onClose={() => setShowRenameModal(false)}
                                    onConfirm={(newName) => handlePortfolioNameUpdate(selectedPortfolio._id, newName)}
                                    currentName={selectedPortfolio.name}
                                    title="Rename Portfolio"
                                />
                            )}
                            {portfolios.length > 0 && (
                                <MenuItem onClick={() => handleDeleteModalOpen(selectedPortfolio)}>Delete Portfolio</MenuItem>
                            )}
                            {showDeleteModal === 'PortfolioDelete' && (
                                <DeleteModal
                                    open={true}
                                    onClose={handleDeleteModalClose}
                                    onConfirm={() => handlePortfolioDelete(selectedPortfolio)}
                                    itemName={selectedPortfolio.name}
                                />
                            )}
                            {user.plan.name === 'Premium' && portfolios.length > 0 && (
                                <MenuItem onClick={handleExport}>Export Portfolio</MenuItem>
                            )}
                            {portfolios.length > 0 && (
                                <MenuItem onClick={handleCurrencyModalOpen}>Base Currency</MenuItem>
                            )}
                        </Menu>
                    </Toolbar>
                </AppBar>
            </div>

            {selectedPortfolio && selectedPortfolio.holdings.length > 0 ? (
                <div>
                    <DataGrid
                        rows={aggregatedHoldings(selectedPortfolio.holdings)}
                        getRowId={(row) => row.symbol}
                        columns={columns}
                        disableColumnMenu
                        initialState={{
                            pagination: { paginationModel: { pageSize: 10 } },
                            sorting: {
                                sortModel: [{ field: 'symbol', sort: 'asc' }],
                            },
                        }}
                        pageSizeOptions={[5, 10, 25]}
                        sx={{
                            '& .MuiDataGrid-cell': {
                                fontSize: '1rem',
                            },
                            '& .MuiDataGrid-columnHeaders': {
                                fontSize: '0.8rem',
                            },
                        }}
                    />
                </div>
            ) : (
                <Typography sx={{ mt: 2 }} variant="body1">No holdings in the selected portfolio.</Typography>
            )}

            <Snackbar
                open={showSnackbar}
                autoHideDuration={3000}
                onClose={() => setShowSnackbar(false)}
                message={snackbarMessage}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            />

            <Modal open={showEditModal} onClose={() => setShowEditModal(false)}
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}>
                <Card sx={{ maxWidth: '500px', mx: 'auto', my: 2 }}>
                    <CardContent className="text-center" style={{ textAlign: 'center' }}>
                        <h3>Transactions Overview</h3>
                        <TableContainer component={Paper}>
                            <Table aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Symbol</TableCell>
                                        <TableCell align="right">Price</TableCell>
                                        <TableCell align="right">Quantity</TableCell>
                                        <TableCell align="right">Actions</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {selectedAsset && selectedAsset.holdings.map((holding, index) => (
                                        <TableRow key={index}>
                                            <TableCell component="th" scope="row">
                                                {holding.symbol}
                                            </TableCell>
                                            <TableCell align="right">{holding.price}</TableCell>
                                            <TableCell align="right">{holding.quantity}</TableCell>
                                            <TableCell align="right">
                                                <DeleteIcon onClick={() => handleAssetDeleteAndCloseModal(selectedPortfolio._id, holding._id)} />
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </CardContent>
                </Card>
            </Modal>

            <CreateModal
                open={showCreateModal}
                onClose={handleCreateModalClose}
                onCreate={handleCreatePortfolioAndClose}
                title="Create Portfolio"
            />

            <RenameModal
                open={showRenameModal}
                onClose={() => setShowRenameModal(false)}
                onConfirm={(newName) => handlePortfolioNameUpdate(selectedPortfolio._id, newName)}
                currentName={selectedPortfolio ? selectedPortfolio.name : ''}
                title="Rename Portfolio"
            />

            <DeleteModal
                open={showDeleteModal}
                onClose={() => setShowDeleteModal(false)}
                onConfirm={handlePortfolioDeleteAndClose}
                itemName={portfolioToDelete ? portfolioToDelete.name : ''}
            />

            {selectedPortfolio && (
                <ChangeCurrencyModal
                    open={showCurrencyModal}
                    onClose={handleCurrencyModalClose}
                    onConfirm={handleCurrencyChange}
                    currentCurrency={selectedPortfolio.baseCurrency}
                    title="Change Base Currency"
                />
            )}

            <Snackbar
                open={showSnackbar}
                autoHideDuration={3000}
                onClose={() => setShowSnackbar(false)}
                message={snackbarMessage}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            />
        </>
    );
};

export default PortfolioTable;