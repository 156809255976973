// StockDashboard.js
import React, { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { DataGrid } from '@mui/x-data-grid';
import {
  Button,
  CircularProgress,
  TextField,
  Box,
  Container,
  Typography,
  Alert
} from '@mui/material';
import { AddCircle as AddCircleIcon } from '@mui/icons-material';
import { useTheme } from '@mui/material/styles';
import { formatNumber } from '../utils/numberUtils';
import AddToWatchlistModal from './AddToWatchlistModal';
import { useChangeColor } from '../utils/colorUtils';

const StockDashboard = ({ user, data, loading, error, onRefresh }) => {
  const theme = useTheme();
  const [selectedAsset, setSelectedAsset] = useState(null);
  const [showAddToWatchlistModal, setShowAddToWatchlistModal] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const baseUrl = window.location.origin;
  const getColorForChange = useChangeColor();

  const handleAddToWatchlist = (asset) => {
    setSelectedAsset(asset);
    setShowAddToWatchlistModal(true);
  };

  const handleWatchlistSuccess = (message) => {
    // console.log(message);
  };

  const handleCloseModal = () => {
    setShowAddToWatchlistModal(false);
    setSelectedAsset(null);
  };

  const handleSearchChange = (event) => {
    setSearchValue(event.target.value);
  };

  const filterAssets = () => {
    if (!data) return [];
    return data.filter((asset) =>
      asset.symbol.toLowerCase().includes(searchValue.toLowerCase()) ||
      (asset.name && asset.name.toLowerCase().includes(searchValue.toLowerCase()))
    );
  };

  const columns = [
    {
      field: 'symbol',
      headerName: 'Company',
      minWidth: 250,
      flex: 1,
      renderCell: (params) => (
        <Box>
          <RouterLink
            to={`${baseUrl}/asset/${params.row.symbol}`}
            style={{
              color: theme.palette.primary.main,
              textDecoration: 'none',
              fontSize: '1rem',
              fontWeight: 'bold',
            }}
          >
            {params.row.symbol}
          </RouterLink>
          <Typography
            variant="body2"
            sx={{
              color: 'text.secondary',
              fontWeight: 500
            }}
          >
            {params.row.name}
          </Typography>
        </Box>
      ),
    },
    {
      field: 'price',
      headerName: 'Price',
      flex: 1,
      minWidth: 150,
      valueFormatter: (params) => formatNumber(params.value)
    },
    {
      field: 'open',
      headerName: 'Open',
      flex: 1,
      minWidth: 150,
      valueFormatter: (params) =>
        params.value?.toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        })
    },
    {
      field: 'high',
      headerName: 'High',
      flex: 1,
      minWidth: 150,
      valueFormatter: (params) =>
        params.value?.toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        })
    },
    {
      field: 'change',
      headerName: 'Change',
      minWidth: 150,
      flex: 1,
      renderCell: (params) => (
        <Typography
          style={{
            color: params.value != null ? getColorForChange(params.value) : theme.palette.text.primary
          }}
        >
          {params.value != null ? params.value.toFixed(2) : '0'}
        </Typography>
      ),
    },
    {
      field: 'changesPercentage',
      headerName: 'Change %',
      minWidth: 150,
      flex: 1,
      renderCell: (params) => (
        <Typography
          style={{
            color: params.value != null ? getColorForChange(params.value) : theme.palette.text.primary
          }}
        >
          {params.value != null ? `${params.value.toFixed(2)}%` : '0'}
        </Typography>
      ),
    },
    {
      field: 'volume',
      headerName: 'Volume',
      flex: 1,
      minWidth: 150,
      valueFormatter: (params) => formatNumber(params.value)
    },
    {
      field: 'marketCap',
      headerName: 'Market Cap',
      minWidth: 150,
      flex: 1,
      valueFormatter: (params) => formatNumber(params.value),
    },
    user && {
      field: 'addToWatchlist',
      headerName: '',
      width: 100,
      sortable: false,
      renderCell: (params) => (
        <Button
          variant="contained"
          color="primary"
          size="small"
          onClick={(e) => {
            e.stopPropagation();
            handleAddToWatchlist(params.row);
          }}
          sx={{ minWidth: 'unset', p: 1 }}
        >
          <AddCircleIcon />
        </Button>
      ),
    },
  ].filter(Boolean);

  if (loading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="calc(100vh - 100px)"
      >
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Alert 
        severity="error" 
        action={
          <Button color="inherit" size="small" onClick={onRefresh}>
            Retry
          </Button>
        }
      >
        {error}
      </Alert>
    );
  }

  return (
    <Container maxWidth="xl">
      <Box>
        <TextField
          label="Search by Symbol or Name"
          variant="outlined"
          value={searchValue}
          onChange={handleSearchChange}
          style={{ marginBottom: '20px' }}
        />
      </Box>

      <Box sx={{ height: 650, width: '100%' }}>
        <DataGrid
          rows={filterAssets()}
          columns={columns}
          getRowId={(row) => row.symbol}
          disableColumnMenu
          paginationPosition="middle"
          initialState={{
            pagination: { paginationModel: { pageSize: 10 } },
            sorting: { sortModel: [{ field: 'marketCap', sort: 'desc' }] },
          }}
          pageSizeOptions={[5, 10, 25]}
          sx={{
            '& .positive': {
              color: theme.palette.positive.main,
            },
            '& .negative': {
              color: theme.palette.negative.main,
            },
          }}
        />
      </Box>

      {selectedAsset && (
        <AddToWatchlistModal
          open={showAddToWatchlistModal}
          onClose={handleCloseModal}
          asset={selectedAsset}
          userId={user?._id}
          onSuccess={handleWatchlistSuccess}
          assetType="stock"
        />
      )}
    </Container>
  );
};

export default StockDashboard;