import React from 'react';
import { Typography, List, ListItem, Box } from '@mui/material';

const PricingPlan = ({ price, title, description, features, btn, isMonthly, activePromotions, plan }) => {
    const relevantPriceId = isMonthly ? plan?.monthlyId : plan?.yearlyId;

    const promotion = activePromotions?.find(p =>
        p.eligiblePriceIds.includes(relevantPriceId)
    );

    return (
        <div style={{ textAlign: 'center', position: 'relative' }}>
            {promotion && (
                <Box
                    sx={{
                        position: 'absolute',
                        top: '-45px', // Increased from -30px
                        left: 0,
                        right: 0,
                        backgroundColor: 'primary.main',
                        color: 'white',
                        py: 1, // Increased from 0.5
                        px: 2,
                        borderTopLeftRadius: 8,
                        borderTopRightRadius: 8,
                        fontWeight: 'bold',
                        textAlign: 'center',
                        zIndex: 1
                    }}
                >
                    <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                        {promotion.name} - {promotion.description}
                    </Typography>
                </Box>
            )}
            <Box sx={{
                position: 'relative',
                mt: promotion ? '2rem' : '1rem'
            }}>
                {promotion ? (
                    <Typography
                        variant="h4"
                        sx={{
                            mb: 1,
                            textDecoration: 'line-through',
                            color: 'text.secondary'
                        }}
                    >
                        {price}
                    </Typography>
                ) : (
                    <Typography
                        variant="h4"
                        sx={{ mb: 1 }}
                    >
                        {price}
                    </Typography>
                )}
            </Box>
            {!isMonthly && (
                <Typography variant="body2" sx={{ mb: 1 }}>
                    billed annually
                </Typography>
            )}
            <Typography variant="h5" sx={{ mb: 1 }}>
                {title}
            </Typography>
            <Typography variant="body1" sx={{ mb: 1 }}>
                {description}
            </Typography>
            <List sx={{ textAlign: 'center' }}>
                {features.map((feature, index) => (
                    <ListItem key={`${title}-feature-${index}`}>
                        {feature}
                    </ListItem>
                ))}
            </List>
        </div>
    );
};

export default PricingPlan;