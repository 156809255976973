import React, { useState, useEffect } from 'react';
import {
    Container, Box, useTheme, Typography, Button, CircularProgress, Grid, Switch,
    FormControlLabel, Snackbar, Dialog, DialogContent, DialogActions
} from '@mui/material';
import axios from '../config/axiosConfig';
import { useAuth } from './AuthContext';
import staticPricingPlans from '../data/staticPricingPlans';
import PricingPlan from './PricingPlan';
import { useNavigate } from 'react-router-dom';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';

function UpgradePlan() {
    const theme = useTheme();
    const { user } = useAuth();
    const navigate = useNavigate();
    const [plans, setPlans] = useState(staticPricingPlans);
    const [currentPlan, setCurrentPlan] = useState(null);
    const [, setError] = useState('');
    const [message, setMessage] = useState('');
    const [loading, setLoading] = useState(true);
    const [isMonthly, setIsMonthly] = useState(false);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [openUpgradeDialog, setOpenUpgradeDialog] = useState(false);
    const [pendingUpgradeId, setPendingUpgradeId] = useState(null);
    const [invoicePreview, setInvoicePreview] = useState(null);
    const [previewLoading, setPreviewLoading] = useState(false);

    useEffect(() => {
        const fetchPlansAndPromotions = async () => {
            try {
                const [plansResponse, promotionsResponse] = await Promise.all([
                    axios.get('/api/stripe/plans'),
                    axios.get('/api/promotions/active')
                ]);

                const updatedPlans = staticPricingPlans.map(plan => {
                    const matchedPlans = plansResponse.data.filter(p =>
                        p.name.trim().toLowerCase() === plan.title.trim().toLowerCase()
                    );

                    matchedPlans.forEach(dynamicPlan => {
                        if (dynamicPlan.interval === 'month') {
                            plan.monthlyPrice = `$${parseFloat(dynamicPlan.price).toLocaleString('en-US', {
                                minimumFractionDigits: 0,
                                maximumFractionDigits: 0
                            })}/month`;
                            plan.monthlyId = dynamicPlan.id;
                        } else if (dynamicPlan.interval === 'year') {
                            plan.yearlyPrice = `$${parseFloat(dynamicPlan.price / 12).toLocaleString('en-US', {
                                minimumFractionDigits: 0,
                                maximumFractionDigits: 0
                            })}/month`;
                            plan.yearlyId = dynamicPlan.id;
                        }
                    });

                    plan.activePromotions = promotionsResponse.data.filter(promotion =>
                        promotion.eligiblePriceIds.includes(plan.monthlyId) ||
                        promotion.eligiblePriceIds.includes(plan.yearlyId)
                    );

                    // Add upgrade eligibility based on current plan
                    if (user.plan && user.plan.name !== 'Free') {
                        // Premium Yearly users can't upgrade to anything
                        if (user.plan.name === 'Premium' && currentPlan?.interval === 'year') {
                            plan.canUpgrade = false;
                            plan.ineligibleReason = "You're already on our highest plan";
                        }
                        // Any yearly plan users can only upgrade to Premium yearly
                        else if (currentPlan?.interval === 'year') {
                            plan.canUpgrade = plan.title === 'Premium';
                            plan.ineligibleReason = "Yearly plans can only upgrade to Premium yearly";
                        }
                        // Premium Monthly users can upgrade to any yearly plan
                        else if (user.plan.name === 'Premium' && currentPlan?.interval === 'month') {
                            plan.canUpgrade = !isMonthly; // Can upgrade to any yearly plan
                            plan.ineligibleReason = "Premium monthly can only upgrade to yearly plans";
                        }
                        // Basic Monthly users can upgrade to anything except Basic Monthly
                        else if (user.plan.name === 'Basic' && currentPlan?.interval === 'month') {
                            plan.canUpgrade = true; // Can upgrade to any plan
                        }
                    } else {
                        // Free users can upgrade to anything
                        plan.canUpgrade = true;
                    }

                    return plan;
                });

                setPlans(updatedPlans);
                setLoading(false);
            } catch (error) {
                setMessage('Error fetching plans and promotions.');
                console.error('Fetch plans and promotions error:', error);
                setLoading(false);
            }
        };

        fetchPlansAndPromotions();
    }, [user.plan, currentPlan, isMonthly]); // Added isMonthly dependency

    useEffect(() => {
        const fetchCurrentPlan = async () => {
            if (user.plan.name !== 'Free') {
                try {
                    const response = await axios.get(`/api/stripe/currentplan/${user._id}`);
                    setCurrentPlan(response.data.plan);
                } catch (err) {
                    setError('Error fetching current plan.');
                    console.error('Error fetching current plan:', err);
                }
            } else {
                setCurrentPlan(user.plan);
            }
            setLoading(false);
        };

        fetchCurrentPlan();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user._id, user.plan.name]);

    const handleUpgradeClick = async (planId) => {
        setPreviewLoading(true);
        setPendingUpgradeId(planId);
        try {
            const response = await axios.post('/api/stripe/preview-invoice', {
                userId: user._id,
                newPriceId: planId
            });
            setInvoicePreview(response.data);
            setOpenUpgradeDialog(true);
        } catch (error) {
            console.error('Error fetching preview:', error);
            setMessage('Unable to calculate upgrade cost at this time.');
            setSnackbarOpen(true);
        }
        setPreviewLoading(false);
    };

    const handleUpgrade = async () => {
        if (!pendingUpgradeId) {
            setMessage('Please select a plan to upgrade.');
            setSnackbarOpen(true);
            return;
        }

        setOpenUpgradeDialog(false);

        try {
            const response = await axios.post('/api/stripe/upgrade', {
                newPriceId: pendingUpgradeId,
                userId: user._id
            });
            if (response.data.updatedSubscription.success) {
                navigate('/success', {
                    state: { message: response.data.updatedSubscription.message }
                });
            } else {
                setMessage(response.data.updatedSubscription.message || 'Upgrade failed.');
                setSnackbarOpen(true);
            }
        } catch (error) {
            setMessage('Error occurred.');
            console.error('Upgrade error:', error);
            setSnackbarOpen(true);
        }
    };

    const handleToggle = () => setIsMonthly(!isMonthly);
    const handleSnackbarClose = () => setSnackbarOpen(false);

    if (loading) {
        return <CircularProgress />;
    }

    const renderInvoicePreview = () => {
        if (!invoicePreview) return null;

        return (
            <Box
                mb={4}
                p={3}
                bgcolor={theme.palette.mode === 'dark' ? 'rgba(255,255,255,0.05)' : 'rgba(0,0,0,0.02)'}
                borderRadius={2}
            >
                <Typography variant="h6" color="text.secondary" gutterBottom>
                    Cost Breakdown
                </Typography>

                {invoicePreview.lineItems.map((item, index) => (
                    <Box key={index} mb={2}>
                        <Typography color="text.primary" mb={1}>
                            {item.description}
                            <Typography component="span"
                                color={item.amount < 0 ? 'success.main' : 'text.primary'}>
                                {item.amount < 0 ? ' -' : ' '}${Math.abs(item.amount).toFixed(2)}
                            </Typography>
                        </Typography>
                        {item.period && (
                            <Typography variant="caption" color="text.secondary">
                                For period: {new Date(item.period.start).toLocaleDateString()}
                                - {new Date(item.period.end).toLocaleDateString()}
                            </Typography>
                        )}
                    </Box>
                ))}

                <Box
                    mt={3}
                    pt={2}
                    borderTop={1}
                    borderColor={theme.palette.divider}
                >
                    <Typography variant="subtitle1" fontWeight="bold">
                        Total Due Today: ${invoicePreview.total.toFixed(2)}
                    </Typography>

                    {invoicePreview.nextBillingDate && (
                        <Typography variant="caption" color="text.secondary">
                            Next billing date: {new Date(invoicePreview.nextBillingDate).toLocaleDateString()}
                        </Typography>
                    )}
                </Box>

                {invoicePreview.paymentMethod && (
                    <Box mt={2}>
                        <Typography variant="body2" color="text.secondary">
                            Payment will be charged to your {invoicePreview.paymentMethod.brand} card
                            ending in {invoicePreview.paymentMethod.last4}
                        </Typography>
                    </Box>
                )}
            </Box>
        );
    };

    return (
        <Container sx={{ mt: 4, mb: 5 }}>
            <Box sx={{ padding: theme.spacing(4) }}>
                <Typography sx={{ mt: 2, mb: 5 }} variant="h4" component="h2" align="center" gutterBottom>
                    <strong>Our Plans</strong>
                </Typography>
                <FormControlLabel
                    control={<Switch checked={isMonthly} onChange={handleToggle} />}
                    label={isMonthly ? 'Monthly' : 'Annually'}
                    sx={{ display: 'flex', justifyContent: 'center', mb: 4 }}
                />
                <Grid container spacing={4} justifyContent="center">
                    {plans
                        .filter(plan => {
                            // Always exclude Free plan
                            if (plan.title === 'Free') return false;

                            // If user has no plan or is on Free plan, show all plans
                            if (!currentPlan || currentPlan.name === 'Free') return true;

                            // If on Premium Yearly, don't show any plans
                            if (currentPlan.name === 'Premium' && currentPlan.interval === 'year') {
                                return false;
                            }

                            // If on any Yearly plan, only show Premium yearly
                            if (currentPlan.interval === 'year' ) {
                                return plan.title === 'Premium' && !isMonthly;
                            }

                            // If on Premium Monthly, only show yearly plans
                            if (currentPlan.name === 'Premium' && currentPlan.interval === 'month') {
                                return !isMonthly; // Only show yearly plans
                            }

                            // If on Basic Monthly, show everything except current plan
                            if (currentPlan.name === 'Basic' && currentPlan.interval === 'month') {
                                if (isMonthly) {
                                    return plan.title === 'Premium'; // Only show Premium monthly
                                }
                                return true; // Show all yearly plans
                            }

                            return true;
                        })
                        .map((plan, index) => (
                            <Grid item xs={12} sm={6} md={4} key={plan.title}>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        padding: theme.spacing(4),
                                        backgroundColor: theme.palette.background.paper,
                                        borderRadius: 2,
                                        border: index === 0 ? '3px solid blue' : 'none',
                                        boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
                                        transition: 'transform 0.3s, box-shadow 0.3s',
                                        '&:hover': {
                                            transform: 'scale(1.05)',
                                            boxShadow: '0px 8px 20px rgba(0, 0, 0, 0.15)',
                                        },
                                    }}
                                >
                                    <PricingPlan
                                        price={isMonthly ? plan.monthlyPrice : plan.yearlyPrice}
                                        title={plan.title}
                                        description={plan.description}
                                        features={plan.features.map((feature, index) => (
                                            <Box key={index} sx={{ display: 'flex', alignItems: 'center' }}>
                                                <CheckCircleRoundedIcon sx={{ mr: 1, color: theme.palette.primary.main }} />
                                                {feature}
                                            </Box>
                                        ))}
                                        btn={plan.btn}
                                        isMonthly={isMonthly}
                                        activePromotions={plan.activePromotions}
                                        plan={plan}
                                    />
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={() => handleUpgradeClick(isMonthly ? plan.monthlyId : plan.yearlyId)}
                                        disabled={!plan.canUpgrade}
                                        title={!plan.canUpgrade ? plan.ineligibleReason : ''}
                                    >
                                        {plan.canUpgrade ? 'Upgrade' : plan.ineligibleReason}
                                    </Button>
                                </Box>
                            </Grid>
                        ))}
                </Grid>
            </Box>

            <Dialog
                open={openUpgradeDialog}
                onClose={() => setOpenUpgradeDialog(false)}
                maxWidth="sm"
                fullWidth
                PaperProps={{
                    sx: {
                        borderRadius: 2,
                        bgcolor: theme.palette.background.paper
                    }
                }}
            >
                <Box sx={{ p: 4 }}>
                    <Typography variant="h4" color="primary" fontWeight="bold" mb={4}>
                        Plan Upgrade Information
                    </Typography>

                    <DialogContent sx={{ p: 0 }}>
                        {previewLoading ? (
                            <Box display="flex" justifyContent="center" my={4}>
                                <CircularProgress />
                            </Box>
                        ) : (
                            <>
                                {renderInvoicePreview()}

                                <Box
                                    mb={4}
                                    p={3}
                                    bgcolor={theme.palette.mode === 'dark' ?
                                        'rgba(255,255,255,0.05)' : 'rgba(0,0,0,0.02)'}
                                    borderRadius={2}
                                >
                                    <Typography variant="h6" color="text.secondary" gutterBottom>
                                        What happens when you upgrade?
                                    </Typography>
                                    <Typography color="text.primary" mb={1}>
                                        • Your new plan will start immediately
                                    </Typography>
                                    <Typography color="text.primary" mb={1}>
                                        • You'll receive credit for any unused time on your current plan
                                    </Typography>
                                    <Typography color="text.primary" mb={1}>
                                        • Your billing cycle will restart from today
                                    </Typography>
                                    {isMonthly ? (
                                        <Typography color="text.primary">
                                            • You can upgrade to a yearly plan at any time to save more
                                        </Typography>
                                    ) : (
                                        <Typography color="text.primary">
                                            • Yearly plans cannot be changed to monthly plans until the end of the billing cycle
                                        </Typography>
                                    )}
                                </Box>

                                {!isMonthly && (
                                    <Box
                                        p={3}
                                        bgcolor={theme.palette.mode === 'dark' ?
                                            'rgba(255,0,0,0.1)' : 'rgba(255,0,0,0.05)'}
                                        borderRadius={2}
                                    >
                                        <Typography variant="subtitle1" color="error.main">
                                            Note: Yearly plans cannot be downgraded. You can only cancel at the end of your billing period.
                                        </Typography>
                                    </Box>
                                )}
                            </>
                        )}
                    </DialogContent>

                    <DialogActions sx={{ p: 0, mt: 4 }}>
                        <Button
                            onClick={() => setOpenUpgradeDialog(false)}
                            sx={{
                                mr: 2,
                                px: 4,
                                color: theme.palette.text.primary
                            }}
                        >
                            Cancel
                        </Button>
                        <Button
                            onClick={handleUpgrade}
                            variant="contained"
                            color="primary"
                            sx={{
                                px: 4,
                                py: 1,
                                fontWeight: 600
                            }}
                            disabled={previewLoading}
                        >
                            Confirm Upgrade
                        </Button>
                    </DialogActions>
                </Box>
            </Dialog>

            <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                open={snackbarOpen}
                onClose={handleSnackbarClose}
                message={message}
                autoHideDuration={6000}
            />
        </Container>
    );
}

export default UpgradePlan;