import React, { useState, useMemo, useEffect } from 'react';
import { useAuth } from './AuthContext';
import { DataGrid } from '@mui/x-data-grid';
import {
    Container,
    Box,
    Grid,
    Typography,
    Switch,
    FormControlLabel,
    FormControl,
    InputLabel,
    Select,
    MenuItem
} from '@mui/material';
import BarChart from './BarChart';
import ContentAccessControl from './ContentAccessControl';
import TimeRangeButtons from './TimeRangeButtons';

const CompanyIncomeStatement = ({ data: incomeStatementData }) => {
    const { user } = useAuth();
    const [showGrid, setShowGrid] = useState(false);
    const [timeRange, setTimeRange] = useState('5Y');
    const [selectedYear, setSelectedYear] = useState('');
    const [periodType, setPeriodType] = useState('annual');

    useEffect(() => {
        // Reset timeRange to 3Y if switching from quarter to annual and timeRange is 1Y
        if (periodType === 'annual' && timeRange === '1Y') {
            setTimeRange('3Y');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [periodType]);

    const chartConfigs = [
        {
            label: 'Revenue',
            key: 'revenue',
            colors: {
                backgroundColor: 'rgba(75,192,192,0.2)',
                borderColor: 'rgba(75,192,192,1)',
                hoverBackgroundColor: 'rgba(75,192,192,0.4)',
                hoverBorderColor: 'rgba(75,192,192,1)'
            },
            access: 'Free'
        },
        {
            label: 'Gross Profit',
            key: 'grossProfit',
            colors: {
                backgroundColor: 'rgba(54, 162, 235, 0.2)',
                borderColor: 'rgba(54, 162, 235, 1)',
                hoverBackgroundColor: 'rgba(54, 162, 235, 0.4)',
                hoverBorderColor: 'rgba(54, 162, 235, 1)'
            },
            access: 'Basic'
        },
        {
            label: 'EPS',
            key: 'eps',
            colors: {
                backgroundColor: 'rgba(255, 99, 132, 0.2)',
                borderColor: 'rgba(255, 99, 132, 1)',
                hoverBackgroundColor: 'rgba(255, 99, 132, 0.4)',
                hoverBorderColor: 'rgba(255, 99, 132, 1)'
            },
            access: 'Basic'
        },
        {
            label: 'EPS Diluted',
            key: 'epsdiluted',
            colors: {
                backgroundColor: 'rgba(153, 102, 255, 0.2)',
                borderColor: 'rgba(153, 102, 255, 1)',
                hoverBackgroundColor: 'rgba(153, 102, 255, 0.4)',
                hoverBorderColor: 'rgba(153, 102, 255, 1)'
            },
            access: 'Premium'
        },
        {
            label: 'EBITDA',
            key: 'ebitda',
            colors: {
                backgroundColor: 'rgba(255, 159, 64, 0.2)',
                borderColor: 'rgba(255, 159, 64, 1)',
                hoverBackgroundColor: 'rgba(255, 159, 64, 0.4)',
                hoverBorderColor: 'rgba(255, 159, 64, 1)'
            },
            access: 'Basic'
        },
        {
            label: 'EBITDA Ratio',
            key: 'ebitdaratio',
            colors: {
                backgroundColor: 'rgba(75, 192, 192, 0.2)',
                borderColor: 'rgba(75, 192, 192, 1)',
                hoverBackgroundColor: 'rgba(75, 192, 192, 0.4)',
                hoverBorderColor: 'rgba(75, 192, 192, 1)'
            },
            access: 'Premium'
        },
        {
            label: 'Gross Profit Ratio',
            key: 'grossProfitRatio',
            colors: {
                backgroundColor: 'rgba(255, 206, 86, 0.2)',
                borderColor: 'rgba(255, 206, 86, 1)',
                hoverBackgroundColor: 'rgba(255, 206, 86, 0.4)',
                hoverBorderColor: 'rgba(255, 206, 86, 1)'
            },
            access: 'Basic'
        },
        {
            label: 'Income Before Tax',
            key: 'incomeBeforeTax',
            colors: {
                backgroundColor: 'rgba(255, 99, 132, 0.2)',
                borderColor: 'rgba(255, 99, 132, 1)',
                hoverBackgroundColor: 'rgba(255, 99, 132, 0.4)',
                hoverBorderColor: 'rgba(255, 99, 132, 1)'
            },
            access: 'Basic'
        },
        {
            label: 'Income Before Tax Ratio',
            key: 'incomeBeforeTaxRatio',
            colors: {
                backgroundColor: 'rgba(54, 162, 235, 0.2)',
                borderColor: 'rgba(54, 162, 235, 1)',
                hoverBackgroundColor: 'rgba(54, 162, 235, 0.4)',
                hoverBorderColor: 'rgba(54, 162, 235, 1)'
            },
            access: 'Premium'
        },
        {
            label: 'Income Tax Expense',
            key: 'incomeTaxExpense',
            colors: {
                backgroundColor: 'rgba(75, 192, 192, 0.2)',
                borderColor: 'rgba(75, 192, 192, 1)',
                hoverBackgroundColor: 'rgba(75, 192, 192, 0.4)',
                hoverBorderColor: 'rgba(75, 192, 192, 1)'
            },
            access: 'Premium'
        },
        {
            label: 'Interest Expense',
            key: 'interestExpense',
            colors: {
                backgroundColor: 'rgba(153, 102, 255, 0.2)',
                borderColor: 'rgba(153, 102, 255, 1)',
                hoverBackgroundColor: 'rgba(153, 102, 255, 0.4)',
                hoverBorderColor: 'rgba(153, 102, 255, 1)'
            },
            access: 'Premium'
        },
        {
            label: 'Interest Income',
            key: 'interestIncome',
            colors: {
                backgroundColor: 'rgba(255, 159, 64, 0.2)',
                borderColor: 'rgba(255, 159, 64, 1)',
                hoverBackgroundColor: 'rgba(255, 159, 64, 0.4)',
                hoverBorderColor: 'rgba(255, 159, 64, 1)'
            },
            access: 'Premium'
        },
        {
            label: 'Net Income',
            key: 'netIncome',
            colors: {
                backgroundColor: 'rgba(153, 102, 255, 0.2)',
                borderColor: 'rgba(153, 102, 255, 1)',
                hoverBackgroundColor: 'rgba(153, 102, 255, 0.4)',
                hoverBorderColor: 'rgba(153, 102, 255, 1)'
            },
            access: 'Basic'
        },
        {
            label: 'Net Income Ratio',
            key: 'netIncomeRatio',
            colors: {
                backgroundColor: 'rgba(255, 159, 64, 0.2)',
                borderColor: 'rgba(255, 159, 64, 1)',
                hoverBackgroundColor: 'rgba(255, 159, 64, 0.4)',
                hoverBorderColor: 'rgba(255, 159, 64, 1)'
            },
            access: 'Premium'
        },
        {
            label: 'Research and Development Expenses',
            key: 'researchAndDevelopmentExpenses',
            colors: {
                backgroundColor: 'rgba(255, 206, 86, 0.2)',
                borderColor: 'rgba(255, 206, 86, 1)',
                hoverBackgroundColor: 'rgba(255, 206, 86, 0.4)',
                hoverBorderColor: 'rgba(255, 206, 86, 1)'
            },
            access: 'Premium'
        },
        {
            label: 'Selling and Marketing Expenses',
            key: 'sellingAndMarketingExpenses',
            colors: {
                backgroundColor: 'rgba(75, 192, 192, 0.2)',
                borderColor: 'rgba(75, 192, 192, 1)',
                hoverBackgroundColor: 'rgba(75, 192, 192, 0.4)',
                hoverBorderColor: 'rgba(75, 192, 192, 1)'
            },
            access: 'Premium'
        },
        {
            label: 'Weighted Average Shares Outstanding',
            key: 'weightedAverageShsOut',
            colors: {
                backgroundColor: 'rgba(54, 162, 235, 0.2)',
                borderColor: 'rgba(54, 162, 235, 1)',
                hoverBackgroundColor: 'rgba(54, 162, 235, 0.4)',
                hoverBorderColor: 'rgba(54, 162, 235, 1)'
            },
            access: 'Premium'
        },
        {
            label: 'Weighted Average Shares Outstanding (Diluted)',
            key: 'weightedAverageShsOutDil',
            colors: {
                backgroundColor: 'rgba(104, 132, 245, 0.2)',
                borderColor: 'rgba(104, 132, 245, 1)',
                hoverBackgroundColor: 'rgba(104, 132, 245, 0.4)',
                hoverBorderColor: 'rgba(104, 132, 245, 1)'
            },
            access: 'Premium'
        },
        {
            label: 'General and Administrative Expenses',
            key: 'generalAndAdministrativeExpenses',
            colors: {
                backgroundColor: 'rgba(75, 192, 75, 0.2)',
                borderColor: 'rgba(75, 192, 75, 1)',
                hoverBackgroundColor: 'rgba(75, 192, 75, 0.4)',
                hoverBorderColor: 'rgba(75, 192, 75, 1)'
            },
            access: 'Premium'
        },
        {
            label: 'Operating Expenses',
            key: 'operatingExpenses',
            colors: {
                backgroundColor: 'rgba(245, 66, 139, 0.2)',
                borderColor: 'rgba(245, 66, 139, 1)',
                hoverBackgroundColor: 'rgba(245, 66, 139, 0.4)',
                hoverBorderColor: 'rgba(245, 66, 139, 1)'
            },
            access: 'Basic'
        },
        {
            label: 'Other Expenses',
            key: 'otherExpenses',
            colors: {
                backgroundColor: 'rgba(240, 128, 128, 0.2)',
                borderColor: 'rgba(240, 128, 128, 1)',
                hoverBackgroundColor: 'rgba(240, 128, 128, 0.4)',
                hoverBorderColor: 'rgba(240, 128, 128, 1)'
            },
            access: 'Premium'
        }
    ];

    // Process the income statement data
    const processedData = useMemo(() => {
        if (!incomeStatementData?.annual || !incomeStatementData?.quarterly) return null;

        const years = [...new Set([
            ...incomeStatementData.annual.map(item => new Date(item.date).getFullYear()),
            ...Object.keys(incomeStatementData.quarterly).map(Number)
        ])].sort((a, b) => b - a);  // Sort years in descending order

        // Set initial year if not set
        if (!selectedYear && years.length > 0) {
            setSelectedYear(years[0].toString());
        }

        return {
            years,
            annual: incomeStatementData.annual,
            quarterly: incomeStatementData.quarterly
        };
    }, [incomeStatementData, selectedYear]);

    const currentData = useMemo(() => {
        if (!processedData || !selectedYear) return [];

        if (showGrid) {
            // For grid view, show only selected year data
            if (periodType === 'annual') {
                return processedData.annual.filter(item =>
                    new Date(item.date).getFullYear() === parseInt(selectedYear)
                );
            } else {
                return processedData.quarterly[selectedYear] || [];
            }
        } else {
            // For chart view, show all data according to period type
            if (periodType === 'annual') {
                return processedData.annual;
            } else {
                // Flatten all quarterly data into a single array
                return Object.values(processedData.quarterly)
                    .flat()
                    .sort((a, b) => new Date(a.date) - new Date(b.date));
            }
        }
    }, [processedData, selectedYear, periodType, showGrid]);


    const prepareChartData = (label, key, colors) => {
        if (!currentData) return null;

        const filteredData = getFilteredData(currentData);

        return {
            labels: filteredData.map(statement =>
                periodType === 'annual'
                    ? new Date(statement.date).getFullYear()
                    : `${new Date(statement.date).getFullYear()} ${statement.period}`
            ),
            datasets: [{
                label: label,
                backgroundColor: colors.backgroundColor,
                borderColor: colors.borderColor,
                borderWidth: 1,
                hoverBackgroundColor: colors.hoverBackgroundColor,
                hoverBorderColor: colors.hoverBorderColor,
                data: filteredData.map(statement => statement[key])
            }]
        };
    };


    const prepareGridColumns = () => {
        if (!processedData || !selectedYear) return [];

        let relevantData;
        if (periodType === 'annual') {
            relevantData = getFilteredData(processedData.annual);

            return [
                { field: 'metric', headerName: 'Metric', width: 200 },
                ...relevantData
                    .sort((a, b) => new Date(b.date) - new Date(a.date))
                    .map(statement => ({
                        field: new Date(statement.date).getFullYear().toString(),
                        headerName: new Date(statement.date).getFullYear().toString(),
                        width: 150,
                        valueFormatter: formatGridValue
                    }))
            ];
        } else {
            // For quarterly view
            const filteredYears = getFilteredYears();
            const columns = [];

            filteredYears.forEach(year => {
                const yearData = processedData.quarterly[year] || [];
                yearData
                    .sort((a, b) => {
                        // Sort Q4 to Q1
                        const aQ = parseInt(a.period.replace('Q', ''));
                        const bQ = parseInt(b.period.replace('Q', ''));
                        return bQ - aQ;
                    })
                    .forEach(quarter => {
                        columns.push({
                            field: `${year}_${quarter.period}`,
                            headerName: `${year} ${quarter.period}`,
                            width: 150,
                            valueFormatter: formatGridValue
                        });
                    });
            });

            return [
                { field: 'metric', headerName: 'Metric', width: 200 },
                ...columns
            ];
        }
    };

    const getFilteredYears = () => {
        const allYears = Object.keys(processedData.quarterly)
            .map(Number)
            .sort((a, b) => b - a); // Sort years descending

        if (timeRange === 'MAX') {
            return allYears;
        }

        const yearLimit = {
            '1Y': 1,
            '3Y': 3,
            '5Y': 5,
            '10Y': 10
        }[timeRange];

        return allYears.slice(0, yearLimit);
    };

    const prepareGridData = () => {
        if (!processedData || !selectedYear) return [];

        const metrics = chartConfigs.map(config => ({
            metric: config.label,
            key: config.key
        }));

        if (periodType === 'annual') {
            const relevantData = getFilteredData(processedData.annual);

            return metrics.map((item, index) => {
                const rowData = {
                    id: index,
                    metric: item.metric,
                };

                relevantData.forEach(statement => {
                    const year = new Date(statement.date).getFullYear();
                    rowData[year] = statement[item.key];
                });

                return rowData;
            });
        } else {
            // For quarterly view
            return metrics.map((item, index) => {
                const rowData = {
                    id: index,
                    metric: item.metric,
                };

                const filteredYears = getFilteredYears();

                filteredYears.forEach(year => {
                    const yearData = processedData.quarterly[year] || [];
                    yearData.forEach(quarter => {
                        rowData[`${year}_${quarter.period}`] = quarter[item.key];
                    });
                });

                return rowData;
            });
        }
    };

    const getFilteredData = (data) => {
        if (!data || !data.length) return data;

        // Sort data - direction depends on view mode
        const sortedData = [...data].sort((a, b) => {
            const dateA = new Date(a.date);
            const dateB = new Date(b.date);
            // For grid view, sort newest to oldest (descending)
            // For chart view, sort oldest to newest (ascending)
            return showGrid ? dateB - dateA : dateA - dateB;
        });

        if (timeRange === 'MAX') {
            return sortedData;
        }

        const yearLimit = {
            '1Y': 1,
            '3Y': 3,
            '5Y': 5,
            '10Y': 10
        }[timeRange];

        if (periodType === 'annual') {
            // For grid view take from start (newest), for chart view take from end (oldest)
            return showGrid ? sortedData.slice(0, yearLimit) : sortedData.slice(-yearLimit);
        } else {
            // For quarterly, first get the years we want
            const uniqueYears = [...new Set(sortedData.map(item =>
                item.year || new Date(item.date).getFullYear()
            ))];

            const relevantYears = showGrid
                ? uniqueYears.slice(0, yearLimit)  // For grid view, take newest years
                : uniqueYears.slice(-yearLimit);   // For chart view, take oldest years

            return sortedData.filter(item => {
                const itemYear = item.year || new Date(item.date).getFullYear();
                return relevantYears.includes(itemYear);
            });
        }
    };

    const formatGridValue = (params) => {
        if (typeof params.value === 'number') {
            return params.value.toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
            });
        }
        return params.value;
    };



    if (!processedData) {
        return (
            <Container>
                <Box display="flex" justifyContent="center" alignItems="center" minHeight="200px">
                    <Typography variant="h6" color="textSecondary">
                        No income statement data available
                    </Typography>
                </Box>
            </Container>
        );
    }

    return (
        <Container maxWidth="xl">
            <Box my={4}>
                {/* Data Selection Controls */}
                <Box sx={{
                    mb: 4,
                    display: 'flex',
                    gap: 2,
                    justifyContent: 'center',
                    flexWrap: 'wrap'
                }}>

                    <FormControl sx={{ minWidth: 120 }} size="small">
                        <InputLabel>Period Type</InputLabel>
                        <Select
                            value={periodType}
                            label="Period Type"
                            onChange={(e) => setPeriodType(e.target.value)}
                        >
                            <MenuItem value="annual">Annual</MenuItem>
                            <MenuItem value="quarter">Quarterly</MenuItem>
                        </Select>
                    </FormControl>
                </Box>

                {/* Time Range Buttons */}
                <TimeRangeButtons
                    periodType={periodType}
                    timeRange={timeRange}
                    setTimeRange={setTimeRange}
                    size="medium" // Can be: 'tiny', 'small', 'medium', 'large', 'xlarge'
                />

                {/* Grid/Chart Toggle */}
                <Box my={4} display="flex" justifyContent="center">
                    <FormControlLabel
                        control={
                            <Switch
                                checked={showGrid}
                                onChange={(e) => setShowGrid(e.target.checked)}
                                color="primary"
                            />
                        }
                        label={showGrid ? "Charts View" : "Grid View"}
                    />
                </Box>

                {showGrid ? (
                    <Box sx={{ width: '100%', mt: 2 }}>
                        <DataGrid
                            rows={prepareGridData()}
                            columns={prepareGridColumns()}
                            pageSize={21}
                            rowsPerPageOptions={[21]}
                            disableSelectionOnClick
                            autoHeight
                        />
                    </Box>
                ) : (
                    <Grid container spacing={2}>
                        {chartConfigs.map((config, index) => (
                            <Grid item xs={12} sm={6} key={index}>
                                <ContentAccessControl
                                    hasAccess={
                                        config.access === 'Free' ||
                                        (config.access === 'Basic' && ['Basic', 'Premium'].includes(user.plan.name)) ||
                                        (config.access === 'Premium' && user.plan.name === 'Premium')
                                    }
                                    upgradeLink="/upgrade"
                                >
                                    <Typography style={{ textAlign: 'center' }} variant="h4" gutterBottom>
                                        {config.label}
                                    </Typography>
                                    <BarChart data={prepareChartData(config.label, config.key, config.colors)} />
                                </ContentAccessControl>
                            </Grid>
                        ))}
                    </Grid>
                )}
            </Box>
        </Container>
    );
};

export default CompanyIncomeStatement;