import React from 'react';
import {
    Box,
    IconButton,
    Button,
    Typography,
    Stack,
    Chip,
    Divider
} from '@mui/material';
import {
    Close as CloseIcon,
    Clear as ClearIcon
} from '@mui/icons-material';
import ComparisonSearchBar from './ComparisonSearchBar';

const ControlPanel = ({
    onAddSymbol,
    onRemoveSymbol,
    symbols,
    maxSymbols,
    selectedTimeframe,
    setSelectedTimeframe,
    toggleDrawer,
    isMobile,
    theme,
    onClearAll
}) => {
    const timeframes = ['1M', '6M', 'YTD', '1Y', '5Y'];

    return (
        <Box sx={{ p: 2 }}>
            <Stack spacing={3}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography variant="h6" color="text.primary">Compare Assets</Typography>
                    {isMobile && (
                        <IconButton onClick={toggleDrawer} size="small">
                            <CloseIcon />
                        </IconButton>
                    )}
                </Box>

                <Box>
                    <Box sx={{ display: 'flex', gap: 1, mb: 1 }}>
                        <Box sx={{ flexGrow: 1 }}>
                            <ComparisonSearchBar
                                onSymbolSelect={onAddSymbol}
                                maxSymbols={maxSymbols}
                                currentSymbols={symbols}
                            />
                        </Box>
                    </Box>
                    <Typography variant="body2" color="text.secondary">
                        {symbols.length} of {maxSymbols} symbols used
                    </Typography>
                </Box>

                <Box>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 1 }}>
                        <Typography variant="subtitle2" color="text.secondary">
                            Selected Symbols
                        </Typography>
                        {symbols.length > 0 && (
                            <Button
                                startIcon={<ClearIcon />}
                                onClick={onClearAll}
                                size="small"
                                color="error"
                            >
                                Clear All
                            </Button>
                        )}
                    </Box>
                    <Stack
                        direction="row"
                        spacing={1}
                        flexWrap="wrap"
                        gap={1}
                        justifyContent="center"
                        alignItems="center"
                    >
                        {symbols.map((symbol) => (
                            <Chip
                                key={symbol}
                                label={symbol}
                                onDelete={() => onRemoveSymbol(symbol)}
                                color="primary"
                                size="small"
                            />
                        ))}
                    </Stack>

                </Box>

                <Divider />

                <Box>
                    <Typography variant="subtitle2" color="text.secondary" sx={{ mb: 1 }}>
                        Time Period
                    </Typography>
                    <Stack
                        direction="row"
                        spacing={1}
                        flexWrap="wrap"
                        gap={1}
                        justifyContent="center"
                        alignItems="center"
                    >
                        {timeframes.map((timeframe) => (
                            <Button
                                key={timeframe}
                                variant={selectedTimeframe === timeframe ? 'contained' : 'outlined'}
                                onClick={() => setSelectedTimeframe(timeframe)}
                                size="small"
                            >
                                {timeframe}
                            </Button>
                        ))}
                    </Stack>
                </Box>
            </Stack>
        </Box>
    );
};

export default ControlPanel;