import React, { useState, useEffect } from 'react';
import axios from '../config/axiosConfig';
import { Button, MenuItem, TextField, CircularProgress, Box } from '@mui/material';

const CheckoutForm = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [plans, setPlans] = useState([]);
  const [selectedPlan, setSelectedPlan] = useState('');

  useEffect(() => {
    const fetchPlans = async () => {
      try {
        const response = await axios.get('/api/stripe/plans');
        setPlans(response.data);
        if (response.data.length > 0) {
          setSelectedPlan(response.data[0].id);
        }
      } catch (err) {
        console.error('Error fetching plans:', err);
        setError('Failed to load plans.');
      }
    };

    fetchPlans();
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();

    setLoading(true);

    try {
      const { data: sessionId } = await axios.post('/api/stripe/create-checkout-session', {
        priceId: selectedPlan,
      });

      // Redirect to Stripe Checkout
      window.location.href = `https://checkout.stripe.com/pay/${sessionId}`;
    } catch (err) {
      console.error('Error:', err);
      setError('Failed to create checkout session.');
    }

    setLoading(false);
  };

  return (
    <Box component="form" onSubmit={handleSubmit} sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
      {error && <div style={{ color: 'red' }}>{error}</div>}
      <TextField
        select
        label="Select Plan"
        value={selectedPlan}
        onChange={(e) => setSelectedPlan(e.target.value)}
        fullWidth
      >
        {plans.map((plan) => (
          <MenuItem key={plan.id} value={plan.id}>
            {plan.name} - ${plan.price}
          </MenuItem>
        ))}
      </TextField>
      <Button type="submit" variant="contained" color="primary" disabled={loading}>
        {loading ? <CircularProgress size={24} /> : 'Pay'}
      </Button>
    </Box>
  );
};

export default CheckoutForm;
