import React from 'react';
import { Grid, Box, Typography } from '@mui/material';
import DoughnutChart from '../components/DoughnutChart';

const PortfolioSummary = ({ totalCost = 0, totalValue = 0, dailyChange = 0, bestPerformer, worstPerformer, portfolioData = {}, baseCurrency = 'USD' }) => {

  const formatCurrency = (value) => {
    if (typeof value !== 'number') return 'N/A';
    return new Intl.NumberFormat('en-US', { style: 'currency', currency: baseCurrency }).format(value);
  };

  const renderPerformer = (performer) => {
    if (!performer) return 'N/A';
    return `${performer.symbol} (${formatCurrency(performer.pnl)})`;
  };

  return (
    <Grid container columnSpacing={{ xs: 1, sm: 2, md: 3 }} mt={2}>
      <Grid item xs={12} sm={4} md={4}>
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', height: '100%', justifyContent: 'center' }}>
          <Box sx={{ boxShadow: 10, p: 2, textAlign: 'center' }}>
            <Box component="span" sx={{ display: 'block' }}>
              <strong>Total P&L:</strong>
            </Box>
            <Box component="span" sx={{ display: 'block', fontSize: '2rem', color: (totalValue - totalCost) > 0 ? 'green' : (totalValue - totalCost) < 0 ? 'red' : 'gray' }}>
              {formatCurrency(totalValue - totalCost)}
            </Box>
          </Box>
        </Box>
      </Grid>

      <Grid item xs={12} sm={4} md={4}>
        <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%', gap: 2 }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', boxShadow: 10, width: '100%', flex: 1, p: 2 }}>
            <Box sx={{ p: 2, flex: 1, textAlign: 'center' }}>
              <Box component="span" sx={{ display: 'block' }}>
                <strong>Total Cost:</strong>
              </Box>
              <Box component="span" sx={{ display: 'block', fontSize: '1.5rem' }}>
                {formatCurrency(totalCost)}
              </Box>
            </Box>
            <Box sx={{ p: 2, flex: 1, textAlign: 'center' }}>
              <Box component="span" sx={{ display: 'block' }}>
                <strong>Total Value:</strong>
              </Box>
              <Box component="span" sx={{ display: 'block', fontSize: '1.5rem' }}>
                {formatCurrency(totalValue)}
              </Box>
            </Box>
          </Box>

          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', boxShadow: 10, flex: 1, p: 2 }}>
            <Box sx={{ p: 2, flex: 1, textAlign: 'center' }}>
              <Box component="span" sx={{ display: 'block' }}>
                <strong>
                  {totalValue - totalCost > 0 ? 'Best Performer:' : 'Worst Performer:'}
                </strong>
              </Box>
              <Box component="span" sx={{ color: totalValue - totalCost > 0 ? 'green' : 'red' }}>
                {totalValue - totalCost > 0 ? renderPerformer(bestPerformer) : renderPerformer(worstPerformer)}
              </Box>
            </Box>

            <Box sx={{ p: 2, flex: 1, textAlign: 'center' }}>
              <Box component="span" sx={{ display: 'block' }}>
                <strong>Daily Change:</strong>
              </Box>
              <Box component="span" sx={{ display: 'block', fontSize: '1rem', color: dailyChange > 0 ? 'green' : dailyChange < 0 ? 'red' : 'gray' }}>
                {formatCurrency(dailyChange)}
              </Box>
            </Box>
          </Box>
        </Box>
      </Grid>

      <Grid item xs={12} sm={4} md={4}>
        <Box sx={{ boxShadow: 10, display: 'flex', justifyContent: 'center', alignItems: 'center', p: 2, height: '100%' }}>
          {Object.keys(portfolioData).length > 0 ? (
            <DoughnutChart
              data={portfolioData}
              headline={`Allocation (${baseCurrency})`}
              currency={baseCurrency}
            />
          ) : (
            <Typography>No data available for chart</Typography>
          )}
        </Box>
      </Grid>
    </Grid>
  );
};

export default PortfolioSummary;