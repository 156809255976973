import React, { useState, useEffect } from 'react';
import {
    Box,
    Grid,
    Paper,
    Typography,
    useTheme,
    CircularProgress
} from '@mui/material';
import axios from '../config/axiosConfig';

const ReturnCard = ({ timeframe, stockSymbol, returns }) => {
    const theme = useTheme();

    if (!returns) return null;

    const { stockReturn, benchmarkReturn } = returns;

    return (
        <Paper
            sx={{
                bgcolor: theme.palette.mode === 'dark' ? 'background.default' : 'background.paper',
                p: 2,
                height: '100%',
                borderRadius: 2,
            }}
        >
            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: 2,
                textAlign: 'center'
            }}>
                <Typography
                    variant="subtitle2"
                    color="text.secondary"
                    sx={{
                        mb: 1,
                        textTransform: 'uppercase',
                        letterSpacing: '0.1em'
                    }}
                >
                    {timeframe} Return
                </Typography>

                <Box sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap: 1.5
                }}>
                    <Typography variant="body1" fontWeight="medium">
                        {stockSymbol}
                    </Typography>
                    <Typography
                        variant="caption"
                        color="text.secondary"
                        sx={{ px: 0.5 }}
                    >
                        vs.
                    </Typography>
                    <Typography variant="body1" fontWeight="medium">
                        SPY
                    </Typography>
                </Box>

                <Box sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    mt: 1,
                    px: 2,
                    gap: 2
                }}>
                    <Typography
                        variant="h6"
                        fontWeight="bold"
                        color={stockReturn >= 0 ? 'positive.main' : 'negative.main'}
                    >
                        {stockReturn >= 0 ? '+' : ''}{stockReturn.toFixed(2)}%
                    </Typography>
                    <Typography
                        variant="h6"
                        fontWeight="bold"
                        color={benchmarkReturn >= 0 ? 'positive.main' : 'negative.main'}
                    >
                        {benchmarkReturn >= 0 ? '+' : ''}{benchmarkReturn.toFixed(2)}%
                    </Typography>
                </Box>
            </Box>
        </Paper>
    );
};

const ReturnComparison = ({ symbol }) => {
    const [returnsData, setReturnsData] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchReturns = async () => {
            try {
                setLoading(true);
                const response = await axios.get(`/api/returns-comparison/${symbol}`);
                
                // Only set data if we have returns
                if (response.data.returns && Object.keys(response.data.returns).length > 0) {
                    setReturnsData(response.data);
                } else {
                    setReturnsData(null);
                }
            } catch (err) {
                console.error('Error fetching returns data:', err);
                setReturnsData(null);
            } finally {
                setLoading(false);
            }
        };

        if (symbol) {
            fetchReturns();
        }
    }, [symbol]);

    const timeframes = [
        { key: 'ytd', label: 'YTD' },
        { key: 'oneYear', label: '1-Year' },
        { key: 'threeYears', label: '3-Year' },
        { key: 'fiveYears', label: '5-Year' }
    ];

    const availableReturns = returnsData ?
        Object.keys(returnsData.returns).length : 0;

    const getGridItemWidth = (totalCards) => {
        switch (totalCards) {
            case 1:
                return '100%';
            case 2:
                return '48%';
            case 3:
                return '32%';
            case 4:
                return '24%';
            default:
                return '24%';
        }
    };

    // If no data or loading complete with no returns, render nothing
    if (!returnsData || Object.keys(returnsData.returns).length === 0) {
        return null;
    }

    // Show loading spinner only while fetching data
    if (loading) {
        return (
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    minHeight: 200
                }}
            >
                <CircularProgress />
            </Box>
        );
    }

    return (
        <Grid
            container
            spacing={2}
            sx={{
                justifyContent: availableReturns <= 3 ? 'center' : 'space-between',
                maxWidth: '100%',
                mx: 'auto'
            }}
        >
            {timeframes.map(({ key, label }) => (
                <Grid
                    item
                    xs={12}
                    sm={availableReturns === 1 ? 12 : 6}
                    md={availableReturns === 1 ? 6 : 'auto'}
                    key={key}
                    sx={{
                        width: {
                            md: getGridItemWidth(availableReturns)
                        },
                        flexBasis: {
                            md: getGridItemWidth(availableReturns)
                        },
                        maxWidth: {
                            md: getGridItemWidth(availableReturns)
                        },
                        ...(availableReturns === 1 && {
                            maxWidth: {
                                md: '500px'
                            },
                            margin: '0 auto'
                        })
                    }}
                >
                    {returnsData?.returns[key] && (
                        <ReturnCard
                            timeframe={label}
                            stockSymbol={symbol}
                            returns={returnsData.returns[key]}
                        />
                    )}
                </Grid>
            ))}
        </Grid>
    );
};

export default ReturnComparison;