import React from 'react';
import { 
  Box, 
  Grid, 
  Typography, 
  Link,
  Paper,
  useTheme
} from '@mui/material';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import Description from './Description';

const CompanyProfile = ({ data }) => {
  const theme = useTheme();
  const companyData = data?.[0];

  // Helper function to check if text is meaningful
  const hasValidDescription = (text) => {
    if (!text || typeof text !== 'string') return false;
    
    const trimmedText = text.trim();
    if (trimmedText.length === 0) return false;
    
    const invalidPatterns = [
      'NA',
      'N/A',
      'N/a',
      'n/a',
      'Not Available',
      'Not Applicable'
    ];
    
    return !invalidPatterns.some(pattern => 
      trimmedText.toUpperCase() === pattern.toUpperCase()
    );
  };

  // Helper function to check if contact information is meaningful
  const hasValidContactInfo = (data) => {
    // Check if any address component is valid
    const hasValidAddress = [
      data.address,
      data.city,
      data.state,
      data.zip
    ].some(component => hasValidDescription(component));

    // Check if country or phone is valid
    const hasValidCountry = hasValidDescription(data.country);
    const hasValidPhone = hasValidDescription(data.phone);
    const hasWebsite = hasValidDescription(data.website);

    // Return true if we have either:
    // 1. Valid address components AND country
    // 2. Valid phone
    // 3. Valid website
    return (hasValidAddress && hasValidCountry) || hasValidPhone || hasWebsite;
  };

  if (!companyData) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="200px"
        width="100%"
      >
        <Typography variant="h6" color="text.secondary">
          No company profile data available
        </Typography>
      </Box>
    );
  }

  const InfoSection = ({ title, children }) => (
    <Paper 
      elevation={3} 
      sx={{ 
        p: 3,
        height: '100%',
        backgroundColor: theme.palette.background.paper,
      }}
    >
      <Typography 
        variant="h6" 
        sx={{ 
          fontWeight: 'bold',
          mb: 2,
          color: theme.palette.primary.main
        }}
      >
        {title}
      </Typography>
      {children}
    </Paper>
  );

  const InfoItem = ({ label, value, hide = false }) => {
    if (hide || !hasValidDescription(value)) return null;
    
    return (
      <Typography 
        variant="body1" 
        sx={{ 
          mb: 1.5,
          '& strong': {
            fontWeight: 600,
            color: theme.palette.text.primary,
          }
        }}
      >
        <strong>{label}:</strong>{' '}
        <span style={{ color: theme.palette.text.secondary }}>
          {value}
        </span>
      </Typography>
    );
  };

  // Helper to format address
  const formatAddress = (data) => {
    const components = [data.address, data.city, data.state, data.zip]
      .filter(component => hasValidDescription(component));
    return components.length > 0 ? components.join(', ') : null;
  };

  return (
    <Box sx={{ width: '100%', p: 2 }}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <InfoSection title="Company Information">
            <InfoItem label="Symbol" value={companyData.symbol} />
            <InfoItem label="CEO" value={companyData.ceo} />
            <InfoItem label="Industry" value={companyData.industry} />
            <InfoItem label="Sector" value={companyData.sector} />
          </InfoSection>
        </Grid>

        <Grid item xs={12} md={6}>
          <InfoSection title="Financial Information">
            <InfoItem 
              label="Market Cap" 
              value={companyData.mktCap ? `$${companyData.mktCap.toLocaleString()}` : null} 
            />
            <InfoItem label="Beta" value={companyData.beta} />
            <InfoItem label="Range" value={companyData.range} />
            <InfoItem 
              label="Volume Average" 
              value={companyData.volAvg ? companyData.volAvg.toLocaleString() : null} 
            />
            <InfoItem label="DCF Diff" value={companyData.dcfDiff} />
            <InfoItem label="DCF" value={companyData.dcf} />
            <InfoItem 
              label="IPO Date" 
              value={companyData.ipoDate ? new Date(companyData.ipoDate).toLocaleDateString() : null} 
            />
          </InfoSection>
        </Grid>

        {/* Only show Contact Information if there's valid data */}
        {hasValidContactInfo(companyData) && (
          <Grid item xs={12} md={6}>
            <InfoSection title="Contact Information">
              <InfoItem 
                label="Address" 
                value={formatAddress(companyData)} 
              />
              <InfoItem label="Country" value={companyData.country} />
              <InfoItem label="Phone" value={companyData.phone} />
              {companyData.website && (
                <Box sx={{ mt: 2 }}>
                  <Link
                    href={companyData.website}
                    target="_blank"
                    rel="noopener noreferrer"
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: 0.5,
                      color: theme.palette.primary.main,
                      textDecoration: 'none',
                      '&:hover': {
                        textDecoration: 'underline'
                      }
                    }}
                  >
                    Go to website
                    <OpenInNewIcon sx={{ fontSize: 16 }} />
                  </Link>
                </Box>
              )}
            </InfoSection>
          </Grid>
        )}

        {hasValidDescription(companyData.description) && (
          <Grid item xs={12}>
            <Paper 
              elevation={3} 
              sx={{ 
                p: 3,
                backgroundColor: theme.palette.background.paper
              }}
            >
              <Typography 
                variant="h6" 
                sx={{ 
                  fontWeight: 'bold',
                  mb: 2,
                  color: theme.palette.primary.main
                }}
              >
                Company Description
              </Typography>
              <Description text={companyData.description} />
            </Paper>
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

export default CompanyProfile;