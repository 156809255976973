import React from 'react';
import { Container, Grid, Typography, Box, useTheme } from '@mui/material';
import {
  Assessment as AssessmentIcon,
  FilterList as FilterListIcon,
  TrendingUp as TrendingUpIcon,
  AccountBalanceWallet as AccountBalanceWalletIcon,
  Visibility as VisibilityIcon,
  CurrencyBitcoin as CurrencyBitcoinIcon,
  MoreHoriz as MoreHorizIcon,
  Article as ArticleIcon,
  Newspaper as NewspaperIcon
} from '@mui/icons-material';

// const features = [
//   {
//     icon: AssessmentIcon,
//     title: 'Financial Data & Analytics',
//     description: 'Access robust tools for financial data analysis and insights',
//   },
//   {
//     icon: FilterListIcon,
//     title: 'Advanced Market Screeners',
//     description: 'Screen and analyze markets with advanced filtering options',
//   },
//   {
//     icon: TrendingUpIcon,
//     title: 'Stock Screeners',
//     description: 'Identify top stocks using customizable screening criteria',
//   },
//   {
//     icon: ShowChartIcon,
//     title: 'Detailed Stocks Overview',
//     description: 'Get comprehensive overviews of individual stocks and market trends',
//   },
//   {
//     icon: AccountBalanceWalletIcon,
//     title: 'Portfolio Tracking',
//     description: 'Monitor your investment portfolio with detailed analytics',
//   },
//   {
//     icon: VisibilityIcon,
//     title: 'Watchlist Tracking',
//     description: 'Keep an eye on your favorite stocks and assets',
//   },
//   {
//     icon: CurrencyBitcoinIcon,
//     title: 'Crypto Price Tracking',
//     description: 'Track cryptocurrency prices and market trends',
//   },
//   {
//     icon: UpdateIcon,
//     title: 'Daily Updates',
//     description: 'Get daily updates for your Watchlists and Portfolios',
//   },
//   {
//     icon: MoreHorizIcon,
//     title: 'More',
//     description: 'Coming Soon...',
//   },
// ];

const features = [
  {
    icon: AssessmentIcon,
    title: 'Financial Data & Analytics',
    description: 'Access comprehensive market data and advanced analytical tools'
  },
  {
    icon: FilterListIcon,
    title: 'Advanced Screeners',
    description: 'Screen stocks and markets using professional-grade filters'
  },
  {
    icon: TrendingUpIcon,
    title: 'Companies Overview',
    description: 'In-depth analysis of company financials, ratings, and metrics'
  },
  {
    icon: AccountBalanceWalletIcon,
    title: 'Portfolio Management',
    description: 'Track and analyze your investment portfolio performance'
  },
  {
    icon: VisibilityIcon,
    title: 'Watchlist Tracking',
    description: 'Keep an eye on your favorite stocks and assets'
  },
  {
    icon: CurrencyBitcoinIcon,
    title: 'Crypto',
    description: 'Track crypto prices in real-time'
  },
  {
    icon: ArticleIcon,
    title: 'Earnings & Reports',
    description: 'Access earnings transcripts and analyst price targets'
  },
  {
    icon: NewspaperIcon,
    title: 'Market News',
    description: 'Stay informed with latest market news and updates'
  },
  {
    icon: MoreHorizIcon,
    title: 'More',
    description: 'Coming Soon...',
  },
];

const Features = () => {
  const theme = useTheme();

  return (
    <Container>
      <Box sx={{ padding: theme.spacing(4) }}>
        <Grid container spacing={4}>
          {features.map((feature, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <Box
                sx={{
                  height: '300px',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                  padding: theme.spacing(3),
                  backgroundColor: theme.palette.background.paper,
                  borderRadius: 2,
                  boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
                  transition: 'transform 0.3s, box-shadow 0.3s',
                  '&:hover': {
                    transform: 'scale(1.05)',
                    boxShadow: '0px 8px 20px rgba(0, 0, 0, 0.15)',
                  },
                }}
              >
                <Box
                  sx={{ height: 60, marginBottom: theme.spacing(2) }}
                >
                  <feature.icon sx={{ fontSize: 60 }} />
                </Box>
                <Typography
                  variant="h6"
                  component="h3"
                  gutterBottom
                  sx={{ fontWeight: 'bold', fontSize: '1.25rem', textAlign: 'center' }}
                >
                  {feature.title}
                </Typography>
                <Typography
                  variant="body2"
                  align="center"
                  sx={{ fontSize: '1rem' }}
                >
                  {feature.description}
                </Typography>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Container>
  );
};

export default Features;
