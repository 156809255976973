// BlogList.js
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import axios from '../config/axiosConfig';
import { 
  Card, 
  CardMedia, 
  CardContent, 
  Typography, 
  Grid, 
  Container, 
  Box, 
  CircularProgress,
  Divider,
  useTheme
} from '@mui/material';
import { 
  AccessTime as AccessTimeIcon,
  Person as PersonIcon
} from '@mui/icons-material';

const BlogList = () => {
  const [blogs, setBlogs] = useState([]);
  const [loading, setLoading] = useState(true);
  const theme = useTheme();

  useEffect(() => {
    axios.get('/blogs')
      .then(response => {
        setBlogs(response.data);
        setLoading(false);
      })
      .catch(error => {
        console.error(error);
        setLoading(false);
      });
  }, []);

  if (loading) {
    return (
      <Box 
        sx={{ 
          display: 'flex', 
          justifyContent: 'center', 
          alignItems: 'center', 
          minHeight: '60vh' 
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Container maxWidth="lg" sx={{ py: 8 }}>
      {/* Header Section */}
      <Box sx={{ mb: 6, textAlign: 'center' }}>
        <Typography 
          variant="h2" 
          component="h1" 
          gutterBottom
          sx={{ 
            fontWeight: 'bold',
            fontSize: { xs: '2.5rem', md: '3.5rem' },
            mb: 2
          }}
        >
          Market Rodeo Blog
        </Typography>
        <Typography 
          variant="h5" 
          color="text.secondary"
          sx={{ 
            maxWidth: '800px', 
            mx: 'auto',
            mb: 4
          }}
        >
          Insights and updates about our platform, features, and market analysis
        </Typography>
      </Box>

      {/* Blog Grid */}
      <Grid container spacing={4}>
        {blogs.map(blog => (
          <Grid item xs={12} md={6} lg={4} key={blog._id}>
            <Card 
              sx={{ 
                height: '100%', 
                display: 'flex', 
                flexDirection: 'column',
                transition: 'transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out',
                '&:hover': {
                  transform: 'translateY(-4px)',
                  boxShadow: theme.shadows[4],
                }
              }}
            >
              <Link to={`/blogs/${blog._id}`} style={{ textDecoration: 'none', color: 'inherit' }}>
                <CardMedia
                  component="img"
                  height="240"
                  image={blog.imageUrl}
                  alt={blog.title}
                  sx={{
                    objectFit: 'cover',
                  }}
                />
              </Link>
              
              <CardContent sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', gap: 2 }}>
                <Box sx={{ mb: 2 }}>
                  <Typography 
                    variant="h5" 
                    component="h2" 
                    gutterBottom 
                    sx={{ 
                      fontWeight: 'bold',
                      fontSize: '1.5rem',
                      lineHeight: 1.4,
                      mb: 2
                    }}
                  >
                    <Link 
                      to={`/blogs/${blog._id}`} 
                      style={{ 
                        textDecoration: 'none', 
                        color: 'inherit',
                        '&:hover': {
                          color: theme.palette.primary.main
                        }
                      }}
                    >
                      {blog.title}
                    </Link>
                  </Typography>
                  
                  <Typography 
                    variant="body2" 
                    color="text.secondary" 
                    sx={{ 
                      mb: 2,
                      display: '-webkit-box',
                      WebkitLineClamp: 3,
                      WebkitBoxOrient: 'vertical',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis'
                    }}
                  >
                    {blog.content.split('.')[0]}...
                  </Typography>
                </Box>

                <Box sx={{ mt: 'auto' }}>
                  <Divider sx={{ mb: 2 }} />
                  
                  <Grid container spacing={2} alignItems="center">
                    <Grid item xs={12} sm={6}>
                      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                        <PersonIcon color="action" sx={{ fontSize: 20 }} />
                        <Typography variant="subtitle2" color="text.secondary">
                          {blog.author}
                        </Typography>
                      </Box>
                    </Grid>
                    
                    <Grid item xs={12} sm={6}>
                      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, justifyContent: 'flex-end' }}>
                        <AccessTimeIcon color="action" sx={{ fontSize: 20 }} />
                        <Typography variant="subtitle2" color="text.secondary">
                          {blog.readTime} min read
                        </Typography>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default BlogList;