import React from 'react';
import { Doughnut } from 'react-chartjs-2';

const CircularProgressBar = ({ header, score, recommendation }) => {
    const data = {
        datasets: [
            {
                data: [score, 5 - score], // Adjusted to a total of 5
                backgroundColor: ['#10DB06', '#e0e0e0'],
                borderWidth: 0, // No border
            },
        ],
    };

    const options = {
        cutout: '80%',
        rotation: 0,
        circumference: 350,
        plugins: {
            tooltip: { enabled: false },
            datalabels: {
                display: false, // Hide labels inside the doughnut segments
            },
        },
    };

    // Style for the overlay text
    const overlayStyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        textAlign: 'center',
    };

    return (
        <div style={{ position: 'relative', width: '200px', margin: '20px', textAlign: 'center' }}>
            {/* Overlay for the score text */}
            <div style={overlayStyle}>
                <h4 style={{ marginBottom: '5px', fontSize: '18px' }}>{header} : {score}</h4>
                <h5 style={{ fontStyle: 'italic', marginTop: '0', marginBottom: '10px', fontSize: '16px' }}>({recommendation})</h5>
            </div>
            {/* Doughnut chart */}
            <Doughnut data={data} options={options} />
        </div>
    );
};

export default CircularProgressBar;
