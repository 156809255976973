import axios from 'axios';

// Define the baseURL using the environment variable or a fallback value
const backendURL = process.env.REACT_APP_BACKEND_URL || 'http://localhost:5000';

// Create an Axios instance with default configurations
const axiosInstance = axios.create({
    baseURL: backendURL,
    withCredentials: true,
});

// Add response interceptor
axiosInstance.interceptors.response.use(
    (response) => response,  // Return successful responses as-is
    async (error) => {
        // Handle session expiration
        if (error.response?.status === 401) {
            // Clear local storage
            localStorage.removeItem('isAuthenticated');
            localStorage.removeItem('user');
            
            // Redirect to login page
            window.location.href = '/login';
        }
        return Promise.reject(error);
    }
);

export default axiosInstance;