import React, { useState, useMemo } from 'react';
import {
    Box,
    Typography,
    Paper,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    useTheme,
    Avatar
} from '@mui/material';
import withPlanAccess from './WithPlanAccess';

const EarningsTranscript = ({ data }) => {
    const theme = useTheme();
    
    const sortedData = useMemo(() => {
        if (!data?.length) return [];
        return [...data].sort((a, b) => {
            if (b.year !== a.year) return b.year - a.year;
            return b.quarter - a.quarter;
        });
    }, [data]);

    const [selectedYear, setSelectedYear] = useState(sortedData[0]?.year || '');
    const [selectedQuarter, setSelectedQuarter] = useState(sortedData[0]?.quarter || '');

    const years = useMemo(() => {
        if (!data?.length) return [];
        return [...new Set(data.map(t => t.year))].sort((a, b) => b - a);
    }, [data]);

    const quarters = useMemo(() => {
        if (!data?.length) return [];
        return [...new Set(data
            .filter(t => t.year === selectedYear)
            .map(t => t.quarter))]
            .sort((a, b) => b - a);
    }, [data, selectedYear]);

    const selectedTranscript = useMemo(() => {
        if (!sortedData.length) return null;
        return sortedData.find(t => t.year === selectedYear && t.quarter === selectedQuarter);
    }, [sortedData, selectedYear, selectedQuarter]);

    if (!sortedData.length) {
        return null;
    }

    const handleYearChange = (event) => {
        const newYear = event.target.value;
        setSelectedYear(newYear);
        // Set first available quarter for the new year
        const availableQuarters = data
            .filter(t => t.year === newYear)
            .map(t => t.quarter)
            .sort((a, b) => b - a);
        setSelectedQuarter(availableQuarters[0]);
    };

    const handleQuarterChange = (event) => {
        setSelectedQuarter(event.target.value);
    };

    const parseTranscript = (content) => {
        if (!content) return [];

        return content.split('\n').reduce((acc, line) => {
            const colonIndex = line.indexOf(':');
            if (colonIndex > -1 && colonIndex < 50) {
                acc.push({
                    speaker: line.slice(0, colonIndex).trim(),
                    text: line.slice(colonIndex + 1).trim()
                });
            } else if (acc.length > 0) {
                acc[acc.length - 1].text += ' ' + line.trim();
            }
            return acc;
        }, []);
    };

    const getInitials = (name) => {
        const names = name.split(' ');
        if (names.length === 1) return name[0].toUpperCase();
        return (names[0][0] + names[names.length - 1][0]).toUpperCase();
    };

    const getAvatarColor = (name) => {
        const colors = [
            '#1976d2', // Blue
            '#dc004e', // Red
            '#388e3c', // Green
            '#f57c00', // Orange
            '#6d1b7b', // Purple
            '#0288d1', // Light Blue
        ];

        let hash = 0;
        for (let i = 0; i < name.length; i++) {
            hash = name.charCodeAt(i) + ((hash << 5) - hash);
        }

        return colors[Math.abs(hash) % colors.length];
    };

    return (
        <Box sx={{ width: '100%', maxWidth: '1200px', mx: 'auto' }}>
            {data.length > 1 && (
                <Box sx={{
                    mb: 4,
                    display: 'flex',
                    gap: 2,
                    flexWrap: 'wrap'
                }}>
                    <FormControl sx={{ minWidth: 120 }} size="small">
                        <InputLabel>Year</InputLabel>
                        <Select
                            value={selectedYear}
                            label="Year"
                            onChange={handleYearChange}
                        >
                            {years.map(year => (
                                <MenuItem key={year} value={year}>
                                    {year}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>

                    <FormControl sx={{ minWidth: 120 }} size="small">
                        <InputLabel>Quarter</InputLabel>
                        <Select
                            value={selectedQuarter}
                            label="Quarter"
                            onChange={handleQuarterChange}
                        >
                            {quarters.map(quarter => (
                                <MenuItem key={quarter} value={quarter}>
                                    {`Q${quarter}`}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Box>
            )}

            {selectedTranscript && (
                <Paper
                    elevation={3}
                    sx={{
                        p: 4,
                        backgroundColor: theme.palette.mode === 'dark' ?
                            'rgba(255, 255, 255, 0.05)' :
                            'rgba(0, 0, 0, 0.02)',
                        borderRadius: 2
                    }}
                >
                    <Typography
                        variant="h5"
                        gutterBottom
                        sx={{
                            color: theme.palette.primary.main,
                            fontWeight: 600,
                            mb: 3
                        }}
                    >
                        {`Q${selectedTranscript.quarter} ${selectedTranscript.year} Earnings Call`}
                    </Typography>
                    <Typography
                        variant="subtitle2"
                        sx={{
                            color: theme.palette.text.secondary,
                            mb: 4
                        }}
                    >
                        {new Date(selectedTranscript.date).toLocaleDateString('en-US', {
                            year: 'numeric',
                            month: 'long',
                            day: 'numeric'
                        })}
                    </Typography>

                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 3
                    }}>
                        {parseTranscript(selectedTranscript.content).map((part, index) => (
                            <Box
                                key={index}
                                sx={{
                                    display: 'flex',
                                    gap: 2,
                                    alignItems: 'flex-start'
                                }}
                            >
                                {part.speaker && (
                                    <Avatar
                                        sx={{
                                            bgcolor: getAvatarColor(part.speaker),
                                            width: 40,
                                            height: 40,
                                            flexShrink: 0
                                        }}
                                    >
                                        {getInitials(part.speaker)}
                                    </Avatar>
                                )}
                                <Box
                                    sx={{
                                        flex: 1,
                                        backgroundColor: theme.palette.mode === 'dark' ?
                                            'rgba(255, 255, 255, 0.05)' :
                                            'rgba(0, 0, 0, 0.02)',
                                        borderRadius: 2,
                                        p: 2,
                                        '& .MuiTypography-root': {
                                            color: theme.palette.text.primary,
                                        }
                                    }}
                                >
                                    {part.speaker && (
                                        <Typography
                                            variant="subtitle2"
                                            sx={{
                                                fontWeight: 600,
                                                color: theme.palette.primary.main,
                                                mb: 1
                                            }}
                                        >
                                            {part.speaker}
                                        </Typography>
                                    )}
                                    <Typography
                                        variant="body2"
                                        sx={{
                                            lineHeight: 1.7,
                                            whiteSpace: 'pre-wrap'
                                        }}
                                    >
                                        {part.text}
                                    </Typography>
                                </Box>
                            </Box>
                        ))}
                    </Box>
                </Paper>
            )}
        </Box>
    );
};

// Maintain the plan access control
const allowedPlans = ['Premium'];
export default withPlanAccess(EarningsTranscript, allowedPlans);