export const exportToCsv = (filename, columns, data) => {
    const csvRows = [];
    
    // Extract headers from columns
    const headers = columns.map(col => col.headerName);
    csvRows.push(headers.join(',')); 

    // Extract and map data rows based on columns
    data.forEach(row => {
        const values = columns.map(col => {
            // Ensure it handles nested data, if necessary
            const value = row[col.field] !== undefined ? row[col.field] : '';
            return typeof value === 'string' ? `"${value}"` : value;
        });
        csvRows.push(values.join(','));
    });

    // Create CSV string
    const csvString = csvRows.join('\n');

    // Get current date for filename
    const currentDate = new Date();
    const formattedDate = currentDate.toISOString().split('T')[0]; // YYYY-MM-DD format
    const finalFilename = `${filename}_${formattedDate}.csv`;

    // Create a Blob for CSV and download it
    const blob = new Blob([csvString], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.setAttribute('href', url);
    a.setAttribute('download', finalFilename);
    a.click();
    window.URL.revokeObjectURL(url); // Cleanup
};
