import React, { useState, useEffect } from 'react';
import { useLocation, Link } from 'react-router-dom';
import axios from '../config/axiosConfig';
import {
  useTheme,
  Typography,
  Alert,
  Container,
  FormControl,
  InputLabel,
  Input,
  Button,
  Box,
  Paper,
  InputAdornment,
  IconButton,
  Divider,
  GlobalStyles,
  Checkbox,
  FormControlLabel,
  FormHelperText
} from '@mui/material';
import {
  Person as PersonIcon,
  Email as EmailIcon,
  Lock as LockIcon,
  Visibility as VisibilityIcon,
  VisibilityOff as VisibilityOffIcon,
  Google as GoogleIcon
} from '@mui/icons-material';
import { useAuth } from './AuthContext';
import logo from '../images/rodeoLogo.png';

const Signup = () => {
  const theme = useTheme();
  const { Signup, setUser } = useAuth();
  const [error, setError] = useState('');
  const [message, setMessage] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [acceptedTerms, setAcceptedTerms] = useState(false);
  const [showTermsError, setShowTermsError] = useState(false);
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    password: ''
  });
  const location = useLocation();

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const error = queryParams.get('error');
    if (error) {
      setError(error);
    } else {
      const code = queryParams.get('code');
      if (code) {
        handleSignupSuccess(code);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleGoogleSignup = () => {
    if (!acceptedTerms) {
      setShowTermsError(true);
      return;
    }
    Signup();
  };

  const handleSignup = async (e) => {
    e.preventDefault();
    if (!acceptedTerms) {
      setShowTermsError(true);
      return;
    }
    try {
      const response = await axios.post('/auth/localsignup', formData);
      if (response && response.data && response.data.user) {
        setMessage('Signup successful! Please check your email to verify your account.');
      }
    } catch (error) {
      setError(error.response?.data?.message || 'An error occurred');
    }
  };

  const handleTermsChange = (event) => {
    setAcceptedTerms(event.target.checked);
    if (event.target.checked) {
      setShowTermsError(false);
    }
  };

  const handleSignupSuccess = async (code) => {
    try {
      const response = await axios.post('/auth/google/token', { code });
      const data = response.data;
      setUser(data.user);
      setMessage('Signup successful! Please check your email to verify your account.');
    } catch (error) {
      setError('Error logging in');
    }
  };

  return (
    <>
      <GlobalStyles
        styles={{
          'input:-webkit-autofill, input:-webkit-autofill:hover, input:-webkit-autofill:focus, input:-webkit-autofill:active': {
            '-webkit-box-shadow': `0 0 0 30px ${theme.palette.background.paper} inset !important`,
            '-webkit-text-fill-color': `${theme.palette.text.primary} !important`,
            'caret-color': `${theme.palette.text.primary} !important`,
            'background-color': `${theme.palette.background.paper} !important`,
            'transition': 'background-color 600000s 0s, color 600000s 0s !important'
          }
        }}
      />
      <Container maxWidth="sm" sx={{
        minHeight: '100vh',
        display: 'flex',
        alignItems: 'center',
        py: 8
      }}>
        <Paper elevation={theme.palette.mode === 'dark' ? 2 : 1} sx={{
          width: '100%',
          borderRadius: 2,
          p: { xs: 3, sm: 6 },
          backgroundColor: theme.palette.mode === 'dark' ? 'background.paper' : 'background.default',
        }}>
          <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: 3
          }}>
            <img
              src={logo}
              alt="Market Rodeo Logo"
              style={{ height: 'auto', width: '69px' }}
            />

            <Box sx={{ textAlign: 'center', mb: 2 }}>
              <Typography variant="h4" gutterBottom fontWeight="bold" color="primary">
                Create Account
              </Typography>
              <Typography variant="body1" color="text.secondary">
                Enter your details to get started
              </Typography>
            </Box>

            {error && (
              <Alert
                severity="error"
                sx={{ width: '100%', borderRadius: 1 }}
              >
                {error}
              </Alert>
            )}
            {message && (
              <Alert
                severity="success"
                sx={{ width: '100%', borderRadius: 1 }}
              >
                {message}
              </Alert>
            )}

            {!message && (
              <Box
                component="form"
                onSubmit={handleSignup}
                sx={{
                  width: '100%',
                  // Make all input fields text larger
                  '& .MuiInputBase-input': {
                    fontSize: '1.2rem',  // Much larger input text
                    padding: '0.7rem 0'  // Add some vertical padding
                  },
                  // Make all labels larger
                  '& .MuiInputLabel-root': {
                    fontSize: '1.2rem'  // Larger label text
                  },
                  // Make checkbox text larger
                  '& .MuiFormControlLabel-label': {
                    fontSize: '1.2rem'
                  },
                  // Make helper/error text larger
                  '& .MuiFormHelperText-root': {
                    fontSize: '1rem'
                  }
                }}
              >
                <FormControl fullWidth variant="outlined" sx={{ mb: 3 }}>
                  <InputLabel htmlFor="firstName">First Name</InputLabel>
                  <Input
                    id="firstName"
                    type="text"
                    name="firstName"
                    autoComplete="given-name"
                    value={formData.firstName}
                    onChange={handleInputChange}
                    startAdornment={
                      <InputAdornment position="start">
                        <PersonIcon />
                      </InputAdornment>
                    }
                  />
                </FormControl>

                <FormControl fullWidth variant="outlined" sx={{ mb: 3 }}>
                  <InputLabel htmlFor="lastName">Last Name</InputLabel>
                  <Input
                    id="lastName"
                    type="text"
                    name="lastName"
                    autoComplete="family-name"
                    value={formData.lastName}
                    onChange={handleInputChange}
                    startAdornment={
                      <InputAdornment position="start">
                        <PersonIcon />
                      </InputAdornment>
                    }
                  />
                </FormControl>

                <FormControl fullWidth variant="outlined" sx={{ mb: 3 }}>
                  <InputLabel htmlFor="email">Email address</InputLabel>
                  <Input
                    id="email"
                    type="email"
                    name="email"
                    autoComplete="email"
                    value={formData.email}
                    onChange={handleInputChange}
                    startAdornment={
                      <InputAdornment position="start">
                        <EmailIcon />
                      </InputAdornment>
                    }
                  />
                </FormControl>

                <FormControl fullWidth variant="outlined" sx={{ mb: 3 }}>
                  <InputLabel htmlFor="password">Password</InputLabel>
                  <Input
                    id="password"
                    type={showPassword ? 'text' : 'password'}
                    name="password"
                    autoComplete="new-password"
                    value={formData.password}
                    onChange={handleInputChange}
                    startAdornment={
                      <InputAdornment position="start">
                        <LockIcon />
                      </InputAdornment>
                    }
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() => setShowPassword(!showPassword)}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                </FormControl>

                <FormControl
                  fullWidth
                  error={showTermsError}
                  sx={{ mb: 2 }}
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={acceptedTerms}
                        onChange={handleTermsChange}
                        color="primary"
                      />
                    }
                    label={
                      <Typography variant="body2">
                        I agree to the{' '}
                        <Link
                          to="/terms-and-conditions"
                          target="_blank"
                          style={{
                            color: theme.palette.primary.main,
                            textDecoration: 'none',
                            fontWeight: 500
                          }}
                        >
                          Terms and Conditions
                        </Link>
                        {' '}and{' '}
                        <Link
                          to="/privacy-policy"
                          target="_blank"
                          style={{
                            color: theme.palette.primary.main,
                            textDecoration: 'none',
                            fontWeight: 500
                          }}
                        >
                          Privacy Policy
                        </Link>
                      </Typography>
                    }
                  />
                  {showTermsError && (
                    <FormHelperText error>
                      You must accept the terms and conditions to continue
                    </FormHelperText>
                  )}
                </FormControl>

                <Button
                  fullWidth
                  variant="contained"
                  type="submit"
                  size="large"
                  sx={{
                    mt: 4,
                    mb: 2,
                    height: 48,
                    textTransform: 'none',
                    fontWeight: 600,
                    fontSize: '1rem'  // Larger button text
                  }}
                >
                  Create Account
                </Button>

                <Box sx={{
                  display: 'flex',
                  alignItems: 'center',
                  my: 3
                }}>
                  <Divider sx={{ flex: 1 }} />
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    sx={{ px: 2 }}
                  >
                    or
                  </Typography>
                  <Divider sx={{ flex: 1 }} />
                </Box>

                <Button
                  fullWidth
                  variant="outlined"
                  onClick={handleGoogleSignup}
                  startIcon={<GoogleIcon />}
                  sx={{
                    height: 48,
                    textTransform: 'none',
                    fontWeight: 500
                  }}
                >
                  Continue with Google
                </Button>
              </Box>
            )}

            <Typography variant="body1" sx={{ mt: 3 }}>
              Already have an account?{' '}
              <Link
                to="/login"
                style={{
                  color: theme.palette.primary.main,
                  textDecoration: 'none',
                  fontWeight: 600
                }}
              >
                Sign in
              </Link>
            </Typography>
          </Box>
        </Paper>
      </Container>
    </>
  );
};

export default Signup;