// src/util/numberUtils.js

export const formatNumber = (num) => {
  if (num == null || isNaN(num) || num === 0) {
    return '-'; // or some default placeholder
  }

  // Ensure num is a number
  const number = Number(num);

  // For very small numbers, customize the format
  if (number < 0.01 && number > 0) {
    return '0.0...1';
  }

  if (number >= 1e12) {
    return (number / 1e12).toFixed(2) + 'T'; // Trillions
  } else if (number >= 1e9) {
    return (number / 1e9).toFixed(2) + 'B'; // Billions
  } else if (number >= 1e6) {
    return (number / 1e6).toFixed(2) + 'M'; // Millions
  } else {
    return number.toLocaleString(); // Format number with commas for less than a million
  }
};

export const formatPrice = (num) => {
  if (num == null || isNaN(num)) {
    return 'N/A';
  }

  const number = Number(num);

  // For zero
  if (number === 0) {
    return '0.00';
  }

  // For very small numbers (crypto prices)
  if (Math.abs(number) < 0.0001) {
    // Convert to scientific notation to count leading zeros
    const scientificStr = number.toExponential();
    const exponent = parseInt(scientificStr.split('e')[1]);
    // Show 2 significant digits after the first non-zero digit
    const significantDigits = Math.abs(exponent) + 2;
    return number.toFixed(significantDigits);
  }

  // For regular decimal numbers
  return number.toLocaleString(undefined, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 8
  });
};