import React, { useState, useEffect } from 'react';
import {
    Box,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    IconButton,
    Typography,
    TextField,
    Stack,
    Paper,
    InputAdornment,
    Grid,
    List,
    ListItem,
    ListItemText,
    ListItemIcon,
    Select,
    MenuItem,
    FormControl
} from '@mui/material';
import {
    Add as AddIcon,
    Close as CloseIcon,
    Search as SearchIcon,
    FileDownload as FileDownloadIcon,
    CheckCircle as CheckCircleIcon
} from '@mui/icons-material';
import useCountries from '../hooks/useCountries';
import useExchanges from '../hooks/useExchanges';
import useIndustries from '../hooks/useIndustries';
import useSectors from '../hooks/useSectors';

const FilterSystem = ({ onSearch, onClear, onExport, showExport = false }) => {
    const countries = useCountries();
    const exchanges = useExchanges();
    const industries = useIndustries();
    const sectors = useSectors();

    const predefinedRanges = {
        marketCap: {
            above: [
                { label: 'Above 1M', value: 1000000 },
                { label: 'Above 10M', value: 10000000 },
                { label: 'Above 100M', value: 100000000 },
                { label: 'Above 500M', value: 500000000 },
                { label: 'Above 1B', value: 1000000000 },
                { label: 'Above 10B', value: 10000000000 },
                { label: 'Above 50B', value: 50000000000 },
                { label: 'Above 100B', value: 100000000000 },
                { label: 'Above 300B', value: 300000000000 },
                { label: 'Above 500B', value: 500000000000 },
                { label: 'Above 1T', value: 1000000000000 }
            ],
            below: [
                { label: 'Under 1M', value: 1000000 },
                { label: 'Under 10M', value: 10000000 },
                { label: 'Under 100M', value: 100000000 },
                { label: 'Under 500M', value: 500000000 },
                { label: 'Under 1B', value: 1000000000 },
                { label: 'Under 10B', value: 10000000000 },
                { label: 'Under 50B', value: 50000000000 },
                { label: 'Under 100B', value: 100000000000 },
                { label: 'Under 300B', value: 300000000000 },
                { label: 'Under 500B', value: 500000000000 },
                { label: 'Under 1T', value: 1000000000000 }
            ]
        },
        price: {
            above: [
                { label: 'Above $1', value: 1 },
                { label: 'Above $5', value: 5 },
                { label: 'Above $10', value: 10 },
                { label: 'Above $20', value: 20 },
                { label: 'Above $50', value: 50 },
                { label: 'Above $80', value: 80 },
                { label: 'Above $100', value: 100 },
                { label: 'Above $150', value: 150 },
                { label: 'Above $200', value: 200 },
                { label: 'Above $500', value: 500 },
                { label: 'Above $1000', value: 1000 }
            ],
            below: [
                { label: 'Under $1', value: 1 },
                { label: 'Under $5', value: 5 },
                { label: 'Under $10', value: 10 },
                { label: 'Under $20', value: 20 },
                { label: 'Under $50', value: 50 },
                { label: 'Under $80', value: 80 },
                { label: 'Under $100', value: 100 },
                { label: 'Under $150', value: 150 },
                { label: 'Under $200', value: 200 },
                { label: 'Under $500', value: 500 },
                { label: 'Under $1000', value: 1000 }
            ]
        },
        beta: {
            above: [
                { label: 'Above -10', value: -10 },
                { label: 'Above -5', value: -5 },
                { label: 'Above -2', value: -2 },
                { label: 'Above -1', value: -1 },
                { label: 'Above 0', value: 0 },
                { label: 'Above 1', value: 1 },
                { label: 'Above 2', value: 2 },
                { label: 'Above 5', value: 5 },
                { label: 'Above 10', value: 10 }
            ],
            below: [
                { label: 'Under -10', value: -10 },
                { label: 'Under -5', value: -5 },
                { label: 'Under -2', value: -2 },
                { label: 'Under -1', value: -1 },
                { label: 'Under 0', value: 0 },
                { label: 'Under 1', value: 1 },
                { label: 'Under 2', value: 2 },
                { label: 'Under 5', value: 5 },
                { label: 'Under 10', value: 10 }
            ]
        },
        volume: {
            above: [
                { label: 'Above 1K', value: 1000 },
                { label: 'Above 10K', value: 10000 },
                { label: 'Above 100K', value: 100000 },
                { label: 'Above 1M', value: 1000000 },
                { label: 'Above 10M', value: 10000000 },
                { label: 'Above 100M', value: 100000000 }
            ],
            below: [
                { label: 'Under 1K', value: 1000 },
                { label: 'Under 10K', value: 10000 },
                { label: 'Under 100K', value: 100000 },
                { label: 'Under 1M', value: 1000000 },
                { label: 'Under 10M', value: 10000000 },
                { label: 'Under 100M', value: 100000000 }
            ]
        }
    };

    const [availableFilters, setAvailableFilters] = useState([]);
    const [activeFilters, setActiveFilters] = useState([]);
    const [isAddFilterOpen, setIsAddFilterOpen] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [selectedFilters, setSelectedFilters] = useState(new Set());

    useEffect(() => {
        setAvailableFilters([
            { id: 'marketCap', label: 'Market Cap', type: 'range', hasPresetRanges: true },
            { id: 'price', label: 'Price', type: 'range', hasPresetRanges: true },
            { id: 'beta', label: 'Beta', type: 'range', hasPresetRanges: true },
            { id: 'volume', label: 'Volume', type: 'range', hasPresetRanges: true },
            { id: 'dividend', label: 'Dividend', type: 'range' },
            { id: 'sector', label: 'Sector', type: 'select', options: sectors || [] },
            { id: 'industry', label: 'Industry', type: 'select', options: industries || [] },
            { id: 'country', label: 'Country', type: 'select', options: countries || [] },
            { id: 'exchange', label: 'Exchange', type: 'select', options: exchanges || [] },
            { id: 'isEtf', label: 'ETF', type: 'boolean' },
            { id: 'isFund', label: 'Fund', type: 'boolean' }
        ]);
    }, [countries, exchanges, industries, sectors]);

    const toggleFilter = (filter) => {
        const newSelectedFilters = new Set(selectedFilters);

        if (newSelectedFilters.has(filter.id)) {
            newSelectedFilters.delete(filter.id);
            setActiveFilters(activeFilters.filter(f => f.id !== filter.id));
        } else {
            newSelectedFilters.add(filter.id);
            if (!activeFilters.find(f => f.id === filter.id)) {
                const newFilter = {
                    ...filter,
                    values: filter.type === 'range' ? { min: '', max: '' } :
                        filter.type === 'boolean' ? false : ''
                };
                setActiveFilters([...activeFilters, newFilter]);
            }
        }

        setSelectedFilters(newSelectedFilters);
    };

    const handleFilterValueChange = (filterId, value, isMin) => {
        setActiveFilters(activeFilters.map(filter => {
            if (filter.id === filterId) {
                if (filter.type === 'range') {
                    return {
                        ...filter,
                        values: {
                            ...filter.values,
                            [isMin ? 'min' : 'max']: value
                        }
                    };
                }
                return { ...filter, values: value };
            }
            return filter;
        }));
    };

    const handleSearch = () => {
        const filterData = activeFilters.reduce((acc, filter) => {
            if (filter.type === 'range') {
                if (filter.values.min) acc[`${filter.id}MoreThan`] = filter.values.min;
                if (filter.values.max) acc[`${filter.id}LowerThan`] = filter.values.max;
            } else {
                acc[filter.id] = filter.values;
            }
            return acc;
        }, {});
        onSearch(filterData);
    };

    const handleClear = () => {
        setActiveFilters([]);
        setSelectedFilters(new Set());
        onClear();
    };

    const renderRangeFilter = (filter) => {
        const ranges = predefinedRanges[filter.id];

        // If this filter has predefined ranges, show dropdowns
        if (ranges) {
            return (
                <Stack spacing={1} sx={{ mt: 1 }}>
                    <FormControl fullWidth size="small">
                        <Select
                            value={filter.values?.min || ''}
                            onChange={(e) => handleFilterValueChange(filter.id, e.target.value, true)}
                            displayEmpty
                            sx={{
                                color: 'white',
                                '& .MuiOutlinedInput-notchedOutline': {
                                    borderColor: 'rgba(255, 255, 255, 0.23)',
                                },
                                '& .MuiSvgIcon-root': {
                                    color: 'white',
                                },
                            }}
                        >
                            <MenuItem value="" sx={{ color: 'white' }}>
                                Select minimum
                            </MenuItem>
                            {ranges.above.map((range) => (
                                <MenuItem
                                    key={range.label}
                                    value={range.value}
                                    sx={{
                                        color: 'white',
                                        '&:hover': {
                                            bgcolor: '#333333'
                                        }
                                    }}
                                >
                                    {range.label}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>

                    <FormControl fullWidth size="small">
                        <Select
                            value={filter.values?.max || ''}
                            onChange={(e) => handleFilterValueChange(filter.id, e.target.value, false)}
                            displayEmpty
                            sx={{
                                color: 'white',
                                '& .MuiOutlinedInput-notchedOutline': {
                                    borderColor: 'rgba(255, 255, 255, 0.23)',
                                },
                                '& .MuiSvgIcon-root': {
                                    color: 'white',
                                },
                            }}
                        >
                            <MenuItem value="" sx={{ color: 'white' }}>
                                Select maximum
                            </MenuItem>
                            {ranges.below.map((range) => (
                                <MenuItem
                                    key={range.label}
                                    value={range.value}
                                    sx={{
                                        color: 'white',
                                        '&:hover': {
                                            bgcolor: '#333333'
                                        }
                                    }}
                                >
                                    {range.label}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Stack>
            );
        }

        // For filters without predefined ranges, show regular text inputs
        return (
            <Stack direction="row" spacing={1} sx={{ mt: 1 }}>
                <TextField
                    size="small"
                    placeholder="Min"
                    value={filter.values.min}
                    onChange={(e) => handleFilterValueChange(filter.id, e.target.value, true)}
                    fullWidth
                    sx={{
                        '& .MuiOutlinedInput-root': {
                            color: 'white',
                            '& fieldset': {
                                borderColor: 'rgba(255, 255, 255, 0.23)',
                            },
                        },
                        '& .MuiInputBase-input::placeholder': {
                            color: 'rgba(255, 255, 255, 0.5)',
                        },
                    }}
                />
                <TextField
                    size="small"
                    placeholder="Max"
                    value={filter.values.max}
                    onChange={(e) => handleFilterValueChange(filter.id, e.target.value, false)}
                    fullWidth
                    sx={{
                        '& .MuiOutlinedInput-root': {
                            color: 'white',
                            '& fieldset': {
                                borderColor: 'rgba(255, 255, 255, 0.23)',
                            },
                        },
                        '& .MuiInputBase-input::placeholder': {
                            color: 'rgba(255, 255, 255, 0.5)',
                        },
                    }}
                />
            </Stack>
        );
    };

    const renderFilterContent = (filter) => {
        if (filter.type === 'range') {
            return renderRangeFilter(filter);
        }

        if (filter.type === 'select') {
            return (
                <FormControl fullWidth size="small" sx={{ mt: 1 }}>
                    <Select
                        value={filter.values || ''}
                        onChange={(e) => handleFilterValueChange(filter.id, e.target.value)}
                        displayEmpty
                        MenuProps={{
                            PaperProps: {
                                sx: {
                                    maxHeight: '200px', // Adjust the dropdown height here
                                    backgroundColor: '#1E1E1E', // Optional: Customize background color
                                    '& .MuiMenuItem-root': {
                                        color: 'white', // Optional: Customize dropdown text color
                                    },
                                },
                            },
                        }}
                        sx={{
                            color: 'white',
                            '& .MuiOutlinedInput-notchedOutline': {
                                borderColor: 'rgba(255, 255, 255, 0.23)',
                            },
                            '& .MuiSvgIcon-root': {
                                color: 'white',
                            },
                        }}
                    >
                        <MenuItem value="" sx={{ color: 'white' }}>
                            Select {filter.label}
                        </MenuItem>
                        {filter.options?.map((option) => (
                            <MenuItem
                                key={option}
                                value={option}
                                sx={{
                                    color: 'white',
                                    '&:hover': {
                                        bgcolor: '#333333',
                                    },
                                }}
                            >
                                {option}
                            </MenuItem>
                        ))}
                    </Select>

                </FormControl>
            );
        }

        if (filter.type === 'boolean') {
            return (
                <Button
                    size="small"
                    variant={filter.values ? "contained" : "outlined"}
                    onClick={() => handleFilterValueChange(filter.id, !filter.values)}
                    fullWidth
                    sx={{ mt: 1 }}
                >
                    {filter.values ? "Yes" : "No"}
                </Button>
            );
        }
    };

    const filteredOptions = availableFilters.filter(filter =>
        filter.label.toLowerCase().includes(searchTerm.toLowerCase())
    );

    return (
        <Box sx={{ mb: 3 }}>
            <Paper
                sx={{
                    p: 2,
                    bgcolor: '#1E1E1E',
                    borderRadius: 1
                }}
            >
                <Stack direction="row" alignItems="center" spacing={1} sx={{ mb: 2 }}>
                    <Typography variant="body1" sx={{ color: 'white' }}>
                        {activeFilters.length} Filters
                    </Typography>
                    <Button
                        size="small"
                        startIcon={<AddIcon />}
                        onClick={() => setIsAddFilterOpen(true)}
                        variant="contained"
                    >
                        Add Filters
                    </Button>
                </Stack>

                {activeFilters.length > 0 && (
                    <Grid container spacing={2}>
                        {activeFilters.map((filter) => (
                            <Grid item xs={12} sm={6} md={4} key={filter.id}>
                                <Paper
                                    sx={{
                                        p: 1.5,
                                        bgcolor: '#2D2D2D',
                                        borderRadius: 1
                                    }}
                                >
                                    <Stack direction="row" alignItems="center" spacing={1}>
                                        <Typography variant="subtitle2" sx={{ flex: 1, color: 'white' }}>
                                            {filter.label}
                                        </Typography>
                                        <IconButton
                                            size="small"
                                            onClick={() => toggleFilter(filter)}
                                            sx={{ color: 'white' }}
                                        >
                                            <CloseIcon fontSize="small" />
                                        </IconButton>
                                    </Stack>
                                    {renderFilterContent(filter)}
                                </Paper>
                            </Grid>
                        ))}
                    </Grid>
                )}

                <Grid container spacing={1} justifyContent="flex-end" sx={{ mt: 2 }}>
                    {showExport && (
                        <Grid item>
                            <Button
                                variant="contained"
                                color="primary"
                                size="small"
                                startIcon={<FileDownloadIcon />}
                                onClick={onExport}
                            >
                                Export
                            </Button>
                        </Grid>
                    )}
                    <Grid item>
                        <Button
                            variant="outlined"
                            size="small"
                            onClick={handleClear}
                        >
                            Clear
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button
                            variant="contained"
                            color="primary"
                            size="small"
                            onClick={handleSearch}
                        >
                            Search
                        </Button>
                    </Grid>
                </Grid>

                <Dialog
                    open={isAddFilterOpen}
                    onClose={() => setIsAddFilterOpen(false)}
                    maxWidth="xs"
                    fullWidth
                    PaperProps={{
                        sx: { bgcolor: '#1E1E1E' }
                    }}
                >
                    <DialogTitle sx={{ color: 'white' }}>
                        Add Filters
                        <IconButton
                            onClick={() => setIsAddFilterOpen(false)}
                            sx={{
                                position: 'absolute',
                                right: 8,
                                top: 8,
                                color: 'white'
                            }}
                        >
                            <CloseIcon />
                        </IconButton>
                    </DialogTitle>
                    <DialogContent dividers sx={{ borderColor: 'rgba(255, 255, 255, 0.12)' }}>
                        <TextField
                            fullWidth
                            placeholder="Search filters..."
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                            sx={{
                                mb: 2,
                                '& .MuiOutlinedInput-root': {
                                    color: 'white',
                                    '& fieldset': {
                                        borderColor: 'rgba(255, 255, 255, 0.23)',
                                    },
                                },
                                '& .MuiInputBase-input::placeholder': {
                                    color: 'rgba(255, 255, 255, 0.5)',
                                },
                            }}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon sx={{ color: 'rgba(255, 255, 255, 0.7)' }} />
                                    </InputAdornment>
                                ),
                            }}
                        />
                        <List sx={{ width: '100%' }}>
                            {filteredOptions.map((filter) => (
                                <ListItem
                                    key={filter.id}
                                    button
                                    onClick={() => toggleFilter(filter)}
                                    sx={{
                                        borderRadius: 1,
                                        mb: 0.5,
                                        color: 'white',
                                        '&:hover': {
                                            bgcolor: 'rgba(255, 255, 255, 0.08)',
                                        }
                                    }}
                                >
                                    <ListItemText primary={filter.label} />
                                    {selectedFilters.has(filter.id) && (
                                        <ListItemIcon sx={{ minWidth: 'auto' }}>
                                            <CheckCircleIcon color="success" />
                                        </ListItemIcon>
                                    )}
                                </ListItem>
                            ))}
                        </List>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={() => setIsAddFilterOpen(false)}
                            variant="contained"
                        >
                            Apply Filters
                        </Button>
                    </DialogActions>
                </Dialog>
            </Paper>
        </Box>
    );
};

export default FilterSystem;