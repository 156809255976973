import React from 'react';
import { Box, Button } from '@mui/material';

const TimeRangeButtons = ({ 
  periodType, 
  timeRange, 
  setTimeRange, 
  size = 'medium' // New size prop with default
}) => {
  // Button size mappings
  const buttonSizes = {
    tiny: {
      buttonSize: 'small',
      minWidth: { xs: '50px', sm: '60px' },
      height: { xs: '24px', sm: '28px' },
      fontSize: { xs: '0.75rem', sm: '0.8rem' }
    },
    small: {
      buttonSize: 'small',
      minWidth: { xs: '60px', sm: '70px' },
      height: { xs: '28px', sm: '32px' },
      fontSize: { xs: '0.8rem', sm: '0.875rem' }
    },
    medium: {
      buttonSize: 'medium',
      minWidth: { xs: '70px', sm: '80px' },
      height: { xs: '32px', sm: '36px' },
      fontSize: { xs: '0.875rem', sm: '1rem' }
    },
    large: {
      buttonSize: 'large',
      minWidth: { xs: '80px', sm: '90px' },
      height: { xs: '36px', sm: '42px' },
      fontSize: { xs: '1rem', sm: '1.125rem' }
    },
    xlarge: {
      buttonSize: 'large',
      minWidth: { xs: '90px', sm: '100px' },
      height: { xs: '42px', sm: '48px' },
      fontSize: { xs: '1.125rem', sm: '1.25rem' }
    }
  };

  const currentSize = buttonSizes[size] || buttonSizes.medium;

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        gap: { xs: 1, sm: 2 },
        mb: 4,
        flexWrap: 'wrap',
        px: { xs: 1, sm: 2 }
      }}
    >
      {periodType === 'quarter' && (
        <Button
          size={currentSize.buttonSize}
          sx={{
            minWidth: currentSize.minWidth,
            height: currentSize.height,
            fontSize: currentSize.fontSize,
            px: { xs: 1, sm: 2 }
          }}
          variant={timeRange === '1Y' ? 'contained' : 'outlined'}
          onClick={() => setTimeRange('1Y')}
        >
          1Y
        </Button>
      )}
      <Button
        size={currentSize.buttonSize}
        sx={{
          minWidth: currentSize.minWidth,
          height: currentSize.height,
          fontSize: currentSize.fontSize,
          px: { xs: 1, sm: 2 }
        }}
        variant={timeRange === '3Y' ? 'contained' : 'outlined'}
        onClick={() => setTimeRange('3Y')}
      >
        3Y
      </Button>
      <Button
        size={currentSize.buttonSize}
        sx={{
          minWidth: currentSize.minWidth,
          height: currentSize.height,
          fontSize: currentSize.fontSize,
          px: { xs: 1, sm: 2 }
        }}
        variant={timeRange === '5Y' ? 'contained' : 'outlined'}
        onClick={() => setTimeRange('5Y')}
      >
        5Y
      </Button>
      <Button
        size={currentSize.buttonSize}
        sx={{
          minWidth: currentSize.minWidth,
          height: currentSize.height,
          fontSize: currentSize.fontSize,
          px: { xs: 1, sm: 2 }
        }}
        variant={timeRange === '10Y' ? 'contained' : 'outlined'}
        onClick={() => setTimeRange('10Y')}
      >
        10Y
      </Button>
      <Button
        size={currentSize.buttonSize}
        sx={{
          minWidth: currentSize.minWidth,
          height: currentSize.height,
          fontSize: currentSize.fontSize,
          px: { xs: 1, sm: 2 }
        }}
        variant={timeRange === 'MAX' ? 'contained' : 'outlined'}
        onClick={() => setTimeRange('MAX')}
      >
        MAX
      </Button>
    </Box>
  );
};

export default TimeRangeButtons;