import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, Grid, Typography, Link, useTheme } from '@mui/material';
import logo from '../images/rodeoLogo.png';
import SocialIcons from './SocialIcons';

const Footer = ({ isAuthenticated }) => {
    const theme = useTheme();
    const navigate = useNavigate();

    const handleScrollTo = (event, section) => {
        event.preventDefault();
        localStorage.setItem('scrollToSection', section);
        navigate('/', { state: { scrollTo: section } });
    };

    if (isAuthenticated) {
        return (
            <footer style={{ padding: '2rem 0', marginTop: '2rem', backgroundColor: theme.palette.background.default, textAlign: 'center' }}>
                <Container maxWidth="lg">
                    <Typography variant="body2" color="textSecondary">
                        <img src={logo} alt="Market Rodeo Logo" style={{ height: 'auto', width: '69px' }} />
                    </Typography>
                    <SocialIcons />
                    <Typography variant="body2" color="textSecondary">
                        © {new Date().getFullYear()} Market Rodeo. All rights reserved.
                    </Typography>
                </Container>
            </footer>
        );
    } else {
        return (
            <footer style={{ padding: '3rem 0', marginTop: '3rem', backgroundColor: theme.palette.background.default }}>
                <Container maxWidth="lg">
                    <Grid container spacing={3} justifyContent="space-between">
                        <Grid item xs={12} sm={6} md={3}>
                            <Typography variant="h6" color="textPrimary" gutterBottom>
                                Market Rodeo
                            </Typography>
                            <Typography variant="body2" color="textSecondary">
                                Market Rodeo provides market data and analytics tools for investors and traders.
                            </Typography>
                        </Grid>

                        <Grid item xs={12} sm={6} md={3}>
                            <Typography variant="h6" color="textPrimary" gutterBottom>
                                Company
                            </Typography>
                            <ul style={{ listStyleType: 'none', padding: 0 }}>
                                <li>
                                    <Link href="/terms-and-conditions" color="textSecondary">
                                        Terms of Service
                                    </Link>
                                </li>
                                <li>
                                    <Link href="/privacy-policy" color="textSecondary">
                                        Privacy Policy
                                    </Link>
                                </li>
                                <li>
                                    <Link href="mailto:support@marketrodeo.com" color="textSecondary">
                                        Contact
                                    </Link>
                                </li>
                            </ul>
                        </Grid>

                        <Grid item xs={12} sm={6} md={3}>
                            <Typography variant="h6" color="textPrimary" gutterBottom>
                                Products
                            </Typography>
                            <ul style={{ listStyleType: 'none', padding: 0 }}>
                                <li>
                                    <Link href="#" color="textSecondary" onClick={(event) => handleScrollTo(event, 'pricing')}>
                                        Pricing
                                    </Link>
                                </li>
                                <li>
                                    <Link href="#" color="textSecondary" onClick={(event) => handleScrollTo(event, 'features')}>
                                        Features
                                    </Link>
                                </li>
                            </ul>
                        </Grid>

                        <Grid item xs={12} sm={6} md={3}>
                            <Typography variant="h6" color="textPrimary" gutterBottom>
                                Resources
                            </Typography>
                            <ul style={{ listStyleType: 'none', padding: 0 }}>
                                <li>
                                    <Link href="/Blogs" color="textSecondary">
                                        Blog
                                    </Link>
                                </li>
                                <li>
                                    <Link href="#" color="textSecondary" onClick={(event) => handleScrollTo(event, 'faq')}>
                                        FAQ
                                    </Link>
                                </li>
                            </ul>
                        </Grid>

                        {/* Copyright Information */}
                        <Grid item xs={12} style={{ textAlign: 'center' }}>
                            <Typography variant="body2" color="textSecondary">
                                <img src={logo} alt="Market Rodeo Logo" style={{ height: 'auto', width: '69px' }} />
                            </Typography>
                            <SocialIcons />
                            <Typography variant="body2" color="textSecondary">
                                © {new Date().getFullYear()} Market Rodeo. All rights reserved.
                            </Typography>
                        </Grid>
                    </Grid>
                </Container>
            </footer>
        );
    }
};

export default Footer;
