import React, { useState } from 'react';
import { Typography, Button, Box } from '@mui/material';

const Description = ({ text }) => {
  const [showMore, setShowMore] = useState(false);
  const toggleShowMore = () => setShowMore(!showMore);

  // If text is null or undefined, return null to render nothing
  if (!text) {
    return null;
  }

  return (
    <Box>
      <Typography variant="body1" component="div">
        {showMore ? text : `${text.substring(0, 200)}...`}
      </Typography>
      <Button onClick={toggleShowMore}>
        {showMore ? 'Show less' : 'Show more'}
      </Button>
    </Box>
  );
};

export default Description;
