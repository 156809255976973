const staticPricingPlans = [
    {
      monthlyId: 'free',
      yearlyId: 'free',
      monthlyPrice: '$0/month',
      yearlyPrice: '$0/month',
      title: 'Free',
      description: 'Basic features with limited access',
      features: ['1 Portfolio', '1 Watchlist', 'Pre-made screeners', 'Basic filters', '5 Year Historical Data', 'Limited Access', '10 results limit', 'Delayed data'],
      btn: 'Get Started',
      id: 'free',
      isFree: true // Mark as free plan
    },
    {
      monthlyId: '',
      yearlyId: '',
      monthlyPrice: '$29/month',
      yearlyPrice: '$19/month',
      title: 'Basic',
      description: 'Extended features with more access',
      features: ['4 Portfolio', '4 Watchlist', 'Pre-made screeners', 'Basic filters', '10 Year Historical Data', 'Company data', 'Financial data', 'Limited Access', '100 results limit', 'Custom screeners', 'Market Calendars'],
      btn: 'Subscribe Now',
      isFree: false
    },
    {
      monthlyId: '',
      yearlyId: '',
      monthlyPrice: '$39/month',
      yearlyPrice: '$29/month',
      title: 'Premium',
      description: 'All features with full access',
      features: ['10 Portfolio', '10 Watchlist', 'Pre-made screeners', 'Basic & Advanced filters', '30+ Year Historical Data', 'Advanced company data', 'Advanced financial data', 'Custom screeners', 'Market Calendars', 'Prioritized feature requests', 'Export Data' ],
      btn: 'Join Now',
      isFree: false
    }
  ];
  
  export default staticPricingPlans;
  