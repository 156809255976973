import React, { useState, useEffect } from 'react';
import { Modal, Box, TextField, Button, Typography } from '@mui/material';

const RenameModal = ({ open, onClose, onConfirm, currentName, title }) => {
  const [newName, setNewName] = useState('');
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    if (open && currentName) {
      setNewName(currentName);
      setIsError(false);
    }
  }, [open, currentName]);

  const handleInputChange = (e) => {
    const value = e.target.value.trim();
    setNewName(value);
    setIsError(value === '');
  };

  const handleConfirm = () => {
    if (newName && newName !== currentName) {
      onConfirm(newName);
      onClose();
    }
  };

  const isConfirmDisabled = newName.trim() === '' || newName === currentName;

  return (
    <Modal open={open} onClose={onClose}>
      <Box sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 4,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}>
        <Typography variant="h6" component="h2" mb={2} textAlign="center">
          {title}
        </Typography>
        <TextField
          autoFocus
          margin="dense"
          label="New Name"
          type="text"
          fullWidth
          value={newName}
          onChange={handleInputChange}
          error={isError}
          helperText={isError ? "Name cannot be empty" : ""}
        />
        <Box sx={{ mt: 2, display: 'flex', justifyContent: 'center', width: '100%' }}>
          <Button onClick={onClose} sx={{ mr: 1 }}>Cancel</Button>
          <Button 
            onClick={handleConfirm} 
            variant="contained" 
            disabled={isConfirmDisabled}
          >
            Confirm
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default RenameModal;