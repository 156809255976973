import React from 'react';
import { Alert, Container, Typography } from '@mui/material';

const Cancel = () => {
  return (
    <Container sx={{ mt: 6, mb: 15 }}>
      <Typography variant="h2" align="center" gutterBottom>
        
      </Typography>
      <Alert severity="error">Something went wrong. Please try again.</Alert>
    </Container>
  );
};

export default Cancel;
