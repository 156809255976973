import React, { useEffect, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import axios from '../config/axiosConfig';
import { DataGrid } from '@mui/x-data-grid';
import { useAuth } from './AuthContext';
import { useTheme } from '@mui/material/styles';
import { CircularProgress, Container, Button, Box } from '@mui/material';
import TitleAndInfo from './TitleAndInfo';
import { exportToCsv } from '../utils/exportToCsv';
import FileDownloadIcon from '@mui/icons-material/FileDownload';

const MarketGainers = () => {
    const { user } = useAuth();
    const [marketGainers, setMarketGainers] = useState([]);
    const [loading, setLoading] = useState(true);
    const theme = useTheme();
    const baseUrl = window.location.origin;

    const titleTop = "Top Gainers: "
    const title = "Top Stocks of the Day";
    const info = "The 'Market Top Gainers' screener lists the stocks with the highest percentage gains in a single day.";

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get('/api/stocks/marketgainers');

                if (response.data && response.data.data) {
                    // Set market gainers data
                    setMarketGainers(response.data.data);
                    setLoading(false);
                } else {
                    console.error('Invalid response data:', response.data);
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, []);

    // Columns configuration
    const columns = [
        {
            field: 'symbol',
            headerName: 'Company',
            minWidth: 400,
            renderCell: (params) => (
                <div>
                    <RouterLink
                        to={`${baseUrl}/asset/${params.row.symbol}`}
                        style={{ color: theme.palette.primary.main, textDecoration: 'none' }}
                    >
                        {params.row.symbol}
                    </RouterLink>
                    <div style={{ fontSize: '0.9em', color: '#888' }}>{params.row.name}</div>
                </div>
            ),
            align: 'left', // Left align for Company column
            headerAlign: 'left', // Left align the header for Company column
        },
        {
            field: 'changesPercentage',
            headerName: '%Change',
            flex: 1,
            minWidth: 150,
            renderCell: (params) => (
                <div style={{ color: theme.palette.positive.main }}>
                    +{parseFloat(params.value).toFixed(2)}%
                </div>
            ),
            align: 'right', // Right align for %Change column
            headerAlign: 'right', // Right align the header for %Change column
        },
        {
            field: 'price',
            headerName: 'Price',
            flex: 1,
            minWidth: 150,
            align: 'right', // Right align for Price column
            headerAlign: 'right', // Right align the header for Price column
        },
        {
            field: 'change',
            headerName: 'Change',
            flex: 1,
            minWidth: 150,
            align: 'right', // Right align for Change column
            headerAlign: 'right', // Right align the header for Change column
        },
    ];

    const handleExport = () => {
        // Call the exportToCsv utility
        exportToCsv('market_gainers.csv', columns, marketGainers);
    };


    if (loading) {
        return (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                <CircularProgress />
            </div>
        )
    }

    return (
        <div className="table-container">
            <Container maxWidth="xl">

                <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    flexDirection={{ xs: 'column', sm: 'row' }} // Stacks vertically on xs, horizontally on sm and larger
                    mb={2}
                    gap={2} // Adds space between items when stacked
                >
                    <TitleAndInfo titleTop={titleTop} title={title} info={info} />

                    {user.plan.name === 'Premium' && (
                        <Button variant="contained" color="primary" startIcon={<FileDownloadIcon />} onClick={handleExport}>
                            Export
                        </Button>
                    )}
                </Box>

                <DataGrid
                    rows={marketGainers}
                    columns={columns}
                    getRowId={(row) => row.symbol}
                    disableColumnMenu
                    pagination
                    paginationPosition="middle"
                    initialState={{
                        pagination: { paginationModel: { pageSize: 10 } },
                    }}
                    pageSizeOptions={[5, 10, 25]}
                    sx={{
                        '& .MuiDataGrid-cell': {
                            fontSize: '1rem', // Adjust the font size here
                            fontWeight: 'bold',
                        },
                        '& .MuiDataGrid-columnHeaders': {
                            fontSize: '0.8rem', // Adjust the font size for headers
                        },
                    }}
                />
            </Container>
        </div>
    );
};

export default MarketGainers;
