import React, { useEffect, useState, useCallback } from 'react';
import { Typography, Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import currencySymbols from '../utils/currencySymbols';
import { useAuth } from './AuthContext';

const PriceDisplay = ({ initialData, exchange, onPriceUpdate }) => {
    const { user } = useAuth();
    const theme = useTheme();
    const [price, setPrice] = useState(initialData.price);
    const [priceFlash, setPriceFlash] = useState(false);
    const [priceIncreased, setPriceIncreased] = useState(null);
    const previousPrice = React.useRef(initialData.price);
    const wsRef = React.useRef(null);
    const mountedRef = React.useRef(true);  // Track if component is mounted

    const cleanupWebSocket = useCallback(() => {
        if (wsRef.current) {
            const ws = wsRef.current;
            // Ensure we cleanup the old connection first
            if (ws.readyState === WebSocket.OPEN) {
                const formattedSymbol = initialData.symbol.toLowerCase().replace('/', '');
                ws.send(JSON.stringify({
                    action: 'unsubscribe',
                    channel: exchange?.toUpperCase() === 'CRYPTO' ? 'crypto' : 'stock',
                    symbol: formattedSymbol
                }));
            }
            ws.close();
            wsRef.current = null;
        }
    }, [initialData.symbol, exchange]);

    useEffect(() => {
        // Set mounted flag
        mountedRef.current = true;

        let ws = null;
        let channel = "";

        const connectWebSocket = () => {
            // Cleanup any existing connection first
            cleanupWebSocket();

            // Don't establish new connections if component is unmounted
            if (!mountedRef.current) return;

            if (user?.plan?.name === 'Free') {
                return;
            }

            switch (exchange?.toUpperCase()) {
                case 'CRYPTO':
                    ws = new WebSocket(getWebSocketUrl());
                    channel = "crypto";
                    break;
                case 'NASDAQ':
                case 'AMEX':
                case 'NYSE':
                    ws = new WebSocket(getWebSocketUrl());
                    channel = "stock";
                    break;
                default:
                    return;
            }

            wsRef.current = ws;

            ws.onopen = () => {
                if (!mountedRef.current) {
                    ws.close();
                    return;
                }
                
                const formattedSymbol = initialData.symbol.toLowerCase().replace('/', '');
                const subscribeMessage = {
                    action: 'subscribe',
                    channel: channel,
                    symbol: formattedSymbol
                };
                ws.send(JSON.stringify(subscribeMessage));
            };

            ws.onmessage = (event) => {
                if (!mountedRef.current) return;
                
                try {
                    const data = JSON.parse(event.data);
                    const formattedSymbol = initialData.symbol.toLowerCase().replace('/', '');

                    if (data.source === channel && data.data) {
                        if (data.data.symbol?.toLowerCase() === formattedSymbol && data.data.price) {
                            updatePrice(data.data.price);
                        }
                    }
                } catch (error) {
                    console.error('Error processing message:', error);
                }
            };

            ws.onerror = (error) => {
                console.error('WebSocket error:', error);
            };

            ws.onclose = () => {
                if (mountedRef.current) {
                    setTimeout(connectWebSocket, 5000);
                }
            };
        };

        const getWebSocketUrl = () => {
            const protocol = window.location.protocol === 'https:' ? 'wss:' : 'ws:';
            const host = process.env.NODE_ENV === 'production'
                ? 'api.marketrodeo.com'
                : 'localhost:5000';
            return `${protocol}//${host}`;
        };

        const updatePrice = (newPrice) => {
            if (previousPrice.current !== newPrice) {
                setPrice(newPrice);
                setPriceFlash(true);
                setPriceIncreased(newPrice > previousPrice.current);
                previousPrice.current = newPrice;
                if (onPriceUpdate) {
                    onPriceUpdate(newPrice);
                }
                setTimeout(() => setPriceFlash(false), 1000);
            }
        };

        if (exchange && initialData.symbol) {
            connectWebSocket();
        }

        // Cleanup function
        return () => {
            mountedRef.current = false;  // Mark as unmounted
            cleanupWebSocket();
        };
    }, [exchange, initialData.symbol, user?.plan?.name, cleanupWebSocket, onPriceUpdate]);

    useEffect(() => {
        setPrice(initialData.price);
    }, [initialData.price]);

    // Function to format prices
    const formatPrice = (price) =>
        price.toString().includes('e') ? price.toFixed(12) : price;

    // Fallback to initialData.price if no WebSocket data is received
    const displayPrice = formatPrice(price || initialData.price);

    return (
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <Typography variant="h5">
                {`${currencySymbols[initialData.currency]}${displayPrice}`}
            </Typography>
            {priceFlash && (
                <Box
                    sx={{
                        width: 8,
                        height: 8,
                        borderRadius: '50%',
                        backgroundColor: priceIncreased ?
                            theme.palette.positive.main :
                            theme.palette.negative.main,
                        animation: 'fadeOut 1s ease-out',
                        '@keyframes fadeOut': {
                            '0%': {
                                opacity: 1,
                                transform: 'scale(1.5)'
                            },
                            '100%': {
                                opacity: 0,
                                transform: 'scale(1)'
                            }
                        }
                    }}
                />
            )}
        </Box>
    );
};

export default PriceDisplay;