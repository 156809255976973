import React, { useState, useEffect } from 'react';
import axios from '../config/axiosConfig';
import { Box, CircularProgress, Typography, Container } from '@mui/material';
import VerticalCarousel from './VerticalCarousel'; // Import the new component

const StockNews = () => {
    const [news, setNews] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        let mounted = true;

        const fetchNews = async () => {
            try {
                setLoading(true);
                const response = await axios.get('/api/news/newest');
                if (mounted) {
                    setNews(response.data.data);
                }
            } catch (err) {
                if (mounted) {
                    setError('Error fetching news');
                }
            } finally {
                if (mounted) {
                    setLoading(false);
                }
            }
        };

        fetchNews();

        // Refresh news every 5 minutes
        const intervalId = setInterval(fetchNews, 300000);

        // Cleanup
        return () => {
            mounted = false;
            clearInterval(intervalId);
        };
    }, []);

    if (loading) {
        return (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '300px' }}>
                <CircularProgress />
            </Box>
        );
    }

    if (error) {
        return (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '300px' }}>
                <Typography variant="h6" color="error">
                    {error}
                </Typography>
            </Box>
        );
    }

    return (
        <Container maxWidth="lg">
            <VerticalCarousel
                items={news.map(article => ({
                    title: article.title,
                    text: article.text,
                    url: article.url,
                    symbol: article.symbol,
                }))}
                sx={{ mt: 2 }}
            />
        </Container>
    );
};

export default StockNews;