import React, { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { useAuth } from './AuthContext';
import axios from '../config/axiosConfig';
import {
  Container,
  CircularProgress,
  Box,
  Typography
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import TitleAndInfo from './TitleAndInfo';
import { formatNumber } from '../utils/numberUtils';
import { exportToCsv } from '../utils/exportToCsv';
import CryptoAvgScreenerFilterSystem from './CryptoAvgScreenerFilterSystem';

const CryptoAvgScreener = () => {
  const { user } = useAuth();
  const theme = useTheme();
  const [loading, setLoading] = useState(false);
  const [stocks, setStocks] = useState([]);
  const baseUrl = window.location.origin;

  const titleTop = "Average Price Screener";
  const title = "";
  const info = "Compare current Crypto prices to their 50-day or 200-day moving averages. Filter crypto based on how their current price compares to these averages, along with additional criteria like price range and market cap.";

  const handleSubmit = async (filterData) => {
    setStocks([]);
    setLoading(true);

    try {
      const response = await axios.post('/api/cryptoavgscreener', filterData);
      setStocks(response.data.data);
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleClear = () => {
    setStocks([]);
  };

  const handleExport = () => {
    exportToCsv('crypto_avg_screener.csv', columns, stocks);
  };

  const columns = [
    {
      field: 'symbol',
      headerName: 'Company',
      minWidth: 250,
      flex: 1,
      renderCell: (params) => (
        <div>
          <RouterLink
            to={`${baseUrl}/asset/${params.row.symbol}`}
            style={{ color: theme.palette.primary.main, textDecoration: 'none', fontWeight: 'bold', }}
          >
            {params.row.symbol}
          </RouterLink>
          <div style={{ fontSize: '0.9em', color: '#888', fontWeight: 'bold', }}>{params.row.name}</div>
        </div>
      ),
      align: 'left',
      headerAlign: 'left',
    },
    { field: 'price', headerName: 'Price', minWidth: 120, flex: 1, valueFormatter: (params) => formatNumber(params.value) },
    { field: 'priceAvg50', headerName: '50-Day Avg', minWidth: 150, flex: 1, valueFormatter: (params) => formatNumber(params.value) },
    { field: 'priceAvg200', headerName: '200-Day Avg', minWidth: 150, flex: 1, valueFormatter: (params) => formatNumber(params.value) },
    { field: 'marketCap', headerName: 'Market Cap', minWidth: 150, flex: 1, valueFormatter: (params) => formatNumber(params.value) },
  ];

  return (
    <Container maxWidth="xl" sx={{ mt: 4 }}>
      <TitleAndInfo titleTop={titleTop} title={title} info={info} />
      
      <CryptoAvgScreenerFilterSystem
        onSearch={handleSubmit}
        onClear={handleClear}
        onExport={handleExport}
        showExport={user.plan.name === 'Premium' && stocks.length > 0}
      />

      {loading && (
        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
          <CircularProgress />
        </Box>
      )}

      {!loading && stocks.length === 0 && (
        <Box sx={{ mt: 4, textAlign: 'center' }}>
          <Typography>No results found.</Typography>
        </Box>
      )}

      {stocks.length > 0 && (
        <Container maxWidth="xl" sx={{ mt: 4 }}>
          <DataGrid
            rows={stocks}
            columns={columns}
            getRowId={(row) => row.symbol}
            disableColumnMenu
            pagination
            paginationPosition="middle"
            initialState={{
              pagination: { paginationModel: { pageSize: 10 } },
              sorting: { sortModel: [{ field: 'marketCap', sort: 'desc' }] },
            }}
            pageSizeOptions={[5, 10, 25]}
            sx={{
              '& .MuiDataGrid-cell': {
                fontSize: '1rem',
              },
              '& .MuiDataGrid-columnHeaders': {
                fontSize: '0.8rem',
              },
            }}
          />
        </Container>
      )}
    </Container>
  );
};

export default CryptoAvgScreener;