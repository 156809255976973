import React, { useState, useEffect } from 'react';
import {
    Box,
    Paper,
    Typography,
    useTheme,
    CircularProgress
} from '@mui/material';
import axios from '../config/axiosConfig';

const IndexCardComponent = ({ symbol, isMobile }) => {
    const theme = useTheme();
    const [indexData, setIndexData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchIndexData = async () => {
            try {
                setLoading(true);
                const response = await axios.get(`/api/indexfullquote/${symbol}`);
                if (response.data && response.data.data && response.data.data[0]) {
                    setIndexData(response.data.data[0]);
                }
            } catch (err) {
                console.error('Error fetching index data:', err);
                setError('Failed to load index data');
            } finally {
                setLoading(false);
            }
        };

        if (symbol) {
            fetchIndexData();
        }
    }, [symbol]);

    if (loading) {
        return (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: isMobile ? 80 : 200 }}>
                <CircularProgress size={isMobile ? 20 : 40} />
            </Box>
        );
    }

    if (error || !indexData || typeof indexData.price === 'undefined') {
        return (
            <Paper
                sx={{
                    bgcolor: theme.palette.mode === 'dark' ? 'background.default' : 'background.paper',
                    p: isMobile ? 1 : 2,
                    height: '100%',
                    borderRadius: 1,
                }}
            >
                <Box sx={{ textAlign: 'center' }}>
                    <Typography variant={isMobile ? 'caption' : 'body1'} color="error">Error</Typography>
                </Box>
            </Paper>
        );
    }

    const formatNumber = (number) => {
        if (typeof number !== 'number') return '0.00';
        return number.toLocaleString('en-US', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        });
    };

    if (isMobile) {
        return (
            <Paper
                sx={{
                    bgcolor: theme.palette.mode === 'dark' ? 'background.default' : 'background.paper',
                    p: 1,
                    height: '100%',
                    borderRadius: 1,
                }}
            >
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 0.5
                }}>
                    <Typography
                        variant="caption"
                        sx={{
                            fontWeight: 'bold',
                            textAlign: 'center',
                        }}
                    >
                        {indexData.name.split(' ')[0]}
                    </Typography>

                    <Typography
                        variant="body2"
                        sx={{
                            fontWeight: 'bold',
                            textAlign: 'center'
                        }}
                    >
                        {formatNumber(indexData.price)}
                    </Typography>

                    <Typography
                        variant="caption"
                        sx={{
                            fontWeight: 'bold',
                            textAlign: 'center',
                            color: (indexData.changesPercentage || 0) >= 0 ? 'positive.main' : 'negative.main'
                        }}
                    >
                        {(indexData.changesPercentage || 0) >= 0 ? '+' : ''}{formatNumber(indexData.changesPercentage)}%
                    </Typography>
                </Box>
            </Paper>
        );
    }

    return (
        <Paper
            sx={{
                bgcolor: theme.palette.mode === 'dark' ? 'background.default' : 'background.paper',
                p: 2,
                height: '100%',
                borderRadius: 2,
            }}
        >
            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: 1
            }}>
                <Typography
                    variant="h6"
                    sx={{
                        fontWeight: 'bold',
                        textAlign: 'center',
                        mb: 1
                    }}
                >
                    {indexData.name}
                </Typography>

                <Typography
                    variant="h4"
                    sx={{
                        fontWeight: 'bold',
                        textAlign: 'center'
                    }}
                >
                    {formatNumber(indexData.price)}
                </Typography>

                <Box sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    gap: 2,
                    mt: 1
                }}>
                    <Typography
                        variant="body1"
                        sx={{
                            fontWeight: 'bold',
                            color: (indexData.change || 0) >= 0 ? 'positive.main' : 'negative.main'
                        }}
                    >
                        {(indexData.change || 0) >= 0 ? '+' : ''}{formatNumber(indexData.change)}
                    </Typography>
                    <Typography
                        variant="body1"
                        sx={{
                            fontWeight: 'bold',
                            color: (indexData.changesPercentage || 0) >= 0 ? 'positive.main' : 'negative.main'
                        }}
                    >
                        ({(indexData.changesPercentage || 0) >= 0 ? '+' : ''}{formatNumber(indexData.changesPercentage)}%)
                    </Typography>
                </Box>
            </Box>
        </Paper>
    );
};

export default IndexCardComponent;