// src/utils/colorUtils.js
import { useTheme } from '@mui/material/styles';

export const useChangeColor = () => {
  const theme = useTheme();
  
  return (value) => {
    // Return default text color if value is null/undefined
    if (value == null) return theme.palette.text.primary;
    
    if (value > 0) return theme.palette.positive.main;
    if (value < 0) return theme.palette.negative.main;
    return theme.palette.text.primary;
  };
};

// Optional: If you need a version that doesn't require the theme hook
export const getChangeColorClass = (value) => {
  if (value == null) return 'neutral';
  if (value > 0) return 'positive';
  if (value < 0) return 'negative';
  return 'neutral';
};