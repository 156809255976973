import { createTheme } from '@mui/material/styles';

// Custom palette
const stockPricePalette = {
  positive: {
    main: '#0eb35b', // green for positive stock price
  },
  negative: {
    main: '#e62333', // red for negative stock price
  },
  lightGray: {
    main: '#d3d3d3', // light gray
  },
};

// Light theme
const lightTheme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: '#1976d2', // blue
    },
    secondary: {
      main: '#dae3e5', // orange
    },
    third: {
      main: '#757575', // Boulder - gray
    },
    error: {
      main: '#f44336', // red
    },
    background: {
      default: '#f5f5f5', // light background
    },
    text: {
      primary: '#000000', // black text
    },
    ...stockPricePalette,
  },
  typography: {
    fontFamily: 'Roboto, Arial, sans-serif',
    fontSize: 12,
    button: {
      fontWeight: 600,
    },
  },
  spacing: 8, // default spacing unit
  components: {
    MuiLink: {
      styleOverrides: {
        root: {
          color: 'inherit',
          textDecoration: 'none',
          '&:hover': {
            color: '#1976d2', // Change to your desired hover color
            textDecoration: 'underline',
          },
        },
      },
    }
  },
  hero: {
    color: '#000000',
    textAlign: 'center',
  },
  ctaButton: {
    marginTop: 4,
  },
});

// Dark theme
const darkTheme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#1976d2', // blue
    },
    secondary: {
      main: '#dae3e5', // orange
    },
    third: {
      main: '#757575', // Boulder - gray
    },
    error: {
      main: '#f44336', // red
    },
    background: {
      default: '#303030', // dark background or change to #000000
    },
    text: {
      primary: '#ffffff', // white text
    },
    ...stockPricePalette,
  },
  typography: {
    fontFamily: 'Roboto, Arial, sans-serif',
    fontSize: 12,
    button: {
      fontWeight: 600,
    },
  },
  spacing: 8, // default spacing unit
  components: {
    MuiLink: {
      styleOverrides: {
        root: {
          color: 'inherit',
          textDecoration: 'none',
          '&:hover': {
            color: '#1976d2', // Change to your desired hover color
            textDecoration: 'underline',
          },
        },
      },
    }
  },
  hero: {
    color: '#fff',
    textAlign: 'center',
  },
  ctaButton: {
    marginTop: 4,
  },
});

export { lightTheme, darkTheme };
