import React, { useEffect, useState } from 'react';
import axios from '../config/axiosConfig';
import { useAuth } from './AuthContext';
import { DataGrid } from '@mui/x-data-grid';
import {  TextField, CircularProgress, Button, Modal, Box, FormControl, InputLabel, MenuItem, Select, Snackbar, Container  } from '@mui/material';
import { MdAddBox } from "react-icons/md";

const DailyAggregated = () => {
    const { user } = useAuth();
    const [assetsData, setAssetsData] = useState([]);
    const [watchlistData, setWatchlistData] = useState(null);
    const [selectedAsset, setSelectedAsset] = useState(null);
    const [showAddToWatchlistModal, setShowAddToWatchlistModal] = useState(false);
    const [showSnackbar, setShowSnackbar] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [loading, setLoading] = useState(true);
    const [searchValue, setSearchValue] = useState('');
    const [formattedDate, setFormattedDate] = useState('');


    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get('/api/dailyaggregateddb');                
                // Check if response data is an object and has the expected structure
                if (response.data && response.data.date && response.data.data) {
                    // Convert the response data into an array of objects
                    const dataArray = response.data.data.map(item => ({
                        ...item,
                        date: response.data.date
                    }));
    
                    // Generate unique IDs for each row
                    const dataWithIds = dataArray.map((item, index) => ({
                        ...item,
                        id: index.toString() // Use the index as the id
                    }));
    
                    // Format the date for display
                    const date = new Date(dataWithIds[0].date);
                    const options = { month: '2-digit', day: '2-digit', year: '2-digit' };
                    const formattedDate = date.toLocaleDateString('en-US', options);
                    setFormattedDate(formattedDate);
    
                    setAssetsData(dataWithIds);
                    setLoading(false);
                } else {
                    console.error('Invalid response data:', response.data);
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
    
        fetchData();
    }, []);

    useEffect(() => {
        if (user) { // Check if user is defined
          const fetchWatchlistData = async () => {
            try {
              const response = await axios.get(`/api/watchlists/${user._id}`);
              setWatchlistData(response.data);
            } catch (error) {
              console.error('Error fetching watchlist data:', error);
            }
          };
    
          fetchWatchlistData();
        }
      }, [user]);
    
    const handleAddToWatchlist = (asset) => {
        setSelectedAsset(asset);
        setShowAddToWatchlistModal(true);
      };

      const handleAddToSelectedWatchlist = async (watchlistId) => {
        try {
          setSelectedAsset(prevState => ({
            ...prevState,
            watchlistId
          }));
      
          await axios.post('/api/watchlist/addtowatchlistfromdaily', {
            watchlistId,
            asset: selectedAsset
          });
          setShowSnackbar(true);
          setSnackbarMessage(`${selectedAsset.T} added to watchlist!`);
          setShowAddToWatchlistModal(false);
        } catch (error) {
          console.error('Error adding asset to watchlist:', error);
        }
      };

    const handleSearchChange = (event) => {
        setSearchValue(event.target.value);
      };

      const filterAssets = () => {
        if (assetsData) {
          return assetsData.filter(asset =>
            asset.T.toLowerCase().includes(searchValue.toLowerCase())
          );
        }
        return [];
      };

    if (loading) {
        return (
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
            <CircularProgress />
          </div>
        )
    }

    return (
        <div className="table-container">
        <Container maxWidth="xl">
            <h1>Daily Aggregated {formattedDate} </h1>
            <TextField
            label="Search by Symbol"
            variant="outlined"
            value={searchValue}
            onChange={handleSearchChange}
            style={{ marginBottom: '20px' }}
            />
            <DataGrid
                rows={filterAssets()}
                columns={[
                    { field: 'T', headerName: 'Symbol', flex: 1 },
                    { field: 'change', headerName: 'Change', flex: 1 },
                    { field: 'changeprecentage', headerName: 'Chg%', flex: 1 },
                    { 
                      field: 'v', 
                      headerName: 'Volume', 
                      flex: 1,
                      renderCell: (params) => (params.value !== undefined ? params.value.toLocaleString() : ''),
                    },
                    // { field: 'vw', headerName: 'Volume Weighted Average Price', flex: 1 },
                    { field: 'o', headerName: 'Open Price', flex: 1 },
                    { field: 'c', headerName: 'Close Price', flex: 1 },
                    { field: 'h', headerName: 'High Price', flex: 1 },
                    { field: 'l', headerName: 'Low Price', flex: 1 },
                    { 
                      field: 'n', 
                      headerName: 'Trades', 
                      flex: 1,
                      renderCell: (params) => (params.value !== undefined ? params.value.toLocaleString() : ''),
                    },
                    user && {
                        field: 'addToWatchlist',
                        headerName: '',
                        renderCell: (params) => (
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={() => handleAddToWatchlist(params.row)}
                          >
                            <MdAddBox />
                          </Button>
                        ),
                      },
                ].filter(Boolean)}
                getRowId={(row) => row.id}
                disableColumnMenu
                pagination
                paginationPosition="middle"
                pageSize={5}
                rowsPerPageOptions={[5, 10, 25]}
            />

            <Snackbar
            open={showSnackbar}
            autoHideDuration={3000}
            onClose={() => setShowSnackbar(false)}
            message={snackbarMessage}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          />

        <Modal open={showAddToWatchlistModal} onClose={() => setShowAddToWatchlistModal(false)}>
          <Box sx={{ 
            position: 'absolute', 
            top: '50%', 
            left: '50%', 
            transform: 'translate(-50%, -50%)', 
            bgcolor: 'background.paper', // Use theme color for background
            p: 4, // Add padding for better visibility
            borderRadius: 8, // Add rounded corners for a more modern look
            boxShadow: 4, // Add box shadow for depth
          }}>
            <h2>Add to Watchlist</h2>
            <FormControl fullWidth>
              <InputLabel> </InputLabel>
              <Select
                value=""
                onChange={(e) => handleAddToSelectedWatchlist(e.target.value)}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {watchlistData && watchlistData.map(watchlist => (
                  <MenuItem key={watchlist._id} value={watchlist._id}>{watchlist.name}</MenuItem>
                ))}
              </Select>
            </FormControl>
            <Button onClick={() => setShowAddToWatchlistModal(false)} >
              Close
            </Button>
          </Box>
        </Modal>
        </Container>
        </div>
    );
};

export default DailyAggregated;
