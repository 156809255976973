import React from 'react';
import { Box, Typography, Link } from '@mui/material';
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

import { Autoplay } from 'swiper/modules';

const VerticalCarousel = ({ items }) => {
    return (
        <Box
            sx={{
                height: '300px',
                overflow: 'hidden',
                '& .swiper': {
                    width: '100%',
                    height: '100%',
                },
                '& .swiper-wrapper': {
                    display: 'flex',
                    flexDirection: 'column',
                },
                '& .swiper-slide': {
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'left',
                    justifyContent: 'left',
                    height: '100px',
                    boxSizing: 'border-box',
                    padding: '0 16px',
                },
            }}
        >
            <Swiper
                direction="vertical"
                loop={true}
                slidesPerView={3}
                spaceBetween={10}
                autoplay={{
                    delay: 0, // Removes the delay for continuous movement
                    disableOnInteraction: false,
                    pauseOnMouseEnter: true,
                }}
                speed={6000} // Higher speed value for slower, smooth scrolling
                cssMode={false} // Set to false for smooth transitions
                allowTouchMove={false}
                modules={[Autoplay]}
            >

                {items.map((item, index) => (
                    <SwiperSlide key={`${item.symbol}-${index}`}>
                        <Link
                            href={item.url}
                            target="_blank"
                            rel="noopener noreferrer"
                            sx={{
                                textDecoration: 'none',
                                display: 'block',
                                width: '100%',
                                textAlign: 'left',
                            }}
                        >
                            <Typography
                                variant="h6"
                                component="div"
                                sx={{
                                    fontWeight: 'bold',
                                    color: 'primary.main',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    whiteSpace: 'nowrap',
                                }}
                            >
                                {item.symbol}: {item.title}
                            </Typography>
                        </Link>
                        <Typography
                            variant="body2"
                            color="text.secondary"
                            sx={{
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                display: '-webkit-box',
                                WebkitLineClamp: 2,
                                WebkitBoxOrient: 'vertical',
                                textAlign: 'left',
                            }}
                        >
                            {item.text}
                        </Typography>
                    </SwiperSlide>
                ))}
            </Swiper>
        </Box>
    );
};

export default VerticalCarousel;
