import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from './AuthContext';
import {
  Box,
  Paper,
  Typography,
  Button,
  Container
} from '@mui/material';
import { Lock as LockIcon } from '@mui/icons-material';

const withPlanAccess = (WrappedComponent, allowedPlans) => {
  const ComponentWithAccessControl = (props) => {
    const { user } = useAuth();
    const navigate = useNavigate();

    // Check if the user's plan is in the allowed plans
    if (!user || !allowedPlans.includes(user.plan.name)) {
      return (
        <Container maxWidth="sm">
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              minHeight: 'calc(100vh - 200px)'
            }}
          >
            <Paper
              elevation={3}
              sx={{
                p: 4,
                textAlign: 'center',
                borderRadius: 2,
                width: '100%'
              }}
            >
              <LockIcon 
                sx={{ 
                  fontSize: 48,
                  color: 'primary.main',
                  mb: 2
                }}
              />
              <Typography 
                variant="h4" 
                component="h1" 
                gutterBottom
                fontWeight="bold"
                color="primary"
              >
                Access Denied
              </Typography>
              <Typography 
                variant="body1" 
                color="text.secondary"
                sx={{ mb: 4 }}
              >
                Your current plan does not allow access to this feature.
              </Typography>
              <Button
                variant="contained"
                size="large"
                onClick={() => navigate('/upgrade')}
                sx={{
                  height: 48,
                  textTransform: 'none',
                  fontWeight: 600,
                  px: 4
                }}
              >
                Upgrade Plan
              </Button>
            </Paper>
          </Box>
        </Container>
      );
    }

    // Render the wrapped component if access is allowed
    return <WrappedComponent {...props} />;
  };

  return ComponentWithAccessControl;
};

export default withPlanAccess;