import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import {
  Box,
  Typography,
  LinearProgress,
  useTheme
} from '@mui/material';
import axios from '../config/axiosConfig';

const AnalystRatingCard = () => {
  const { symbol } = useParams();
  const theme = useTheme(); // Get the current theme

  const [consensusData, setConsensusData] = useState(null);
  const [buyCount, setBuyCount] = useState(0);
  const [holdCount, setHoldCount] = useState(0);
  const [sellCount, setSellCount] = useState(0);
  const [priceTarget, setPriceTarget] = useState("n/a");
  const [, setNumOfAnalyst] = useState(0);
  const [, setChangesPercentage] = useState(0);

  useEffect(() => {
    axios.get(`/api/upgradesDowngradesConsensus/${symbol}`)
      .then(response => {
        const data = response.data.data;

        if (!data) {
          console.warn("No data received from the API");
          return;
        }

        setConsensusData(data);
        const totalAnalysts = data.buy + data.hold + data.sell;
        setBuyCount((data.buy / totalAnalysts) * 100);
        setHoldCount((data.hold / totalAnalysts) * 100);
        setSellCount((data.sell / totalAnalysts) * 100);
        setNumOfAnalyst(totalAnalysts);
        setPriceTarget(data.consensus === 'Buy' ? 150 : 'n/a');
        const lastPrice = 145;
        setChangesPercentage(((priceTarget / lastPrice - 1) * 100).toFixed(2));
      })
      .catch(error => {
        console.error("Error fetching the consensus data", error);
      });
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [symbol]);

  if (!consensusData) {
    return <Typography variant="body1" color="text.primary"></Typography>;
  }

  return (
    <Box>
      <Box sx={{ mt: 2 }}>
        <Typography
          variant="body1"
          color="text.primary"
          sx={{ mb: 1 }}
        >
          Buy: {buyCount.toFixed(2)}%
        </Typography>
        <LinearProgress
          variant="determinate"
          value={buyCount}
          sx={{
            bgcolor: theme.palette.mode === 'dark' ? '#3B3D3F' : theme.palette.grey[200],
            '& .MuiLinearProgress-bar': {
              backgroundColor: theme.palette.positive.main
            },
            height: 8,
            borderRadius: 4,
          }}
        />

        <Typography
          variant="body1"
          color="text.primary"
          sx={{ mt: 2, mb: 1 }}
        >
          Hold: {holdCount.toFixed(2)}%
        </Typography>
        <LinearProgress
          variant="determinate"
          value={holdCount}
          sx={{
            bgcolor: theme.palette.mode === 'dark' ? '#3B3D3F' : theme.palette.grey[200],
            '& .MuiLinearProgress-bar': {
              backgroundColor: theme.palette.mode === 'dark' ? '#fff' : theme.palette.grey[500]
            },
            height: 8,
            borderRadius: 4,
          }}
        />

        <Typography
          variant="body1"
          color="text.primary"
          sx={{ mt: 2, mb: 1 }}
        >
          Sell: {sellCount.toFixed(2)}%
        </Typography>
        <LinearProgress
          variant="determinate"
          value={sellCount}
          sx={{
            bgcolor: theme.palette.mode === 'dark' ? '#3B3D3F' : theme.palette.grey[200],
            '& .MuiLinearProgress-bar': {
              backgroundColor: theme.palette.negative.main
            },
            height: 8,
            borderRadius: 4,
          }}
        />
      </Box>
    </Box>
  );
};

export default AnalystRatingCard;