import React, { useState, useEffect } from 'react';
import { useParams, Link as RouterLink } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import {
    Box,
    Typography,
    Container,
    CircularProgress,
    Button,
    Snackbar
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { AddCircle as AddCircleIcon } from '@mui/icons-material';
import axios from '../config/axiosConfig';
import { formatNumber } from '../utils/numberUtils';
import AddToWatchlistModal from './AddToWatchlistModal';
import UpgradePopup from './UpgradePopup';
import TitleAndInfo from './TitleAndInfo';

const MarketCapList = ({ user }) => {
    const theme = useTheme();
    const { capSize } = useParams();
    const [stocks, setStocks] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    // const [watchlistData, setWatchlistData] = useState(null);
    const [selectedAsset, setSelectedAsset] = useState(null);
    const [showAddToWatchlistModal, setShowAddToWatchlistModal] = useState(false);
    const [showSnackbar, setShowSnackbar] = useState(false);
    const [snackbarMessage,] = useState('');
    const [showUpgradePopup, setShowUpgradePopup] = useState(false);

    const baseUrl = window.location.origin;

    const marketCapInfo = {
        'mega-cap': 'Market capitalization of $200 billion or more',
        'large-cap': 'Market capitalization between $10 billion and $200 billion',
        'mid-cap': 'Market capitalization between $2 billion and $10 billion',
        'small-cap': 'Market capitalization between $300 million and $2 billion',
        'micro-cap': 'Market capitalization between $50 million and $300 million',
        'nano-cap': 'Market capitalization below $50 million'
    };

    const titleTop = `${capSize.charAt(0).toUpperCase() + capSize.slice(1)} Stocks`;
    // const title = titleTop;
    const info = marketCapInfo[capSize] || 'Information not available';

    const columns = [
        {
            field: 'symbol',
            headerName: 'Company',
            minWidth: 250,
            renderCell: (params) => (
                <div>
                    <RouterLink
                        to={`${baseUrl}/asset/${params.row.symbol}`}
                        style={{
                            color: theme.palette.primary.main,
                            textDecoration: 'none',
                            fontSize: '1rem',
                            fontWeight: 'bold',
                        }}
                    >
                        {params.row.symbol}
                    </RouterLink>
                    <div
                        style={{ fontSize: '0.9em', color: '#888', fontWeight: 'bold' }}
                    >
                        {params.row.name || 'N/A'}
                    </div>
                </div>
            ),
            align: 'left',
            headerAlign: 'left',
        },
        {
            field: 'price',
            headerName: 'Price',
            flex: 1,
            minWidth: 150,
            valueFormatter: (params) => formatNumber(params.value),
        },
        {
            field: 'open',
            headerName: 'Open',
            flex: 1,
            minWidth: 120,
            valueFormatter: (params) => formatNumber(params.value),
        },
        {
            field: 'dayHigh',
            headerName: 'High',
            flex: 1,
            minWidth: 120,
            valueFormatter: (params) => formatNumber(params.value),
        },
        {
            field: 'dayLow',
            headerName: 'Low',
            flex: 1,
            minWidth: 120,
            valueFormatter: (params) => formatNumber(params.value),
        },
        {
            field: 'changesPercentage',
            headerName: 'Change %',
            flex: 1,
            minWidth: 150,
            valueFormatter: (params) => `${formatNumber(params.value)}%`,
        },
        {
            field: 'change',
            headerName: 'Change',
            flex: 1,
            minWidth: 150,
            valueFormatter: (params) => formatNumber(params.value),
        },
        {
            field: 'volume',
            headerName: 'Volume',
            flex: 1,
            minWidth: 150,
            valueFormatter: (params) => formatNumber(params.value),
        },
        {
            field: 'marketCap',
            headerName: 'Market Cap',
            minWidth: 150,
            flex: 1,
            valueFormatter: (params) => formatNumber(params.value),
        },
        user && {
            field: 'addToWatchlist',
            headerName: '',
            width: 100,
            sortable: false,
            renderCell: (params) => (
              <Button
                variant="contained"
                color="primary"
                size="small"
                onClick={(e) => {
                  e.stopPropagation();
                  handleAddToWatchlist(params.row);
                }}
                sx={{ minWidth: 'unset', p: 1 }}
              >
                <AddCircleIcon />
              </Button>
            ),
          },
        ].filter(Boolean);

    useEffect(() => {
        const fetchStocks = async () => {
            setLoading(true);
            setError(null);
            try {
                const response = await axios.get(`/api/market-cap/${capSize}`);
                setStocks(response.data);
            } catch (error) {
                console.error('Error fetching stocks:', error);
                if (error.response) {
                    console.error('Error data:', error.response.data);
                    console.error('Error status:', error.response.status);
                    console.error('Error headers:', error.response.headers);
                } else if (error.request) {
                    console.error('Error request:', error.request);
                } else {
                    console.error('Error message:', error.message);
                }
                setError('Failed to fetch stocks. Please try again later.');
            } finally {
                setLoading(false);
            }
        };

        fetchStocks();
    }, [capSize]);

    const handleAddToWatchlist = (asset) => {
        setSelectedAsset(asset);
        setShowAddToWatchlistModal(true);
    };

    // Updated to not close the modal on success
    const handleWatchlistSuccess = (message) => {
        // Optional: Handle success message if needed
        // console.log(message);
    };

    // Separate handler for closing the modal
    const handleCloseModal = () => {
        setShowAddToWatchlistModal(false);
        setSelectedAsset(null); // Clean up selected asset when modal closes
    };


    if (loading) {
        return (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                <CircularProgress />
            </Box>
        );
    }

    if (error) {
        return (
            <Box sx={{ textAlign: 'center', mt: 4 }}>
                <Typography color="error">{error}</Typography>
            </Box>
        );
    }

    return (
        <Box sx={{ bgcolor: theme.palette.background.default, color: theme.palette.text.primary, py: 4 }}>
            <Container maxWidth="xl">
                <TitleAndInfo titleTop={titleTop} info={info} />
                <DataGrid
                    rows={stocks}
                    columns={columns}
                    getRowId={(row) => row.symbol}
                    disableColumnMenu
                    paginationPosition="middle"
                    initialState={{
                        pagination: { paginationModel: { pageSize: 10 } },
                        sorting: { sortModel: [{ field: 'marketCap', sort: 'desc' }] },
                    }}
                    pageSizeOptions={[5, 10, 25]}
                />
            </Container>
            <Snackbar
                open={showSnackbar}
                autoHideDuration={3000}
                onClose={() => setShowSnackbar(false)}
                message={snackbarMessage}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            />
            <UpgradePopup
                open={showUpgradePopup}
                onClose={() => setShowUpgradePopup(false)}
            />

            {selectedAsset && (
                <AddToWatchlistModal
                    open={showAddToWatchlistModal}
                    onClose={handleCloseModal} // Updated to use the new close handler
                    asset={selectedAsset}
                    userId={user?._id}
                    onSuccess={handleWatchlistSuccess} // Updated success handler that doesn't close the modal
                    assetType="stock"
                />
            )}

        </Box>
    );
};

export default MarketCapList;