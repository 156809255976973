import { useState, useEffect } from 'react';
import axios from '../config/axiosConfig';

export const useForexData = (baseCurrencies) => {
  const [forexRates, setForexRates] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchForexData = async () => {
      try {
        const promises = baseCurrencies.map(currency =>
          axios.get(`/api/forexQuoteList/currency/${currency}`)
        );
        const responses = await Promise.all(promises);

        const newRates = {};
        responses.forEach((response, index) => {
          if (response.data && response.data.data && Array.isArray(response.data.data)) {
            response.data.data.forEach(quote => {
              const [base, quoteCurrency] = quote.ticker.split('/');
              newRates[`${base}/${quoteCurrency}`] = quote.bid;
              newRates[`${quoteCurrency}/${base}`] = 1 / quote.ask;
            });
          }
        });

        setForexRates(newRates);
        setLoading(false);
      } catch (err) {
        console.error('Error fetching forex data:', err);
        setError(err);
        setLoading(false);
      }
    };

    if (baseCurrencies.length > 0) {
      fetchForexData();
    }
  }, [baseCurrencies]);

  return { forexRates, loading, error };
};

export default useForexData;