import React from 'react';
import {
  Modal,
  Button,
  Box,
  Typography
} from '@mui/material';

const DeleteModal = ({ open, onClose, onConfirm, itemName }) => {
  return (
    <Modal open={open} onClose={onClose}>
      <Box sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 4,
        borderRadius: 2,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}>
        <Typography variant="h6" component="h2" mb={2} textAlign="center">
          Confirm Deletion
        </Typography>
        <Typography variant="body1" mb={3} textAlign="center">
          {`Are you sure you want to delete "${itemName}"?`}
        </Typography>
        <Box sx={{ mt: 2, display: 'flex', justifyContent: 'center', width: '100%' }}>
          <Button onClick={onClose} sx={{ mr: 1 }}>
            No
          </Button>
          <Button onClick={onConfirm} variant="contained" color="error">
            Yes, Delete
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default DeleteModal;