import React, { useState } from 'react';
import { Grid, ButtonGroup, Button, Select, MenuItem, useMediaQuery, useTheme, Box } from '@mui/material';
import IndexCardComponent from './IndexCardComponent';

const MarketIndicesOverview = () => {
    const [selectedRegion, setSelectedRegion] = useState(() => {
        const savedRegion = localStorage.getItem('selectedMarketRegion');
        return savedRegion || 'US';
    });
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    const marketIndices = {
        US: [
            { symbol: '^SPX', label: 'S&P 500' },
            { symbol: '^IXIC', label: 'NASDAQ' },
            { symbol: '^RUI', label: 'Russell 1000' }
        ],
        Europe: [
            { symbol: '^STOXX50E', label: 'STOXX 50' },
            { symbol: '^N100', label: 'EURONEXT 100' },
            { symbol: '^GDAXI', label: 'DAX' }
        ],
        Asia: [
            { symbol: '^N225', label: 'Nikkei 225' },
            { symbol: '^AXJO', label: 'ASX 200' },
            { symbol: '^HSI', label: 'Hang Seng' }
        ]
    };

    const handleSelectChange = (event) => {
        const newRegion = event.target.value;
        setSelectedRegion(newRegion);
        localStorage.setItem('selectedMarketRegion', newRegion);
    };

    const RegionSelector = () => (
        <Select
            value={selectedRegion}
            onChange={handleSelectChange}
            size="small"
            sx={{
                width: '120px',
                '& .MuiSelect-select': {
                    py: 0.5,
                    fontSize: isMobile ? '0.75rem' : '0.875rem'
                }
            }}
        >
            {Object.keys(marketIndices).map((region) => (
                <MenuItem key={region} value={region}>
                    {region}
                </MenuItem>
            ))}
        </Select>
    );

    if (isMobile) {
        return (
            <Box sx={{ width: '100%' }}>
                <Box sx={{
                    display: 'flex',
                    justifyContent: 'center',  // Changed from 'flex-start' to 'center'
                    mb: 1,
                    px: 1
                }}>
                    <RegionSelector />
                </Box>
                <Box sx={{
                    display: 'flex',
                    width: '100%',
                    px: 1
                }}>
                    <Grid container spacing={1}>
                        {marketIndices[selectedRegion].map((index) => (
                            <Grid
                                item
                                xs={4}
                                key={index.symbol}
                            >
                                <IndexCardComponent
                                    symbol={index.symbol}
                                    isMobile={true}
                                />
                            </Grid>
                        ))}
                    </Grid>
                </Box>
            </Box>
        );
    }

    return (
        <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} md>
                <Grid
                    container
                    spacing={2}
                    sx={{
                        justifyContent: 'space-between',
                        maxWidth: '100%',
                        mx: 'auto'
                    }}
                >
                    {marketIndices[selectedRegion].map((index) => (
                        <Grid
                            item
                            xs={12}
                            md={4}
                            key={index.symbol}
                        >
                            <IndexCardComponent
                                symbol={index.symbol}
                                isMobile={false}
                            />
                        </Grid>
                    ))}
                </Grid>
            </Grid>

            <Grid
                item
                md="auto"
                sx={{
                    display: 'flex',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                    height: '100%'
                }}
            >
                <ButtonGroup
                    orientation="vertical"
                    variant="contained"
                    size="small"
                    sx={{
                        width: 'auto',
                        '& .MuiButton-root': {
                            justifyContent: 'center',
                            px: 2,
                            py: 1
                        }
                    }}
                >
                    {Object.keys(marketIndices).map((region) => (
                        <Button
                            key={region}
                            onClick={() => {
                                setSelectedRegion(region);
                                localStorage.setItem('selectedMarketRegion', region);
                            }}
                            variant={selectedRegion === region ? 'contained' : 'outlined'}
                            sx={{
                                color: selectedRegion === region ? '#ffffff' : '#1976d2'  // White for selected, blue for unselected
                            }}
                        >
                            {region}
                        </Button>
                    ))}
                </ButtonGroup>
            </Grid>
        </Grid>
    );
};

export default MarketIndicesOverview;