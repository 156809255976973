import React from 'react';
import { Container, Paper, Typography, Box, List, ListItem, ListItemText, Divider } from '@mui/material';

const PrivacyPolicy = () => {
    return (
        <Container maxWidth="md">
            <Paper elevation={3} style={{ padding: '20px', marginTop: '20px' }}>
                <Box my={4}>
                    <Typography variant="h4" gutterBottom>
                        Privacy Policy
                    </Typography>
                    <Typography variant="subtitle1" gutterBottom>
                        Last Revised: 11/20/2024
                    </Typography>
                    <Divider />
                    <Box my={2}>
                        <Typography variant="h6" gutterBottom>
                            1. Introduction
                        </Typography>
                        <Typography variant="body1" paragraph>
                            Market Rodeo ("we," "our," "us") respects your privacy and is committed to protecting your personal data. This privacy policy will inform you about how we look after your personal data when you visit our website or use our services and tell you about your privacy rights and how the law protects you.
                        </Typography>
                    </Box>
                    <Box my={2}>
                        <Typography variant="h6" gutterBottom>
                            2. What Data We Collect
                        </Typography>
                        <List>
                            <ListItem>
                                <ListItemText primary="Identity Data: First name, last name, username, or similar identifier." />
                            </ListItem>
                            <ListItem>
                                <ListItemText primary="Contact Data: Email address and telephone numbers." />
                            </ListItem>
                            <ListItem>
                                <ListItemText
                                    primary="Financial Data : We do not store any payment card details. All payment processing is handled securely by Stripe. We only store subscription status."
                                />
                            </ListItem>
                            <ListItem>
                                <ListItemText primary="Technical Data: Internet protocol (IP) address, your login data, cookies, browser type and version, time zone setting and location, browser plug-in types and versions, operating system and platform, and other technology on the devices you use to access our website." />
                            </ListItem>
                        </List>
                    </Box>
                    <Box my={2}>
                        <Typography variant="h6" gutterBottom>
                            3. How Do We Collect Your Data
                        </Typography>
                        <Typography variant="body1" paragraph>
                            We use different methods to collect data from and about you including through direct interactions. You may give us your Identity and Contact Data by filling in forms or by corresponding with us by post, phone, email, or otherwise. This includes personal data you provide when you create an account on our website, subscribe to our service or publications, request marketing to be sent to you, enter a competition, promotion or survey, or give us feedback or contact us.
                        </Typography>
                    </Box>
                    <Box my={2}>
                        <Typography variant="h6" gutterBottom>
                            4. How Will We Use Your Data
                        </Typography>
                        <Typography variant="body1" paragraph>
                            We use your data to:
                        </Typography>
                        <List>
                            <ListItem>
                                <ListItemText primary="Provide, improve, and maintain our services." />
                            </ListItem>
                            <ListItem>
                                <ListItemText primary="Understand and analyze how you use our services." />
                            </ListItem>
                            <ListItem>
                                <ListItemText primary="Develop new services, features, and functionality." />
                            </ListItem>
                            <ListItem>
                                <ListItemText primary="Communicate with you, either directly or through one of our partners, for customer service, to provide you with updates and other information relating to our services, and for marketing and promotional purposes." />
                            </ListItem>
                            <ListItem>
                                <ListItemText primary="For compliance purposes, including enforcing our Terms of Service, or other legal rights, or as may be required by applicable laws and regulations or requested by any judicial process or governmental agency." />
                            </ListItem>
                        </List>
                    </Box>
                    <Box my={2}>
                        <Typography variant="h6" gutterBottom>
                            5. How Do We Store Your Data
                        </Typography>
                        <Typography variant="body1" paragraph>
                            Market Rodeo securely stores your data. We will only retain your personal data for as long as necessary to fulfill the purposes we collected it for, including for the purposes of satisfying any legal, accounting, or reporting requirements.
                        </Typography>
                    </Box>
                    <Box my={2}>
                        <Typography variant="h6" gutterBottom>
                            6. Marketing
                        </Typography>
                        <Typography variant="body1" paragraph>
                            Market Rodeo would like to send you information about products and services of ours that we think you might like. If you have agreed to receive marketing, you may always opt-out at a later date.
                        </Typography>
                    </Box>
                    <Box my={2}>
                        <Typography variant="h6" gutterBottom>
                            7. What Are Your Data Protection Rights
                        </Typography>
                        <Typography variant="body1" paragraph>
                            We would like to make sure you are fully aware of all of your data protection rights. Every user is entitled to the following:
                        </Typography>
                        <List>
                            <ListItem>
                                <ListItemText primary="The right to access – You have the right to request us for copies of your personal data." />
                            </ListItem>
                            <ListItem>
                                <ListItemText primary="The right to rectification – You have the right to request that we correct any information you believe is inaccurate." />
                            </ListItem>
                            <ListItem>
                                <ListItemText primary="The right to erasure – You have the right to request that we erase your personal data, under certain conditions." />
                            </ListItem>
                            <ListItem>
                                <ListItemText primary="The right to restrict processing – You have the right to request that we restrict the processing of your personal data, under certain conditions." />
                            </ListItem>
                            <ListItem>
                                <ListItemText primary="The right to object to processing – You have the right to object to our processing of your personal data, under certain conditions." />
                            </ListItem>
                            <ListItem>
                                <ListItemText primary="The right to data portability – You have the right to request that we transfer the data that we have collected to another organization, or directly to you, under certain conditions." />
                            </ListItem>
                        </List>
                    </Box>
                    <Box my={2}>
                        <Typography variant="h6" gutterBottom>
                            8. Changes to Our Privacy Policy
                        </Typography>
                        <Typography variant="body1" paragraph>
                            Market Rodeo keeps its privacy policy under regular review and places any updates on this web page. This privacy policy was last updated on 07/08/2024.
                        </Typography>
                    </Box>
                    <Box my={2}>
                        <Typography variant="h6" gutterBottom>
                            9. Contact Us
                        </Typography>
                        <Typography variant="body1" paragraph>
                            If you have any questions about Market Rodeo's privacy policy, the data we hold on you, or you would like to exercise one of your data protection rights, please do not hesitate to contact us at: support@marketrodeo.com
                        </Typography>
                    </Box>
                </Box>
            </Paper>
        </Container>
    );
};

export default PrivacyPolicy;
