import currencySymbols from './currencySymbols';

export const formatCurrency = (value, currency = 'USD') => {
  // Return N/A for invalid numbers
  if (typeof value !== 'number' || isNaN(value)) return 'N/A';
  
  // Get the currency symbol from our mapping, defaulting to the currency code if not found
  const symbol = currencySymbols[currency] || currency;
  
  // Format the number without currency symbol
  const formattedNumber = new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(Math.abs(value));
  
  // Handle negative values
  const isNegative = value < 0;
  
  // Construct the final string with symbol always on left
  let result = `${symbol}${formattedNumber}`;
  
  // Add negative sign if needed
  if (isNegative) {
    result = `-${result}`;
  }
  
  return result;
};

export default formatCurrency;
