import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import axios from '../config/axiosConfig';
import {
  useTheme,
  Typography,
  Alert,
  Container,
  FormControl,
  InputLabel,
  Input,
  Button,
  Box,
  Paper,
  InputAdornment,
  IconButton,
  Divider,
  GlobalStyles
} from '@mui/material';
import {
  Email as EmailIcon,
  Lock as LockIcon,
  Visibility as VisibilityIcon,
  VisibilityOff as VisibilityOffIcon,
  Google as GoogleIcon
} from '@mui/icons-material';
import { useAuth } from './AuthContext';
import logo from '../images/rodeoLogo.png';

const Login = () => {
  const theme = useTheme();
  const { Signup, setUser, setIsAuthenticated } = useAuth();
  const [error, setError] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [formData, setFormData] = useState({
    email: '',
    password: ''
  });
  const navigate = useNavigate();

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleGoogleSignup = () => {
    Signup();
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('/auth/locallogin', formData);
      if (response && response.data && response.data.success) {
        const user = response.data.user;
        if (!user.isVerified) {
          setError('Email verification required. Please verify your email to login.');
          return;
        }
        setUser(user);
        setIsAuthenticated(response.data.success);
        navigate('/');
      }
    } catch (error) {
      setError(error.response.data.message);
    }
  };

  return (
    <>
      <GlobalStyles
        styles={{
          'input:-webkit-autofill, input:-webkit-autofill:hover, input:-webkit-autofill:focus, input:-webkit-autofill:active': {
            '-webkit-box-shadow': `0 0 0 30px ${theme.palette.background.paper} inset !important`,
            '-webkit-text-fill-color': `${theme.palette.text.primary} !important`,
            'caret-color': `${theme.palette.text.primary} !important`,
            'background-color': `${theme.palette.background.paper} !important`,
            'transition': 'background-color 600000s 0s, color 600000s 0s !important'
          }
        }}
      />
      <Container maxWidth="sm" sx={{
        minHeight: '100vh',
        display: 'flex',
        alignItems: 'center',
        py: 8
      }}>
        <Paper elevation={theme.palette.mode === 'dark' ? 2 : 1} sx={{
          width: '100%',
          borderRadius: 2,
          p: { xs: 3, sm: 6 },
          backgroundColor: theme.palette.mode === 'dark' ? 'background.paper' : 'background.default',
        }}>
          <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: 3
          }}>
            <img
              src={logo}
              alt="Market Rodeo Logo"
              style={{ height: 'auto', width: '69px' }}
            />

            <Box sx={{ textAlign: 'center', mb: 2 }}>
              <Typography variant="h4" gutterBottom fontWeight="bold" color="primary">
                Welcome Back
              </Typography>
              <Typography variant="body1" color="text.secondary">
                Enter your credentials to access your account
              </Typography>
            </Box>

            {error && (
              <Alert
                severity="error"
                sx={{
                  width: '100%',
                  borderRadius: 1
                }}
              >
                {error}
              </Alert>
            )}

            <Box
              component="form"
              onSubmit={handleLogin}
              sx={{
                width: '100%',
                // Make all input fields text larger
                '& .MuiInputBase-input': {
                  fontSize: '1.2rem',  // Much larger input text
                  padding: '0.7rem 0'  // Add some vertical padding
                },
                // Make all labels larger
                '& .MuiInputLabel-root': {
                  fontSize: '1.2rem'  // Larger label text
                },
                // Make checkbox text larger
                '& .MuiFormControlLabel-label': {
                  fontSize: '1.2rem'
                },
                // Make helper/error text larger
                '& .MuiFormHelperText-root': {
                  fontSize: '1rem'
                }
              }}
            >
              <FormControl fullWidth variant="outlined" sx={{ mb: 3 }}>
                <InputLabel htmlFor="email">Email address</InputLabel>
                <Input
                  id="email"
                  type="email"
                  name="email"
                  autoComplete="email"
                  value={formData.email}
                  onChange={handleInputChange}
                  startAdornment={
                    <InputAdornment position="start">
                      <EmailIcon />
                    </InputAdornment>
                  }
                  sx={{
                    '&:-webkit-autofill': {
                      WebkitBoxShadow: `0 0 0 100px ${theme.palette.background.paper} inset !important`,
                      WebkitTextFillColor: `${theme.palette.text.primary} !important`,
                    }
                  }}
                />
              </FormControl>

              <FormControl fullWidth variant="outlined">
                <InputLabel htmlFor="password">Password</InputLabel>
                <Input
                  id="password"
                  type={showPassword ? 'text' : 'password'}
                  name="password"
                  autoComplete="current-password"
                  value={formData.password}
                  onChange={handleInputChange}
                  startAdornment={
                    <InputAdornment position="start">
                      <LockIcon />
                    </InputAdornment>
                  }
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() => setShowPassword(!showPassword)}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                      </IconButton>
                    </InputAdornment>
                  }
                  sx={{
                    '&:-webkit-autofill': {
                      WebkitBoxShadow: `0 0 0 100px ${theme.palette.background.paper} inset !important`,
                      WebkitTextFillColor: `${theme.palette.text.primary} !important`,
                    }
                  }}
                />
              </FormControl>

              <Button
                fullWidth
                variant="contained"
                type="submit"
                size="large"
                sx={{
                  mt: 4,
                  mb: 2,
                  height: 48,
                  textTransform: 'none',
                  fontWeight: 600,
                  fontSize: '1rem'  // Larger button text
                }}
              >
                Sign in
              </Button>

              <Box sx={{
                display: 'flex',
                alignItems: 'center',
                my: 3
              }}>
                <Divider sx={{ flex: 1 }} />
                <Typography
                  variant="body2"
                  color="text.secondary"
                  sx={{ px: 2 }}
                >
                  or
                </Typography>
                <Divider sx={{ flex: 1 }} />
              </Box>

              <Button
                fullWidth
                variant="outlined"
                onClick={handleGoogleSignup}
                startIcon={<GoogleIcon />}
                sx={{
                  height: 48,
                  textTransform: 'none',
                  fontWeight: 500
                }}
              >
                Continue with Google
              </Button>
            </Box>

            <Typography variant="body1" sx={{ mt: 3 }}>
              Don't have an account?{' '}
              <Link
                to="/signup"
                style={{
                  color: theme.palette.primary.main,
                  textDecoration: 'none',
                  fontWeight: 600
                }}
              >
                Sign up
              </Link>
            </Typography>
          </Box>
        </Paper>
      </Container>
    </>
  );
};

export default Login;