// BlogDetail.js
import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import axios from '../config/axiosConfig';
import { 
  Container, 
  Typography, 
  Box, 
  CircularProgress,
  Button,
  useTheme
} from '@mui/material';
import { 
  AccessTime as AccessTimeIcon,
  CalendarToday as CalendarIcon,
  Person as PersonIcon,
  ArrowBack as ArrowBackIcon
} from '@mui/icons-material';

const BlogDetail = () => {
  const { id } = useParams();
  const [blog, setBlog] = useState(null);
  const [loading, setLoading] = useState(true);
  const theme = useTheme();

  useEffect(() => {
    axios.get(`/blogs/${id}`)
      .then(response => {
        setBlog(response.data);
        setLoading(false);
      })
      .catch(error => {
        console.error(error);
        setLoading(false);
      });
  }, [id]);

  if (loading) {
    return (
      <Box 
        sx={{ 
          display: 'flex', 
          justifyContent: 'center', 
          alignItems: 'center', 
          minHeight: '60vh' 
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  if (!blog) {
    return (
      <Box 
        sx={{ 
          textAlign: 'center', 
          py: 8 
        }}
      >
        <Typography variant="h5">Blog post not found</Typography>
        <Button 
          component={Link} 
          to="/blogs" 
          variant="contained" 
          sx={{ mt: 2 }}
        >
          Return to Blog List
        </Button>
      </Box>
    );
  }

  return (
    <Box>
      {/* Hero Section */}
      <Box 
        sx={{ 
          position: 'relative',
          height: { xs: '300px', md: '500px' },
          width: '100%',
          overflow: 'hidden',
          mb: 6
        }}
      >
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundImage: `url(${blog.imageUrl})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            '&::after': {
              content: '""',
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundColor: 'rgba(0, 0, 0, 0.4)',
            }
          }}
        />
        <Container maxWidth="lg" sx={{ height: '100%', position: 'relative' }}>
          {/* Back Button */}
          <Button
            component={Link}
            to="/blogs"
            startIcon={<ArrowBackIcon />}
            sx={{ 
              position: 'absolute',
              top: '2rem',
              left: '2rem',
              color: 'white',
              '&:hover': {
                backgroundColor: 'rgba(255, 255, 255, 0.1)'
              }
            }}
          >
            Back to Blogs
          </Button>

          <Box
            sx={{
              position: 'absolute',
              bottom: '2rem',
              color: 'white',
              maxWidth: '800px'
            }}
          >
            <Typography 
              variant="h2" 
              component="h1"
              sx={{ 
                fontWeight: 'bold',
                fontSize: { xs: '2rem', md: '3rem' },
                mb: 2,
                textShadow: '2px 2px 4px rgba(0,0,0,0.5)'
              }}
            >
              {blog.title}
            </Typography>
            
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 3, flexWrap: 'wrap' }}>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <PersonIcon sx={{ fontSize: 20 }} />
                <Typography>{blog.author}</Typography>
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <CalendarIcon sx={{ fontSize: 20 }} />
                <Typography>
                  {new Date(blog.publishedDate).toLocaleDateString('en-US', {
                    year: 'numeric',
                    month: 'long',
                    day: 'numeric'
                  })}
                </Typography>
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <AccessTimeIcon sx={{ fontSize: 20 }} />
                <Typography>{blog.readTime} min read</Typography>
              </Box>
            </Box>
          </Box>
        </Container>
      </Box>

      {/* Content Section */}
      <Container maxWidth="md" sx={{ pb: 8 }}>
    
        <Box sx={{ 
          typography: 'body1',
          '& p': { 
            mb: 2,
            lineHeight: 1.8,
            fontSize: '1.1rem'
          }
        }}>
          {blog.content.split('.').map((sentence, index) => (
            sentence.trim() && (
              <Typography 
                key={index} 
                paragraph
                sx={{
                  color: theme.palette.text.primary,
                  fontSize: '1.1rem',
                  lineHeight: 1.8
                }}
              >
                {sentence.trim()}.
              </Typography>
            )
          ))}
        </Box>
      </Container>
    </Box>
  );
};

export default BlogDetail;