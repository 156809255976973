import React, { useEffect, useState, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from '../config/axiosConfig';
import { Container, CircularProgress, Alert, Box } from '@mui/material';
import PortfolioTable from './PortfolioTable';
import PortfolioSummary from './PortfolioSummary';
import useForexData from '../hooks/useForexData';
import UpgradePopup from './UpgradePopup';
import ErrorBoundary from './ErrorBoundary';
import CreateModal from './CreateModal';

const Portfolio = ({ user }) => {
  const navigate = useNavigate();
  const [portfolios, setPortfolios] = useState([]);
  const [selectedPortfolio, setSelectedPortfolio] = useState(null);
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const [showUpgradePopup, setShowUpgradePopup] = useState(false);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [convertedData, setConvertedData] = useState({
    totalCost: 0,
    totalValue: 0,
    dailyChange: 0,
    worstPerformer: null,
    bestPerformer: null,
    portfolioData: {}
  });
  const [isLoading, setIsLoading] = useState(true);

  const baseUrl = window.location.origin;

  const uniqueBaseCurrencies = useMemo(() => {
    return [...new Set(portfolios.map(p => p.baseCurrency))];
  }, [portfolios]);

  // Use useForexData for all unique base currencies
  const { forexRates, loading: forexLoading, error: forexError } = useForexData(uniqueBaseCurrencies);

  useEffect(() => {
    fetchPortfolios();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      fetchPortfoliosHoldings(portfolios);
    }, 60000);
    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [portfolios]);

  useEffect(() => {
    setSelectedPortfolio(portfolios[selectedTabIndex]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [portfolios, selectedTabIndex]);

  useEffect(() => {
    if (portfolios.length > 0) {
      setSelectedPortfolio(portfolios[selectedTabIndex]);
    } else {
      setSelectedPortfolio(null);
    }
  }, [portfolios, selectedTabIndex]);

  useEffect(() => {
    if (selectedPortfolio && !forexLoading && !forexError) {
      calculateConvertedData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPortfolio, forexRates, forexLoading, forexError]);

  const fetchPortfolios = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(`/api/portfolios/${user._id}`);
      setPortfolios(response.data);
      // Show create modal if no portfolios exist
      if (response.data.length === 0) {
        setShowCreateModal(true);
      }
      if (response.data.length > 0) {
        await fetchPortfoliosHoldings(response.data);
      }
    } catch (error) {
      console.error('Error fetching portfolios:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleCreateModalClose = () => {
    setShowCreateModal(false);
  };

  const handleCreatePortfolioAndClose = async (itemName) => {
    await handleCreatePortfolio(itemName);
    setShowCreateModal(false);
  };

  const fetchPortfoliosHoldings = async (portfoliosData) => {
    try {
      const response = await axios.get(`/api/portfolios/holdings/${user._id}`);
      const quoteData = response.data;
      const updatedPortfolios = portfoliosData.map(portfolio => {
        const updatedHoldings = portfolio.holdings.map(holding => {
          const quote = quoteData.find(q => q.symbol === holding.symbol);
          const { name, exchange, price: quotePrice, changesPercentage, change, currency } = quote || {};
          return {
            ...holding,
            name,
            exchange,
            quotePrice,
            changesPercentage,
            change,
            currency
          };
        });
        return {
          ...portfolio,
          holdings: updatedHoldings,
        };
      });

      setPortfolios(updatedPortfolios);
    } catch (error) {
      console.error('Error fetching holdings quote data:', error);
    }
  };

  // const convertCurrency = (amount, fromCurrency, toCurrency) => {
  //   if (fromCurrency === toCurrency) return amount;
  //   const rate = forexRates[`${fromCurrency}/${toCurrency}`];
  //   return rate ? amount * rate : amount;
  // };

  const convertCurrency = (amount, fromCurrency, toCurrency) => {
    if (fromCurrency === toCurrency) return amount;

    // Standardize currency codes
    const standardizedFromCurrency = fromCurrency === 'ILA' ? 'ILS' : fromCurrency;
    const standardizedToCurrency = toCurrency === 'ILA' ? 'ILS' : toCurrency;

    const rate = forexRates[`${standardizedFromCurrency}/${standardizedToCurrency}`];
    return rate ? amount * rate : amount;
  };

  const calculateConvertedData = () => {
    if (!selectedPortfolio || !forexRates) return;

    const baseCurrency = selectedPortfolio.baseCurrency;
    let totalCost = 0;
    let totalValue = 0;
    let dailyChange = 0;
    let bestPerformer = null;
    let worstPerformer = null;
    const portfolioData = {};

    selectedPortfolio.holdings.forEach(holding => {
      const convertedPrice = convertCurrency(holding.price, holding.currency, baseCurrency);
      const convertedQuotePrice = convertCurrency(holding.quotePrice, holding.currency, baseCurrency);
      const convertedChange = convertCurrency(holding.change, holding.currency, baseCurrency);

      const cost = holding.quantity * convertedPrice;
      const value = holding.quantity * convertedQuotePrice;
      const change = holding.quantity * convertedChange;

      totalCost += cost;
      totalValue += value;
      dailyChange += change;

      const pnl = value - cost;
      if (!bestPerformer || pnl > bestPerformer.pnl) {
        bestPerformer = { ...holding, pnl };
      }
      if (!worstPerformer || pnl < worstPerformer.pnl) {
        worstPerformer = { ...holding, pnl };
      }

      // Store the current value (quantity * current price) instead of just quantity
      portfolioData[holding.symbol] = value;
    });

    setConvertedData({
      totalCost,
      totalValue,
      dailyChange,
      bestPerformer,
      worstPerformer,
      portfolioData
    });
  };

  const handleBaseCurrencyUpdate = async (portfolioId, newCurrency) => {
    try {
      await axios.put(`/api/portfolio/${portfolioId}/currency`, { baseCurrency: newCurrency });
      fetchPortfolios();
    } catch (error) {
      console.error('Error updating portfolio currency:', error);
      // Handle error (show message to user, etc.)
    }
  };

  const handleTabChange = (event, newValue) => {
    setSelectedTabIndex(newValue);
  };

  const handleCreatePortfolio = async (itemName) => {
    try {
      const response = await axios.post('/api/portfolios', {
        userId: user._id,
        holdings: [],
        name: itemName,
      });
      setShowUpgradePopup(false);
      fetchPortfolios();
      setSelectedPortfolio(response.data);
    } catch (error) {
      if (error.response && error.response.status === 403) {
        setShowUpgradePopup(true);
      } else {
        console.error('Error creating Portfolio:', error);
      }
    }
  };

  const handlePortfolioNameUpdate = async (portfolioId, newName) => {
    try {
      await axios.put(`/api/portfolio/${portfolioId}`, { name: newName });
      fetchPortfolios();
    } catch (error) {
      console.error('Error updating portfolio name:', error);
    }
  };

  const handlePortfolioDelete = async (portfolio) => {
    try {
      await axios.delete(`/api/portfolios/${portfolio._id}`);
      const updatedPortfolios = portfolios.filter(p => p._id !== portfolio._id);
      setPortfolios(updatedPortfolios);
      if (updatedPortfolios.length > 0) {
        setSelectedTabIndex(0);
      } else {
        setSelectedPortfolio(null);
      }
    } catch (error) {
      console.error('Error deleting portfolio:', error);
    }
  };

  const handleAssetDelete = async (portfolioId, holdingId) => {
    try {
      await axios.delete(`/api/deleteasset/${portfolioId}/${holdingId}`);
      fetchPortfolios();
    } catch (error) {
      console.error('Error deleting Asset:', error);
    }
  };

  if (!user) {
    navigate('/login');
    return null;
  }

  if (isLoading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <CircularProgress />
      </Box>
    );
  }

  /*  if (forexLoading) {
    return <CircularProgress />;
  }
    */

  if (forexError) {
    return <Alert severity="error">Error loading forex data. Please try again later.</Alert>;
  }

  let portfolioData = {};
  if (selectedPortfolio) {
    selectedPortfolio.holdings.forEach(holding => {
      const existingQuantity = portfolioData[holding.symbol];
      const newQuantity = holding.quantity;
      portfolioData[holding.symbol] = existingQuantity ? existingQuantity + newQuantity : newQuantity;
    });
  }

  return (
    <Container maxWidth="xl">
      <ErrorBoundary>
        {portfolios.length > 0 && selectedPortfolio && (
          <PortfolioSummary
            totalCost={convertedData.totalCost}
            totalValue={convertedData.totalValue}
            dailyChange={convertedData.dailyChange}
            bestPerformer={convertedData.bestPerformer}
            worstPerformer={convertedData.worstPerformer}
            portfolioData={convertedData.portfolioData}
            baseCurrency={selectedPortfolio.baseCurrency}
          />
        )}
      </ErrorBoundary>

      <ErrorBoundary>
        <PortfolioTable
          user={user}
          portfolios={portfolios}
          selectedPortfolio={selectedPortfolio}
          selectedTabIndex={selectedTabIndex}
          handleTabChange={handleTabChange}
          handleCreatePortfolio={handleCreatePortfolio}
          handlePortfolioNameUpdate={handlePortfolioNameUpdate}
          handlePortfolioDelete={handlePortfolioDelete}
          handleAssetDelete={handleAssetDelete}
          fetchPortfolios={fetchPortfolios}
          baseUrl={baseUrl}
          setShowUpgradePopup={setShowUpgradePopup}
          convertCurrency={convertCurrency}
          forexRates={forexRates}
          handleBaseCurrencyUpdate={handleBaseCurrencyUpdate}

        />
      </ErrorBoundary>

      <UpgradePopup
        open={showUpgradePopup}
        onClose={() => setShowUpgradePopup(false)}
      />

      <CreateModal
        open={showCreateModal}
        onClose={handleCreateModalClose}
        onCreate={handleCreatePortfolioAndClose}
        title="Create Portfolio"
      />

    </Container>
  );
};

export default Portfolio;