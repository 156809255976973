import React, { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { useAuth } from './AuthContext';
import axios from '../config/axiosConfig';
import {
 Container,
 CircularProgress,
 Box,
 Typography
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import TitleAndInfo from './TitleAndInfo';
import { exportToCsv } from '../utils/exportToCsv';
import CryptoQuotesScreenerFilterSystem from './CryptoQuotesScreenerFilterSystem';

const CryptoQuotesScreener = () => {
 const { user } = useAuth();
 const theme = useTheme();
 const [loading, setLoading] = useState(false);
 const [stocks, setStocks] = useState([]);
 const baseUrl = window.location.origin;

 const titleTop = "Crypto Quote Screener: ";
 const title = "";
 const info = "The Crypto Quote Screener offers in-depth crypto data. This tool provides essential metrics. Use this screener to access vital information, refine your trading strategies, and make well-informed investment decisions. Perfect for traders and investors seeking detailed crypto insights for effective trading.";

 const handleSubmit = async (filterData) => {
   setStocks([]);
   setLoading(true);

   try {
     const response = await axios.post('/api/filteredcryptoquotes', filterData);
     setStocks(response.data.data);
   } catch (error) {
     console.error('Error fetching data:', error);
   } finally {
     setLoading(false);
   }
 };

 const handleClear = () => {
   setStocks([]);
 };

 const handleExport = () => {
   exportToCsv('crypto_quotes_screener.csv', columns, stocks);
 };

 const columns = [
   {
     field: 'symbol',
     headerName: 'Company',
     minWidth: 200,
     renderCell: (params) => (
       <div>
         <RouterLink
           to={`${baseUrl}/asset/${params.row.symbol}`}
           style={{ color: theme.palette.primary.main, textDecoration: 'none', fontWeight: 'bold' }}
         >
           {params.row.symbol}
         </RouterLink>
         <div style={{ fontSize: '0.9em', color: '#888', fontWeight: 'bold' }}>{params.row.name}</div>
       </div>
     ),
     align: 'left',
     headerAlign: 'left',
   },
   { field: 'price', headerName: 'Price', flex: 1, minWidth: 120 },
   {
     field: 'changesPercentage',
     headerName: 'Changes Percentage',
     flex: 1,
     minWidth: 150,
     renderCell: (params) => (
       <div style={{ color: params.value > 0 ? theme.palette.positive.main : params.value < 0 ? theme.palette.negative.main : theme.palette.lightGray.main }}>
         {params.value}
       </div>
     ),
   },
   {
     field: 'change',
     headerName: 'Change',
     flex: 1,
     minWidth: 120,
     renderCell: (params) => (
       <div style={{ color: params.value > 0 ? theme.palette.positive.main : params.value < 0 ? theme.palette.negative.main : theme.palette.lightGray.main }}>
         {params.value}
       </div>
     ),
   },
   { field: 'dayLow', headerName: 'Day Low', flex: 1, minWidth: 120 },
   { field: 'dayHigh', headerName: 'Day High', flex: 1, minWidth: 120 },
   { field: 'yearHigh', headerName: 'Year High', flex: 1, minWidth: 120 },
   { field: 'yearLow', headerName: 'Year Low', flex: 1, minWidth: 120 },
   { field: 'marketCap', headerName: 'Market Cap', flex: 1, minWidth: 120 },
   { field: 'volume', headerName: 'Volume', flex: 1, minWidth: 120 },
   { field: 'avgVolume', headerName: 'Avg Volume', flex: 1, minWidth: 120 },
   { field: 'open', headerName: 'Open', flex: 1, minWidth: 120 },
   { field: 'previousClose', headerName: 'Previous Close', flex: 1, minWidth: 120 }
 ];

 return (
   <div>
     <Container maxWidth="xl" sx={{ mt: 4 }}>
       <TitleAndInfo titleTop={titleTop} title={title} info={info} />

       <CryptoQuotesScreenerFilterSystem
         onSearch={handleSubmit}
         onClear={handleClear}
         onExport={handleExport}
         showExport={user.plan.name === 'Premium' && stocks.length > 0}
       />

       {loading && (
         <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
           <CircularProgress />
         </Box>
       )}

       {!loading && stocks.length === 0 && (
         <Box sx={{ mt: 4, textAlign: 'center' }}>
           <Typography>No results found.</Typography>
         </Box>
       )}
     </Container>

     {stocks.length > 0 && (
       <Container maxWidth="xxl" sx={{ mt: 4 }}>
         <DataGrid
           rows={stocks}
           columns={columns}
           getRowId={(row) => row.symbol}
           disableColumnMenu
           pagination
           paginationPosition="middle"
           initialState={{
             pagination: { paginationModel: { pageSize: 10 } },
             sorting: { sortModel: [{ field: 'marketCap', sort: 'desc' }] },
           }}
           pageSizeOptions={[5, 10, 25]}
           sx={{
             '& .MuiDataGrid-cell': {
               fontSize: '1rem',
             },
             '& .MuiDataGrid-columnHeaders': {
               fontSize: '0.8rem',
             },
           }}
         />
       </Container>
     )}
   </div>
 );
};

export default CryptoQuotesScreener;