import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { Container, Box, Grid, Typography, Switch, FormControlLabel } from '@mui/material';
import BarChart from './BarChart';
import { DataGrid } from '@mui/x-data-grid';
import withPlanAccess from './WithPlanAccess';

const CompanyHistoricalDividends = ({ data }) => {
  const { symbol } = useParams();
  const [showRawData, setShowRawData] = useState(false);

  // Transform and validate the data
  const historicalDividendsData = React.useMemo(() => {
    if (!data || !Array.isArray(data)) return [];

    const allHistoricalData = data
      .filter(item => item.historical && Array.isArray(item.historical))
      .flatMap(item => item.historical)
      .filter(dividend =>
        dividend &&
        dividend.dividend &&
        dividend.date
      );

    return allHistoricalData.sort((a, b) => new Date(a.date) - new Date(b.date));
  }, [data]);

  if (!historicalDividendsData.length) {
    return null;
  }

  const prepareChartData = (label, key, colors) => {
    return {
      labels: historicalDividendsData.map(dividend => new Date(dividend.date).getFullYear()),
      datasets: [{
        label: label,
        backgroundColor: colors.backgroundColor,
        borderColor: colors.borderColor,
        borderWidth: 1,
        hoverBackgroundColor: colors.hoverBackgroundColor,
        hoverBorderColor: colors.hoverBorderColor,
        data: historicalDividendsData.map(dividend => dividend[key])
      }]
    };
  };

  // Define columns for DataGrid
  const columns = [
    {
      field: 'label',
      headerName: 'Label',
      flex: 1
    },
    {
      field: 'adjDividend',
      headerName: 'Adj Dividend',
      flex: 1,
      valueFormatter: (params) =>
        params.value ? Number(params.value).toFixed(4) : 'N/A'
    },
    {
      field: 'dividend',
      headerName: 'Dividend',
      flex: 1,
      valueFormatter: (params) =>
        params.value ? Number(params.value).toFixed(4) : 'N/A'
    },
    {
      field: 'recordDate',
      headerName: 'Record Date',
      flex: 1,
      valueFormatter: params =>
        params.value ? new Date(params.value).toLocaleDateString() : 'N/A'
    },
    {
      field: 'paymentDate',
      headerName: 'Payment Date',
      flex: 1,
      valueFormatter: params =>
        params.value ? new Date(params.value).toLocaleDateString() : 'N/A'
    },
    {
      field: 'declarationDate',
      headerName: 'Declaration Date',
      flex: 1,
      valueFormatter: params =>
        params.value ? new Date(params.value).toLocaleDateString() : 'N/A'
    },
  ];

  // Prepare rows for DataGrid
  const rows = historicalDividendsData.map((dividend, index) => ({
    id: index,
    label: dividend.label || `Dividend ${index + 1}`,
    adjDividend: dividend.adjDividend,
    dividend: dividend.dividend,
    recordDate: dividend.recordDate,
    paymentDate: dividend.paymentDate,
    declarationDate: dividend.declarationDate,
    date: dividend.date
  }));

  const chartDataDividends = prepareChartData('Dividend', 'dividend', {
    backgroundColor: 'rgba(75,192,192,0.2)',
    borderColor: 'rgba(75,192,192,1)',
    hoverBackgroundColor: 'rgba(75,192,192,0.4)',
    hoverBorderColor: 'rgba(75,192,192,1)'
  });

  return (
    <Container>
      <Box my={4}>
        <Grid container justifyContent="center" spacing={4}>
          <Grid item xs={12} sm={10}>
            <Typography
              variant="h4"
              gutterBottom
              align="center"
            >
              Dividend History
            </Typography>
            {chartDataDividends && <BarChart data={chartDataDividends} />}
          </Grid>
        </Grid>
      </Box>

      <Box my={4} display="flex" justifyContent="center">
        <FormControlLabel
          control={
            <Switch
              checked={showRawData}
              onChange={() => setShowRawData(!showRawData)}
            />
          }
          label="Show Raw Data"
        />
      </Box>

      {showRawData && (
        <Box my={4} sx={{ width: '100%' }}>
          <Typography
            variant="h4"
            gutterBottom
            align="center"
          >
            Historical Dividends for {symbol}
          </Typography>
          <DataGrid
            rows={rows}
            columns={columns}
            getRowId={(row) => row.id}
            disableColumnMenu
            pagination
            paginationPosition="middle"
            initialState={{
              pagination: { paginationModel: { pageSize: 15 } },
              sorting: { sortModel: [{ field: 'date', sort: 'desc' }] },
            }}
            pageSizeOptions={[5, 10, 15, 25]}
            sx={{
              '& .MuiDataGrid-cell': {
                fontSize: '1rem',
              },
              '& .MuiDataGrid-columnHeaders': {
                fontSize: '0.8rem',
              },
            }}
            autoHeight
          />
        </Box>
      )}
    </Container>
  );
};

// Maintain the plan access control
const allowedPlans = ['Basic', 'Premium'];
export default withPlanAccess(CompanyHistoricalDividends, allowedPlans);