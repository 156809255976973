import React, { useState, useEffect } from 'react';
import {
  Modal,
  TextField,
  Button,
  Box,
  Typography
} from '@mui/material';

const CreateModal = ({ open, onClose, onCreate, title }) => {
  const [itemName, setItemName] = useState('');
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    if (open) {
      setItemName('');
      setIsError(false);
    }
  }, [open]);

  const handleInputChange = (e) => {
    const value = e.target.value.trim();
    setItemName(value);
    setIsError(value === '');
  };

  const handleCreate = () => {
    if (itemName) {
      onCreate(itemName);
      setItemName('');
      onClose();
    }
  };

  const isCreateDisabled = itemName.trim() === '';

  return (
    <Modal open={open} onClose={onClose}>
      <Box sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 4,
        borderRadius: 2,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}>
        <Typography variant="h6" component="h2" mb={2} textAlign="center">
          {title}
        </Typography>
        <TextField
          autoFocus
          margin="dense"
          label="Enter Name"
          type="text"
          fullWidth
          value={itemName}
          onChange={handleInputChange}
          error={isError}
          helperText={isError ? "Name cannot be empty" : ""}
          onKeyDown={(e) => {
            e.stopPropagation();
            if (e.key === 'Enter' && !isCreateDisabled) {
              handleCreate();
            }
          }}
        />
        <Box sx={{ mt: 2, display: 'flex', justifyContent: 'center', width: '100%' }}>
          <Button onClick={onClose} sx={{ mr: 1 }}>
            Cancel
          </Button>
          <Button 
            onClick={handleCreate} 
            variant="contained" 
            disabled={isCreateDisabled}
          >
            Create
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default CreateModal;