import React from 'react';
import { Typography, Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';

const TitleAndInfo = ({ title, titleTop, info }) => {
  const theme = useTheme();

  return (
    <Box sx={{ marginBottom: 6, paddingTop: 4 }}>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          flexDirection: { xs: 'column', md: 'row' },
          textAlign: { xs: 'center', md: 'left' },
        }}
      >
        <Typography
          variant="h4"
          component="h2"
          gutterBottom
          sx={{ color: theme.palette.primary.main, fontWeight: 'bold', fontSize: '2.5rem' }}
        >
          {titleTop}
        </Typography>
        <Typography
          variant="h4"
          component="h2"
          gutterBottom
          sx={{ fontWeight: 'bold', fontSize: '2.5rem', marginLeft: { md: 2 } }}
        >
          {title}
        </Typography>
      </Box>
      <Typography variant="body1" sx={{ fontSize: '1rem' }}>
        {info}
      </Typography>
    </Box>
  );
};

export default TitleAndInfo;
