import React, { useEffect, useState } from 'react';
import { Typography, Container, CircularProgress, Button, Grid, Box, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import axios from '../config/axiosConfig';
import { useAuth } from './AuthContext';
import { useNavigate } from 'react-router-dom';

function CurrentPlan() {
    const { user } = useAuth();
    const [currentPlan, setCurrentPlan] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const [upgradeInProgress,] = useState(false);
    // const [downgradeInProgress, setDowngradeInProgress] = useState(false);
    const [openCancelDialog, setOpenCancelDialog] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchCurrentPlan = async () => {
            if (user.plan.name !== 'Free') {
                try {
                    const response = await axios.get(`/api/stripe/currentplan/${user._id}`);
                    setCurrentPlan(response.data.plan);
                } catch (err) {
                    setError('Error fetching current plan.');
                    console.error('Error fetching current plan:', err);
                }
            } else {
                setCurrentPlan(user.plan);
            }
            setLoading(false);
        };

        fetchCurrentPlan();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user._id, user.plan.name]);

    const handleUpgrade = () => {
        if (currentPlan.name === 'Free') {
            navigate('/Pricing');
        } else {
            navigate('/upgrade');
        }
    };

    // const handleDowngrade = () => {
    //     navigate('/downgrade');
    // };

    const handleCancelSubscription = () => {
        setOpenCancelDialog(true);
    };

    const handleCloseCancelDialog = () => {
        setOpenCancelDialog(false);
    };

    const confirmCancelSubscription = async () => {
        try {
            await axios.post(`/api/stripe/cancel/${user._id}`);
            setOpenCancelDialog(false);
            window.location.reload();
        } catch (err) {
            console.error('Error canceling subscription:', err);
        }
    };

    const handleUpdatePayment = async () => {
        try {
            const response = await axios.post('/api/stripe/setup-payment');
            window.location.href = response.data.url;
        } catch (error) {
            console.error('Error updating payment method:', error);
        }
    };

    if (loading) {
        return <CircularProgress />;
    }

    return (
        <Container>
            {error && <Typography variant="body1" color="error">{error}</Typography>}
            {currentPlan ? (
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Box sx={{ padding: '16px' }}>
                            <Typography variant="h6">Plan: {currentPlan.name}</Typography>
                            {currentPlan.name !== 'Free' && (
                                currentPlan.cancelAtPeriodEnd ? (
                                    <Typography variant="body1">Subscription end date: {new Date(currentPlan.cancellationDate).toLocaleDateString()}</Typography>
                                ) : (
                                    <Typography variant="body1">Next billing date: {new Date(currentPlan.currentPeriodEnd * 1000).toLocaleDateString()}</Typography>
                                )
                            )}
                        </Box>
                    </Grid>
                    <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center', gap: 2, flexWrap: 'wrap' }}>
                        {currentPlan.name === 'Free' && (
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={handleUpgrade}
                                disabled={upgradeInProgress}
                            >
                                {upgradeInProgress ? 'Upgrading...' : 'Upgrade Plan'}
                            </Button>
                        )}
                        {/* Show "Change Plan" button for non-Premium yearly plans */}
                        {!(currentPlan.name === 'Premium' && currentPlan.interval === 'year') && currentPlan.name !== 'Free' && (
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={handleUpgrade}
                            >
                                Change Plan
                            </Button>
                        )}
                        {/* Update Payment Method button for non-free plans */}
                        {currentPlan.name !== 'Free' && (
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={handleUpdatePayment}
                            >
                                Update Payment Method
                            </Button>
                        )}
                        {currentPlan.name !== 'Free' && !currentPlan.cancelAtPeriodEnd && (
                            <Button
                                variant="contained"
                                color="secondary"
                                onClick={handleCancelSubscription}
                            >
                                Cancel Subscription
                            </Button>
                        )}
                    </Grid>
                </Grid>
            ) : (
                <Typography variant="body1">No current plan information available.</Typography>
            )}

            <Dialog open={openCancelDialog} onClose={handleCloseCancelDialog}>
                <DialogTitle>Cancel Subscription</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Are you sure? Please note that the cancellation will take effect at the end of your subscription period.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseCancelDialog} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={confirmCancelSubscription} color="primary">
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>
        </Container>
    );
}

export default CurrentPlan;