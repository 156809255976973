import React from 'react';
import { useLocation, useSearchParams, useNavigate  } from 'react-router-dom';
import { Alert, Container, Typography, Button, Box } from '@mui/material';

const Success = () => {
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const message = location.state?.message || searchParams.get('message') || 'Welcome to Market Rodeo!';

  return (
    <Container sx={{ mt: 6, mb: 15 }}>
    <Box textAlign="center">
      <Typography variant="h2" align="center" gutterBottom>
        Success
      </Typography>
      <Alert severity="success">{message}</Alert>

      <Typography variant="h6" sx={{ mt: 2 }}>
        <Button variant="contained" onClick={() => navigate('/')}>
          Start Here
        </Button>
      </Typography>
    </Box>
    </Container>
  );
};

export default Success;
