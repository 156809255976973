import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from './AuthContext';
import { useTheme, alpha } from '@mui/material/styles';
import {
  Box,
  Typography,
  Container,
  keyframes,
} from '@mui/material';
import { AutoAwesome as SparkleIcon } from '@mui/icons-material';
import axios from '../config/axiosConfig';

// Define animations
const pulse = keyframes`
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
`;

const sparkle = keyframes`
  0% {
    opacity: 0.4;
    transform: scale(0.8);
  }
  50% {
    opacity: 1;
    transform: scale(1.2);
  }
  100% {
    opacity: 0.4;
    transform: scale(0.8);
  }
`;

const PromotionBanner = () => {
  const theme = useTheme();
  const [promotions, setPromotions] = useState([]);
  const [currentPromotionIndex, setCurrentPromotionIndex] = useState(0);
  const { user } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchPromotions = async () => {
      try {
        const response = await axios.get('/api/promotions/active');
        setPromotions(response.data);
      } catch (error) {
        console.error('Error fetching promotions:', error);
      }
    };

    fetchPromotions();

    if (promotions.length > 1) {
      const interval = setInterval(() => {
        setCurrentPromotionIndex((prevIndex) =>
          prevIndex === promotions.length - 1 ? 0 : prevIndex + 1
        );
      }, 5000);

      return () => clearInterval(interval);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClick = () => {
    // If no user, go to pricing
    // If user exists but has Free plan, go to pricing
    // If user exists and has paid plan (Basic or Premium), go to upgrade
    navigate(!user || user.plan.name === 'Free' ? '/pricing' : '/upgrade');
};

  if (promotions.length === 0) {
    return null;
  }

  const currentPromotion = promotions[currentPromotionIndex];

  return (
    <Box 
      onClick={handleClick}
      sx={{
        mb: 2,
        background: `linear-gradient(135deg, 
          ${theme.palette.primary.main} 0%, 
          ${alpha(theme.palette.primary.dark, 0.95)} 100%)`,
        cursor: 'pointer',
        transition: 'all 0.3s ease-in-out',
        position: 'relative',
        overflow: 'hidden',
        '&:hover': {
          background: `linear-gradient(135deg, 
            ${theme.palette.primary.dark} 0%, 
            ${alpha(theme.palette.primary.main, 0.95)} 100%)`,
        },
        '&::before': {
          content: '""',
          position: 'absolute',
          top: 0,
          left: '-100%',
          width: '200%',
          height: '100%',
          background: `linear-gradient(to right, 
            transparent 0%,
            ${alpha(theme.palette.common.white, 0.2)} 50%,
            transparent 100%)`,
          animation: `${pulse} 2s infinite`,
        }
      }}
    >
      <Container maxWidth="xl">
        <Box
          sx={{
            py: 2,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            gap: 2,
          }}
        >
          <SparkleIcon
            sx={{
              color: theme.palette.common.white,
              animation: `${sparkle} 1.5s infinite`,
              fontSize: 28,
            }}
          />
          
          <Typography
            variant="h6"
            sx={{
              color: theme.palette.common.white,
              fontWeight: 600,
              textAlign: 'center',
              display: 'flex',
              alignItems: 'center',
              gap: 1,
              textShadow: '0 2px 4px rgba(0,0,0,0.2)',
              animation: `${pulse} 2s infinite`,
            }}
          >
            {currentPromotion.name} - {currentPromotion.description}!
            <Box
              component="span"
              sx={{
                ml: 2,
                textDecoration: 'underline',
                fontWeight: 700,
                color: alpha(theme.palette.common.white, 0.9),
              }}
            >
              {user ? 'Upgrade now' : 'Sign up now'}
            </Box>
          </Typography>

          <SparkleIcon
            sx={{
              color: theme.palette.common.white,
              animation: `${sparkle} 1.5s infinite`,
              animationDelay: '0.75s',
              fontSize: 28,
            }}
          />
        </Box>

        {promotions.length > 1 && (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              gap: 0.5,
              pb: 1,
            }}
          >
            {promotions.map((_, index) => (
              <Box
                key={index}
                sx={{
                  width: 8,
                  height: 8,
                  borderRadius: '50%',
                  bgcolor: index === currentPromotionIndex
                    ? 'common.white'
                    : alpha(theme.palette.common.white, 0.5),
                  transition: 'all 0.3s ease',
                }}
              />
            ))}
          </Box>
        )}
      </Container>
    </Box>
  );
};

export default PromotionBanner;