import React, { useState } from 'react';
import { useTheme } from '@mui/material/styles';
import {
    Box,
    Typography,
    List,
    ListItem,
    ListItemText,
    Grid,
    Container,
    Button
} from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';

const StockLists = () => {
    const theme = useTheme();
    const [showAllExchanges, setShowAllExchanges] = useState(false);

    const lists = [
        {
            title: 'Market Cap Groups',
            items: [
                { name: 'Mega-Cap Stocks', route: '/lists/market-cap/mega-cap' },
                { name: 'Large-Cap Stocks', route: '/lists/market-cap/large-cap' },
                { name: 'Mid-Cap Stocks', route: '/lists/market-cap/mid-cap' },
                { name: 'Small-Cap Stocks', route: '/lists/market-cap/small-cap' },
                { name: 'Micro-Cap Stocks', route: '/lists/market-cap/micro-cap' },
                { name: 'Nano-Cap Stocks', route: '/lists/market-cap/nano-cap' }
            ]
        },
        {
            title: 'Exchange Groups',
            items: [
                { name: 'NASDAQ', route: '/lists/exchange/NASDAQ' },
                { name: 'NYSE', route: '/lists/exchange/NYSE' },
                { name: 'AMEX', route: '/lists/exchange/AMEX' },
                { name: 'London Stock Exchange', route: '/lists/exchange/LSE' },
                { name: 'Hong Kong Stock Exchange', route: '/lists/exchange/HKSE' },
                { name: 'Tel Aviv Stock Exchange', route: '/lists/exchange/TLV' },
                { name: 'Shanghai Stock Exchange', route: '/lists/exchange/SHH' },
                { name: 'Euronext', route: '/lists/exchange/EURONEXT' },
                { name: 'Australian Stock Exchange', route: '/lists/exchange/ASX' },
                { name: 'Bombay Stock Exchange', route: '/lists/exchange/BSE' },
                { name: 'Brazil Stock Exchange', route: '/lists/exchange/SAO' },
                { name: 'XETRA', route: '/lists/exchange/XETRA' },
                { name: 'Johannesburg Stock Exchange', route: '/lists/exchange/JNB' },
                { name: 'Korea Exchange', route: '/lists/exchange/KOE' },
                { name: 'Shenzhen Stock Exchange', route: '/lists/exchange/SHZ' },
                { name: 'Swiss Stock Exchange', route: '/lists/exchange/SIX' },
                { name: 'Toronto Stock Exchange', route: '/lists/exchange/TSX' },
                { name: 'Amsterdam Stock Exchange', route: '/lists/exchange/AMS' },
                { name: 'Berlin Stock Exchange', route: '/lists/exchange/BER' },
                { name: 'Euronext Brussels', route: '/lists/exchange/BRU' },
                { name: 'Budapest Stock Exchange', route: '/lists/exchange/BUD' },
                { name: 'Buenos Aires Stock Exchange', route: '/lists/exchange/BUE' },
                { name: 'NASDAQ Copenhagen', route: '/lists/exchange/CPH' },
                { name: 'Dubai Financial Market', route: '/lists/exchange/DFM' },
                { name: 'Qatar Stock Exchange', route: '/lists/exchange/DOH' },
                { name: 'Dusseldorf Stock Exchange', route: '/lists/exchange/DUS' },
                { name: 'Hamburg Stock Exchange', route: '/lists/exchange/HAM' },
                { name: 'NASDAQ Helsinki', route: '/lists/exchange/HEL' },
                { name: 'ICE Futures', route: '/lists/exchange/ICE' },
                { name: 'Borsa Istanbul', route: '/lists/exchange/IST' },
                { name: 'Indonesia Stock Exchange', route: '/lists/exchange/JKT' },
                { name: 'Japan Exchange Group', route: '/lists/exchange/JPX' },
                { name: 'Bursa Malaysia', route: '/lists/exchange/KLS' },
                { name: 'Moscow Exchange', route: '/lists/exchange/MCX' },
                { name: 'Mexican Stock Exchange', route: '/lists/exchange/MEX' },
                { name: 'Milan Stock Exchange', route: '/lists/exchange/MIL' },
                { name: 'Munich Stock Exchange', route: '/lists/exchange/MUN' },
                { name: 'National Stock Exchange of India', route: '/lists/exchange/NSE' },
                { name: 'New Zealand Stock Exchange', route: '/lists/exchange/NZE' },
                { name: 'Oslo Stock Exchange', route: '/lists/exchange/OSL' },
                { name: 'OTC Markets Pink', route: '/lists/exchange/PNK' },
                { name: 'Prague Stock Exchange', route: '/lists/exchange/PRA' },
                { name: 'Saudi Stock Exchange', route: '/lists/exchange/SAU' },
                { name: 'Stock Exchange of Thailand', route: '/lists/exchange/SET' },
                { name: 'Stockholm Stock Exchange', route: '/lists/exchange/STO' },
                { name: 'Stuttgart Stock Exchange', route: '/lists/exchange/STU' },
                { name: 'Taiwan Stock Exchange', route: '/lists/exchange/TAI' },
                { name: 'Taipei Exchange', route: '/lists/exchange/TWO' },
                { name: 'Vienna Stock Exchange', route: '/lists/exchange/VIE' },
                { name: 'Warsaw Stock Exchange', route: '/lists/exchange/WSE' },
                { name: 'Bolsa de Madrid', route: '/lists/exchange/BME' },
                { name: 'CBOE Europe', route: '/lists/exchange/DXE' }
            ],
            initialDisplay: 6
        },
        {
            title: 'Index Groups',
            items: [
                { name: 'S&P 500', route: '/lists/index/sp500' },
                { name: 'NASDAQ 100', route: '/lists/index/nasdaq' },
                { name: 'Dow Jones', route: '/lists/index/dowjones' },
            ]
        },
        {
            title: 'Sector Groups',
            items: [
                { name: 'Energy', route: '/lists/sector/energy' },
                { name: 'Financial Services', route: '/lists/sector/financial-services' },
                { name: 'Healthcare', route: '/lists/sector/healthcare' },
                { name: 'Industrials', route: '/lists/sector/industrials' },
                { name: 'Materials', route: '/lists/sector/materials' },
                { name: 'Consumer Staples', route: '/lists/sector/consumer-staples' },
                { name: 'Consumer Discretionary', route: '/lists/sector/consumer-discretionary' },
                { name: 'Communication Services', route: '/lists/sector/communication-services' },
                { name: 'Real Estate', route: '/lists/sector/real-estate' },
                { name: 'Technology', route: '/lists/sector/technology' },
                { name: 'Utilities', route: '/lists/sector/utilities' }
            ]
        },
        {
            title: 'Other Lists',
            //items: ['Magnificent Seven', 'Dividend Kings', 'Dividend Aristocrats', 'All REITs', 'Delisted Companies']
            items: [
                { name: 'Delisted Companies', route: '/lists/delisted' },
            ]
        }
    ];

    const listItemStyles = {
        borderRadius: '8px',
        transition: 'all 0.2s ease-in-out',
        '&:hover': {
            backgroundColor: theme.palette.mode === 'dark'
                ? 'rgba(255, 255, 255, 0.08)'
                : 'rgba(0, 0, 0, 0.04)',
            transform: 'translateX(4px)',
            '& .MuiListItemText-primary': {
                color: theme.palette.primary.main,
            },
        },
    };

    const linkStyles = {
        color: 'inherit',
        textDecoration: 'none',
        width: '100%',
        display: 'block',
        padding: '8px 16px',
        borderRadius: 'inherit',
        transition: 'color 0.2s ease-in-out',
    };

    const renderListItems = (items, isExchangeGroup = false) => {
        let displayedItems = items;
        if (isExchangeGroup && !showAllExchanges) {
            displayedItems = items.slice(0, 6);
        }

        // For the left column, just take the first half
        const leftColumnItems = displayedItems.slice(0, Math.ceil(displayedItems.length / 2));
        // For the right column, take the second half
        const rightColumnItems = displayedItems.slice(Math.ceil(displayedItems.length / 2));

        // Then add the "Show More" button at the end of rightColumnItems list
        if (isExchangeGroup && !showAllExchanges) {
            rightColumnItems.push({
                isButton: true,
                name: 'SHOW MORE EXCHANGES'
            });
        }

        return (
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <List dense>
                        {leftColumnItems.map((item, index) => (
                            <ListItem
                                key={index}
                                sx={listItemStyles}
                                disablePadding
                            >
                                <RouterLink
                                    to={item.route}
                                    style={linkStyles}
                                >
                                    <ListItemText
                                        primary={item.name}
                                        primaryTypographyProps={{
                                            sx: {
                                                fontSize: '1rem',
                                                transition: 'color 0.2s ease-in-out',
                                            }
                                        }}
                                    />
                                </RouterLink>
                            </ListItem>
                        ))}
                    </List>
                </Grid>
                <Grid item xs={6}>
                    <List dense>
                        {rightColumnItems.map((item, index) => (
                            item.isButton ? (
                                <Button 
                                    key={index}
                                    onClick={() => setShowAllExchanges(true)}
                                    sx={{ 
                                        color: theme.palette.primary.main,
                                        pl: 2,
                                        mt: 1
                                    }}
                                >
                                    {item.name}
                                </Button>
                            ) : (
                            <ListItem
                                key={index}
                                sx={listItemStyles}
                                disablePadding
                            >
                                <RouterLink
                                    to={item.route}
                                    style={linkStyles}
                                >
                                    <ListItemText
                                        primary={item.name}
                                        primaryTypographyProps={{
                                            sx: {
                                                fontSize: '1rem',
                                                transition: 'color 0.2s ease-in-out',
                                            }
                                        }}
                                    />
                                </RouterLink>
                            </ListItem>
                        )
                        ))}
                    </List>
                </Grid>
            </Grid>
        );
    };

    return (
        <Box sx={{ bgcolor: theme.palette.background.default, color: theme.palette.text.primary, py: 4 }}>
            <Container maxWidth="lg">
                <Typography variant="h4" component="h1" gutterBottom sx={{ mb: 3, fontWeight: 'bold' }}>
                    Stock Lists
                </Typography>
                {lists.map((list, index) => (
                    <Box key={index} mb={4}>
                        <Typography variant="h6" component="h2" sx={{ color: theme.palette.primary.main, mb: 2, fontWeight: 'bold' }}>
                            {list.title}
                        </Typography>
                        {renderListItems(list.items, list.title === 'Exchange Groups')}
                    </Box>
                ))}
            </Container>
        </Box>
    );
};

export default StockLists;