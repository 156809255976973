import React from 'react';
import { Container, Box, Grid, Typography, Tooltip } from '@mui/material';
import CircularProgressBar from './CircularProgressBar';
import ContentAccessControl from './ContentAccessControl';
import { useAuth } from './AuthContext';

const CompanyRating = ({ data: companyRatingData }) => {
    const { user } = useAuth();

    if (!companyRatingData) {
        return (
            <Container>
                <Box display="flex" justifyContent="center" alignItems="center" minHeight="200px">
                    <Typography variant="h6" color="textSecondary">
                        No rating data available
                    </Typography>
                </Box>
            </Container>
        );
    }

    return (
        <Container>
            <Box my={4}>
                <Grid container spacing={2} alignItems="center" justifyContent="center">
                    <Grid item xs={12}>
                        <Grid container spacing={2} justifyContent="center">
                            <Grid item xs={12} sm={4}>
                                <ContentAccessControl
                                    hasAccess={user.plan.name === 'Premium' || user.plan.name === 'Basic' || user.plan.name === 'Free'}
                                    upgradeLink="/upgrade"
                                >
                                    <Tooltip title="Company Rating">
                                        <Box display="flex" justifyContent="center" alignItems="center" height="100%">
                                            <CircularProgressBar
                                                header='Company Rating'
                                                score={companyRatingData.ratingScore}
                                                recommendation={companyRatingData.rating + ' ' + companyRatingData.ratingRecommendation}
                                            />
                                        </Box>
                                    </Tooltip>
                                </ContentAccessControl>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <ContentAccessControl
                                    hasAccess={user.plan.name === 'Premium' || user.plan.name === 'Basic'}
                                    upgradeLink="/upgrade"
                                >
                                    <Tooltip title="Discounted Cash Flow">
                                        <Box display="flex" justifyContent="center" alignItems="center" height="100%">
                                            <CircularProgressBar
                                                header='DCF'
                                                score={companyRatingData.ratingDetailsDCFScore}
                                                recommendation={companyRatingData.ratingDetailsDCFRecommendation}
                                            />
                                        </Box>
                                    </Tooltip>
                                </ContentAccessControl>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <ContentAccessControl
                                    hasAccess={user.plan.name === 'Premium' || user.plan.name === 'Basic'}
                                    upgradeLink="/upgrade"
                                >
                                    <Tooltip title="Return on Equity">
                                        <Box display="flex" justifyContent="center" alignItems="center" height="100%">
                                            <CircularProgressBar
                                                header='ROE'
                                                score={companyRatingData.ratingDetailsROEScore}
                                                recommendation={companyRatingData.ratingDetailsROERecommendation}
                                            />
                                        </Box>
                                    </Tooltip>
                                </ContentAccessControl>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <ContentAccessControl
                                    hasAccess={user.plan.name === 'Premium' || user.plan.name === 'Basic'}
                                    upgradeLink="/upgrade"
                                >
                                    <Tooltip title="Return on Assets">
                                        <Box display="flex" justifyContent="center" alignItems="center" height="100%">
                                            <CircularProgressBar
                                                header='ROA'
                                                score={companyRatingData.ratingDetailsROAScore}
                                                recommendation={companyRatingData.ratingDetailsROARecommendation}
                                            />
                                        </Box>
                                    </Tooltip>
                                </ContentAccessControl>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <ContentAccessControl
                                    hasAccess={user.plan.name === 'Premium' || user.plan.name === 'Basic'}
                                    upgradeLink="/upgrade"
                                >
                                    <Tooltip title="Debt to Equity">
                                        <Box display="flex" justifyContent="center" alignItems="center" height="100%">
                                            <CircularProgressBar
                                                header='DE'
                                                score={companyRatingData.ratingDetailsDEScore}
                                                recommendation={companyRatingData.ratingDetailsDERecommendation}
                                            />
                                        </Box>
                                    </Tooltip>
                                </ContentAccessControl>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <ContentAccessControl
                                    hasAccess={user.plan.name === 'Premium'}
                                    upgradeLink="/upgrade"
                                >
                                    <Tooltip title="Price to Earnings">
                                        <Box display="flex" justifyContent="center" alignItems="center" height="100%">
                                            <CircularProgressBar
                                                header='PE'
                                                score={companyRatingData.ratingDetailsPEScore}
                                                recommendation={companyRatingData.ratingDetailsPERecommendation}
                                            />
                                        </Box>
                                    </Tooltip>
                                </ContentAccessControl>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <ContentAccessControl
                                    hasAccess={user.plan.name === 'Premium'}
                                    upgradeLink="/upgrade"
                                >
                                    <Tooltip title="Price to Book">
                                        <Box display="flex" justifyContent="center" alignItems="center" height="100%">
                                            <CircularProgressBar
                                                header='PB'
                                                score={companyRatingData.ratingDetailsPBScore}
                                                recommendation={companyRatingData.ratingDetailsPBRecommendation}
                                            />
                                        </Box>
                                    </Tooltip>
                                </ContentAccessControl>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
        </Container>
    );
};

export default CompanyRating;