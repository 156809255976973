import React, { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { useAuth } from './AuthContext';
import TitleAndInfo from './TitleAndInfo';
import { formatNumber } from '../utils/numberUtils';
import axios from '../config/axiosConfig';
import {
  Grid,
  Container,
  CircularProgress,
  Box
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { exportToCsv } from '../utils/exportToCsv';
import FilterSystem from './FilterSystem';

const baseUrl = window.location.origin;
const titleTop = "Stock Screener: "
const title = "Find Stocks that Meet Your Investment Criteria";
const info = "Discover stocks that align with your investment strategy using our Stock Screener. This powerful tool allows you to search for stocks based on various criteria such as market cap, price, volume, beta, sector, and country. Tailor your search to find the perfect investment opportunities, make informed decisions, and enhance your portfolio with the Stock Screener. Ideal for investors looking to customize their stock selection process and achieve their financial goals.";

const StockCustomScreener = () => {
  const { user } = useAuth();
  const theme = useTheme();
  const [, setFormData] = useState({
    marketCapMoreThan: '',
    marketCapLowerThan: '',
    priceMoreThan: '',
    priceLowerThan: '',
    betaMoreThan: '',
    betaLowerThan: '',
    volumeMoreThan: '',
    volumeLowerThan: '',
    dividendMoreThan: '',
    dividendLowerThan: '',
    isEtf: false,
    isFund: false,
    isActivelyTrading: false,
    sector: '',
    industry: '',
    country: '',
    exchange: '',
  });

  const [loading, setLoading] = useState(false);
  const [stocks, setStocks] = useState([]);

  const handleSubmit = async (filterData) => {
    setStocks([]);
    setLoading(true);

    try {
      const response = await axios.post('/api/stocks/customscreener', filterData);
      setStocks(response.data.data);
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleExport = () => {
    // Call the exportToCsv utility
    exportToCsv('stock_screener.csv', columns, stocks);
  };

  const handleSearch = (filterData) => {
    // Remove empty values
    const cleanedData = Object.entries(filterData).reduce((acc, [key, value]) => {
      if (value !== '' && value !== null && value !== undefined) {
        acc[key] = value;
      }
      return acc;
    }, {});

    handleSubmit(cleanedData);
  };

  const handleClear = () => {
    setFormData({
      marketCapMoreThan: '',
      marketCapLowerThan: '',
      priceMoreThan: '',
      priceLowerThan: '',
      betaMoreThan: '',
      betaLowerThan: '',
      volumeMoreThan: '',
      volumeLowerThan: '',
      dividendMoreThan: '',
      dividendLowerThan: '',
      isEtf: false,
      isFund: false,
      sector: '',
      industry: '',
      country: '',
      exchange: '',
    });
    setStocks([]);
  };


  const columns = [
    {
      field: 'symbol',
      headerName: 'Company',
      minWidth: 200,
      flex: 1,
      renderCell: (params) => (
        <div>
          <RouterLink
            to={`${baseUrl}/asset/${params.row.symbol}`}
            style={{ color: theme.palette.primary.main, textDecoration: 'none', fontWeight: 'bold', }}
          >
            {params.row.symbol}
          </RouterLink>
          <div style={{ fontSize: '0.9em', color: '#888', fontWeight: 'bold', }}>{params.row.companyName}</div>
        </div>
      ),
      align: 'left', // Left align for Company column
      headerAlign: 'left', // Left align the header for Company column
    },
    { field: 'price', headerName: 'Price', flex: 1, minWidth: 120, align: 'right', headerAlign: 'right' },
    {
      field: 'marketCap',
      headerName: 'Market Cap',
      flex: 1.2,
      minWidth: 150,
      align: 'right',
      headerAlign: 'right',
      renderCell: (params) => formatNumber(params.value), // Apply the number formatting
    },
    { field: 'sector', headerName: 'Sector', flex: 1, minWidth: 150 },
    { field: 'industry', headerName: 'Industry', flex: 1, minWidth: 150 },
    { field: 'beta', headerName: 'Beta', flex: 1, minWidth: 100, align: 'right', headerAlign: 'right' },
    { field: 'lastAnnualDividend', headerName: 'Last Annual Dividend', flex: 1.2, minWidth: 150, align: 'right', headerAlign: 'right' },
    { field: 'volume', headerName: 'Volume', flex: 1, minWidth: 120, align: 'right', headerAlign: 'right' },
    //{/*  { field: 'exchange', headerName: 'Exchange', flex: 1, minWidth: 120 }, */},
    { field: 'exchangeShortName', headerName: 'Exchange', flex: 1, minWidth: 120 },
    { field: 'country', headerName: 'Country', flex: 1, minWidth: 120 },
    { field: 'isEtf', headerName: 'ETF', flex: 0.8, minWidth: 100, renderCell: (params) => (params.value ? 'Yes' : 'No') },
    { field: 'isFund', headerName: 'Fund', flex: 0.8, minWidth: 100, renderCell: (params) => (params.value ? 'Yes' : 'No') },
    //  {/* { field: 'isActivelyTrading', headerName: 'Is Actively Trading', flex: 1, minWidth: 150, renderCell: (params) => (params.value ? 'Yes' : 'No') }, */}
  ];

  return (
    <div>
      <Container maxWidth="xl" sx={{ mt: 4 }}>
        <TitleAndInfo titleTop={titleTop} title={title} info={info} />

        <FilterSystem
          onSearch={handleSearch}
          onClear={handleClear}
          onExport={handleExport}
          showExport={user.plan.name === 'Premium' && stocks.length > 0}
        />


      </Container>


      {loading && (
        <Box sx={{ padding: 2, borderRadius: '5px' }}>
          <Grid container justifyContent="center">
            <CircularProgress />
          </Grid>
        </Box>
      )}

      {stocks.length > 0 && (
        <Container maxWidth="xxl" sx={{ mt: 4 }}>
          <DataGrid
            rows={stocks}
            columns={columns}
            getRowId={(row) => row.symbol}
            disableColumnMenu
            pagination
            paginationPosition="middle"
            initialState={{
              pagination: { paginationModel: { pageSize: 10 } },
            }}
            pageSizeOptions={[5, 10, 25]}
            sx={{
              '& .MuiDataGrid-cell': {
                fontSize: '1rem', // Adjust the font size here
              },
              '& .MuiDataGrid-columnHeaders': {
                fontSize: '0.8rem', // Adjust the font size for headers
              },
            }}
          />
        </Container>
      )}
    </div>
  );
};

export default StockCustomScreener;
