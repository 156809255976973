import React from 'react';
import { Container, Typography, Box, Paper } from '@mui/material';

const TermsAndConditions = () => {
    return (
        <Container maxWidth="md">
            <Paper
                elevation={3}
                sx={{
                    padding: '20px',
                    marginTop: '20px',
                    marginBottom: '20px'
                }}
            >
                <Typography variant="h4" gutterBottom>
                    Terms and Conditions
                </Typography>
                <Typography variant="subtitle1" gutterBottom>
                    Last Revised: 11/20/2024
                </Typography>

                <Box mt={3}>
                    <Typography variant="h5" gutterBottom>
                        1. Welcome
                    </Typography>
                    <Typography variant="body1" paragraph>
                        Welcome to Market Rodeo! We appreciate your choice to use our services.
                    </Typography>
                </Box>

                <Box mt={3}>
                    <Typography variant="h5" gutterBottom>
                        2. Service Overview
                    </Typography>
                    <Typography variant="body1" paragraph>
                        Market Rodeo provides users with stock data, market prices, analysis, custom stock screeners, and cryptocurrency information to help identify investment opportunities. You acknowledge that you are exclusively accountable for all the investment decisions you make using Market Rodeo.
                    </Typography>
                </Box>

                <Box mt={3}>
                    <Typography variant="h5" gutterBottom>
                        3. Adherence to Terms
                    </Typography>
                    <Typography variant="body1" paragraph>
                        By engaging with our service, you consent to be bound by these Terms and Conditions. Should you disagree with these terms, we kindly ask that you refrain from using our services.
                    </Typography>
                </Box>

                <Box mt={3}>
                    <Typography variant="h5" gutterBottom>
                        4. Payments and Subscription
                    </Typography>
                    <Typography variant="body1" paragraph>
                        All financial transactions for Market Rodeo services are managed through Stripe. Your use of our service implies your agreement to comply with the terms and conditions set forth by Stripe. Subscription terms are automatically renewed unless canceled before the renewal date. We reserve the right to modify pricing with 30 days notice. All sales are final and no refunds will be issued under any circumstances. Failed payments may result in immediate service interruption or account suspension. Users are responsible for ensuring their payment information is current and valid.
                    </Typography>
                </Box>

                <Box mt={3}>
                    <Typography variant="h5" gutterBottom>
                        5. Ownership of Intellectual Assets
                    </Typography>
                    <Typography variant="body1" paragraph>
                        Unless explicitly specified, the Service constitutes our proprietary asset, including all source code, databases, functions, software, website aesthetics, audio, video, text, photographs, and graphics (collectively referred to as the "Content"). Additionally, the trademarks, service marks, and logos within the Service (the "Marks") are either owned, controlled by us, or licensed to us. These assets are protected by copyright, trademark laws, and other intellectual property rights, both domestically and internationally.
                    </Typography>
                </Box>

                <Box mt={3}>
                    <Typography variant="h5" gutterBottom>
                        6. Responsibilities of Users
                    </Typography>
                    <Typography variant="body1" paragraph>
                        Users are responsible for their own investment decisions. Market Rodeo does not provide financial advice. Users must ensure that their use of the services complies with applicable laws and regulations. Market Rodeo does not vouch for the authenticity, precision, or reliability of any information provided by users nor does it endorse any opinions expressed by users. You concur that any reliance on materials provided by other users is at your sole discretion.
                    </Typography>
                </Box>

                <Box mt={3}>
                    <Typography variant="h5" gutterBottom>
                        7. Liability Limitation
                    </Typography>
                    <Typography variant="body1" paragraph>
                        To the fullest extent permissible by prevailing law, Market Rodeo along with its affiliates, agents, directors, employees, suppliers, or licensors shall not be held accountable for any direct, indirect, punitive, incidental, special, consequential, or exemplary damages. This includes but is not limited to: damages for loss of profits, trading losses, investment losses, goodwill, usage, data, or other intangible losses. Our maximum liability is limited to the subscription fees paid in the previous 12 months. We are not liable for any third-party content or services linked to or integrated with our platform. You acknowledge and accept the inherent risks of market volatility and trading in financial markets.
                    </Typography>
                </Box>

                <Box mt={3}>
                    <Typography variant="h5" gutterBottom>
                        8. Account Termination
                    </Typography>
                    <Typography variant="body1" paragraph>
                        We reserve the right to terminate or suspend your account forthwith, sans prior notification or liability, for any reason whatsoever, inclusive but not limited to a breach of the Terms. Upon termination, your privilege to use the Service will be revoked immediately.
                    </Typography>
                </Box>

                <Box mt={3}>
                    <Typography variant="h5" gutterBottom>
                        9. Jurisdiction
                    </Typography>
                    <Typography variant="body1" paragraph>
                        These Terms and Conditions are governed by applicable laws. Any claims arising from or related to your use of our site will be handled exclusively by the appropriate courts. However, we reserve the right to take legal action against you for breaches of these conditions in your country of residence or any other relevant jurisdiction.
                    </Typography>
                </Box>

                <Box mt={3}>
                    <Typography variant="h5" gutterBottom>
                        10. Changes to Terms
                    </Typography>
                    <Typography variant="body1" paragraph>
                        Market Rodeo reserves the right to modify these Terms and Conditions at any time. Users will be notified of any changes, and continued use of the services constitutes acceptance of the new terms.
                    </Typography>
                </Box>

                <Box mt={3}>
                    <Typography variant="h5" gutterBottom>
                        11. Contact Information
                    </Typography>
                    <Typography variant="body1" paragraph>
                        Should you have any inquiries regarding these Terms and Conditions, feel free to get in touch with us at support@marketrodeo.com.
                    </Typography>
                </Box>

                <Box mt={3}>
                    <Typography variant="h5" gutterBottom>
                        12. Data Usage and Privacy
                    </Typography>
                    <Typography variant="body1" paragraph>
                        By using our service, you acknowledge and agree that we collect and process your personal data in accordance with our Privacy Policy. This includes but is not limited to usage data, account information, and transaction history. We implement appropriate technical and organizational measures to protect your data, but we cannot guarantee absolute security. You grant us permission to use anonymized data for improving our services and conducting market analysis.
                    </Typography>
                </Box>

                <Box mt={3}>
                    <Typography variant="h5" gutterBottom>
                        13. Financial Information Disclaimer
                    </Typography>
                    <Typography variant="body1" paragraph>
                        All content provided through Market Rodeo is for informational purposes only and should not be construed as financial, investment, legal, or tax advice. The information we provide is obtained from sources believed to be reliable, but we make no representations or warranties of any kind, express or implied, about the completeness, accuracy, reliability, suitability, or availability of the information.
                    </Typography>

                    <Typography variant="body1" paragraph>
                        Users must understand that our market data and financial information may be delayed, incomplete, or contain errors. This includes potential gaps, inconsistencies, or missing information for certain assets or time periods. The accuracy and completeness of data may vary depending on market conditions and data sources. Market data may be delayed, and technical issues may affect data availability.
                    </Typography>

                    <Typography variant="body1" paragraph>
                        Some data is sourced from third-party providers, and while we strive to work with reliable sources, we cannot guarantee the accuracy of data provided by external sources. Users are encouraged to verify critical information through multiple sources before making investment decisions.
                    </Typography>

                    <Typography variant="body1" paragraph>
                        Past performance is not indicative of future results. We strongly recommend consulting with qualified financial advisors before making any investment decisions. Any reliance you place on the information provided through our service is strictly at your own risk.
                    </Typography>

                    <Typography variant="body1" paragraph>
                        By using our service, you acknowledge and agree that Market Rodeo shall not be liable for any investment decisions or trading activities you undertake, regardless of the accuracy, completeness, or timeliness of the data provided. This includes but is not limited to any losses, damages, or costs arising from: market volatility, delayed data, system outages, technical issues, your interpretation of the data, or any other factors related to your investment decisions. You assume full responsibility for all your investment choices and acknowledge that using our service does not create any form of financial advisory relationship.
                    </Typography>
                </Box>

                <Box mt={3}>
                    <Typography variant="h5" gutterBottom>
                        14. API Usage and Service Limitations
                    </Typography>
                    <Typography variant="body1" paragraph>
                        We reserve the right to impose rate limits on data requests, API calls, and other service features. Users agree not to circumvent these limitations or attempt to access data through unauthorized means. We may modify, suspend, or discontinue any aspect of our service at any time without prior notice.
                    </Typography>
                </Box>

                <Box mt={3}>
                    <Typography variant="h5" gutterBottom>
                        15. Account Security
                    </Typography>
                    <Typography variant="body1" paragraph>
                        You are responsible for maintaining the confidentiality of your account credentials and for all activities that occur under your account. You must immediately notify us of any unauthorized use of your account or any other breach of security. We will not be liable for any loss that you may incur as a result of someone else using your account, either with or without your knowledge.
                    </Typography>
                </Box>

                <Box mt={3}>
                    <Typography variant="h5" gutterBottom>
                        16. Force Majeure
                    </Typography>
                    <Typography variant="body1" paragraph>
                        Market Rodeo shall not be liable for any failure or delay in performance due to circumstances beyond our reasonable control, including but not limited to acts of God, war, terrorism, pandemic, changes in laws or regulations, exchange or market rules, market volatility, server outages, network failures, or third-party service provider failures.
                    </Typography>
                </Box>

                <Box mt={3}>
                    <Typography variant="h5" gutterBottom>
                        17. Age Restriction
                    </Typography>
                    <Typography variant="body1" paragraph>
                        You must be at least 18 years old to use our services. By using Market Rodeo, you represent and warrant that you are at least 18 years of age.
                    </Typography>
                </Box>

                <Box mt={3}>
                    <Typography variant="h5" gutterBottom>
                        18. Class Action Waiver
                    </Typography>
                    <Typography variant="body1" paragraph>
                        All claims and disputes within the scope of these Terms must be litigated on an individual basis and not on a class basis. Claims of more than one customer or user cannot be arbitrated or litigated jointly or consolidated with those of any other customer or user.
                    </Typography>
                </Box>

                <Box mt={3}>
                    <Typography variant="h5" gutterBottom>
                        19. Export Control
                    </Typography>
                    <Typography variant="body1" paragraph>
                        You agree to comply with all applicable export and import control laws and regulations in your use of the Service, and, in particular, you will not utilize the Service to export or re-export data or software without proper licenses or permits.
                    </Typography>
                </Box>
            </Paper>
        </Container>
    );
};

export default TermsAndConditions;