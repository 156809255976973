const exchangeInfo = {
    'NASDAQ': {
        fullName: 'National Association of Securities Dealers Automated Quotations',
        description: 'The NASDAQ is an American stock exchange, the second-largest in the world by market capitalization. It is known for its technology and growth companies.'
    },
    'NYSE': {
        fullName: 'New York Stock Exchange',
        description: 'The NYSE is the world\'s largest stock exchange by market capitalization. It\'s known for its trading floor and iconic bell ringing ceremonies.'
    },
    'AMEX': {
        fullName: 'American Stock Exchange',
        description: 'Now part of the NYSE, the AMEX was once the third-largest stock exchange in the U.S. It\'s known for listing small to medium-sized companies.'
    },
    'XETRA': {
        fullName: 'Exchange Electronic Trading',
        description: 'Xetra is a German electronic trading system operated by the Frankfurt Stock Exchange. It\'s known for its efficiency and speed in executing trades.'
    },
    'TLV': {
        fullName: 'Tel Aviv Stock Exchange',
        description: 'The Tel Aviv Stock Exchange is Israel\'s only public stock exchange. It plays a significant role in the country\'s economy and is known for its technology sector.'
    },
    'ASX': {
        fullName: 'Australian Stock Exchange',
        description: 'One of the largest exchanges in the Asia-Pacific region, known for its mining and resources companies listings.'
    },
    'BSE': {
        fullName: 'Bombay Stock Exchange',
        description: 'Asia\'s oldest stock exchange and one of India\'s leading exchange groups. Known for its benchmark SENSEX index.'
    },
    'SAO': {
        fullName: 'Brazil Stock Exchange',
        description: 'The main securities trading center in Brazil, featuring prominent Latin American companies and a strong commodities sector.'
    },
    'FRA': {
        fullName: 'Frankfurt Stock Exchange',
        description: 'One of Europe\'s largest trading venues, known for its electronic trading system and international securities listings.'
    },
    'HKSE': {
        fullName: 'Hong Kong Stock Exchange',
        description: 'A major Asian financial hub, particularly important for Chinese company listings and international investment in Asia.'
    },
    'JNB': {
        fullName: 'Johannesburg Stock Exchange',
        description: 'Africa\'s largest stock exchange, known for its mining sector and role in the continent\'s economic development.'
    },
    'KOE': {
        fullName: 'Korea Exchange',
        description: 'South Korea\'s main securities exchange, known for its technology sector and robust trading infrastructure.'
    },
    'LSE': {
        fullName: 'London Stock Exchange',
        description: 'One of the world\'s oldest stock exchanges and Europe\'s largest, known for its international listings and financial innovation.'
    },
    'SYD': {
        fullName: 'Sydney Stock Exchange',
        description: 'An Australian securities exchange focused on providing listing and trading services for growth companies.'
    },
    'SHZ': {
        fullName: 'Shenzhen Stock Exchange',
        description: 'One of mainland China\'s major exchanges, known for its focus on smaller, growing enterprises and tech companies.'
    },
    'SIX': {
        fullName: 'Swiss Stock Exchange',
        description: 'Switzerland\'s principal stock exchange, known for its stability and strong presence in financial services sector.'
    },
    'TYO': {
        fullName: 'Tokyo Stock Exchange',
        description: 'Japan\'s largest stock exchange and one of Asia\'s most significant trading venues, known for its industrial and technology sectors.'
    },
    'TSX': {
        fullName: 'Toronto Stock Exchange',
        description: 'Canada\'s largest stock exchange, known for its resource and mining company listings.'
    },
    'ARCA': {
        fullName: 'NYSE Arca',
        description: 'An electronic securities exchange specializing in ETF trading and listings.'
    },
    'AMS': {
        fullName: 'Amsterdam Stock Exchange',
        description: 'Part of Euronext, known for its long history as one of the oldest exchanges in the world.'
    },
    'AQS': {
        fullName: 'Aquis Exchange',
        description: 'A pan-European exchange known for its subscription-based trading model and technology focus.'
    },
    'BER': {
        fullName: 'Berlin Stock Exchange',
        description: 'A regional German exchange known for its specialist trading and retail focus.'
    },
    'BRU': {
        fullName: 'Euronext Brussels',
        description: 'Belgium\'s primary stock exchange, part of the Euronext group, known for its diverse listing offerings.'
    },
    'BTS': {
        fullName: 'BitShares Exchange',
        description: 'A decentralized exchange platform operating 24/7 for digital asset trading.'
    },
    'BUD': {
        fullName: 'Budapest Stock Exchange',
        description: 'Hungary\'s principal stock exchange, central to the country\'s capital markets.'
    },
    'BUE': {
        fullName: 'Buenos Aires Stock Exchange',
        description: 'Argentina\'s main stock exchange, important for South American market access.'
    },
    'CAI': {
        fullName: 'Egyptian Stock Exchange',
        description: 'Egypt\'s primary securities market, one of the oldest exchanges in the Middle East.'
    },
    'CME': {
        fullName: 'Chicago Mercantile Exchange',
        description: 'The world\'s largest derivatives exchange, known for its futures and options trading.'
    },
    'CPH': {
        fullName: 'NASDAQ Copenhagen',
        description: 'Denmark\'s principal stock exchange, part of the NASDAQ Nordic group.'
    },
    'DFM': {
        fullName: 'Dubai Financial Market',
        description: 'The United Arab Emirates\' leading financial market, known for its Islamic financial products.'
    },
    'DOH': {
        fullName: 'Qatar Stock Exchange',
        description: 'Qatar\'s principal stock market, important for Gulf region investments.'
    },
    'DUS': {
        fullName: 'Dusseldorf Stock Exchange',
        description: 'A regional German exchange specializing in private investor services.'
    },
    'EURONEXT': {
        fullName: 'Euronext',
        description: 'A pan-European exchange operating markets in multiple European countries.'
    },
    'HAM': {
        fullName: 'Hamburg Stock Exchange',
        description: 'A regional German exchange with a focus on local and international securities.'
    },
    'HEL': {
        fullName: 'NASDAQ Helsinki',
        description: 'Finland\'s main stock exchange, part of the NASDAQ Nordic group.'
    },
    'ICE': {
        fullName: 'ICE Futures',
        description: 'A major derivatives exchange known for its energy and commodity futures.'
    },
    'ISE': {
        fullName: 'Irish Stock Exchange',
        description: 'Ireland\'s principal securities market, known for its international listings.'
    },
    'IST': {
        fullName: 'Borsa Istanbul',
        description: 'Turkey\'s sole exchange entity, combining former Istanbul exchanges.'
    },
    'JKT': {
        fullName: 'Indonesia Stock Exchange',
        description: 'Indonesia\'s main securities exchange, important for Southeast Asian markets.'
    },
    'JPX': {
        fullName: 'Japan Exchange Group',
        description: 'Japan\'s main exchange group, operating both Tokyo and Osaka exchanges.'
    },
    'KLS': {
        fullName: 'Bursa Malaysia',
        description: 'Malaysia\'s integrated exchange, known for its Islamic financial products.'
    },
    'MCX': {
        fullName: 'Moscow Exchange',
        description: 'Russia\'s largest exchange group, trading multiple asset classes.'
    },
    'MEX': {
        fullName: 'Mexican Stock Exchange',
        description: 'Mexico\'s principal securities exchange, important for Latin American markets.'
    },
    'MIL': {
        fullName: 'Milan Stock Exchange',
        description: 'Italy\'s main stock exchange, known for its industrial and luxury goods sectors.'
    },
    'MUN': {
        fullName: 'Munich Stock Exchange',
        description: 'A regional German exchange focusing on retail investors and local securities.'
    },
    'NSE': {
        fullName: 'National Stock Exchange of India',
        description: 'India\'s leading stock exchange, known for its technology and modern trading systems.'
    },
    'NZE': {
        fullName: 'New Zealand Stock Exchange',
        description: 'New Zealand\'s principal securities market, known for its dairy and agricultural sectors.'
    },
    'OSL': {
        fullName: 'Oslo Stock Exchange',
        description: 'Norway\'s principal stock exchange, known for its energy and shipping sectors.'
    },
    'PNK': {
        fullName: 'OTC Markets Pink',
        description: 'A U.S. market for trading over-the-counter stocks with varying levels of disclosure.'
    },
    'PRA': {
        fullName: 'Prague Stock Exchange',
        description: 'The Czech Republic\'s major stock exchange, central to Eastern European markets.'
    },
    'SAU': {
        fullName: 'Saudi Stock Exchange',
        description: 'Saudi Arabia\'s sole stock exchange, known as Tadawul, the largest in the Arab world.'
    },
    'SET': {
        fullName: 'Stock Exchange of Thailand',
        description: 'Thailand\'s main stock exchange, important for Southeast Asian investments.'
    },
    'SHH': {
        fullName: 'Shanghai Stock Exchange',
        description: 'One of mainland China\'s two main exchanges, known for larger company listings.'
    },
    'STO': {
        fullName: 'Stockholm Stock Exchange',
        description: 'Sweden\'s primary stock exchange, part of the NASDAQ Nordic group.'
    },
    'STU': {
        fullName: 'Stuttgart Stock Exchange',
        description: 'Germany\'s second-largest exchange, known for retail investor focus.'
    },
    'TAI': {
        fullName: 'Taiwan Stock Exchange',
        description: 'Taiwan\'s principal exchange, known for its technology sector listings.'
    },
    'TWO': {
        fullName: 'Taipei Exchange',
        description: 'Taiwan\'s over-the-counter market, focusing on smaller companies and startups.'
    },
    'VIE': {
        fullName: 'Vienna Stock Exchange',
        description: 'Austria\'s principal stock exchange, important for Central European markets.'
    },
    'WSE': {
        fullName: 'Warsaw Stock Exchange',
        description: 'Poland\'s principal stock exchange, largest in Central and Eastern Europe.'
    },
    'BME': {
        fullName: 'Bolsa de Madrid',
        description: 'Spain\'s largest stock exchange, known for its IBEX 35 index and diverse sectors.'
    },
    'DXE': {
        fullName: 'CBOE Europe',
        description: 'A pan-European exchange owned by CBOE Global Markets, focusing on equities trading.'
    },
    'RIS': {
        fullName: 'Riga Stock Exchange',
        description: 'Latvia\'s principal stock exchange, part of the NASDAQ Baltic market.'
    }
};

export default exchangeInfo;