import React, { useState, useEffect } from 'react';
import { Typography, Box } from '@mui/material';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import Marquee from 'react-fast-marquee';
import axios from '../config/axiosConfig';  // Import your axios configuration

const SectorGrid = () => {
    const [data, setData] = useState([]);

    const fetchData = async () => {
        try {
            const response = await axios.get('/api/sectorPerformance');  // Adjust the endpoint if needed
            setData(response.data.data);
        } catch (error) {
            console.error('Error fetching sector data:', error);
        }
    };

    useEffect(() => {
        fetchData();  // Initial data fetch

        const intervalId = setInterval(() => {
            fetchData();  // Fetch data every 5 minutes
        }, 5 * 60 * 1000);  // 5 minutes in milliseconds

        return () => clearInterval(intervalId);  // Clean up interval on component unmount
    }, []);

    return (
        <Box
            sx={{
                display: 'flex',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
            }}
        >
            <Marquee
                velocity={10}  // Adjust the speed of scrolling
                direction="left"  // Specify the direction
                resetAfterTries={200}  // Optional: to reset after long duration
            >
                {data.map((item, index) => {
                    const isPositive = parseFloat(item.changesPercentage) > 0;
                    return (
                        <Box
                            key={index}
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                marginRight: 2,
                                whiteSpace: 'nowrap',
                            }}
                        >
                            <Typography variant="body1" sx={{ marginRight: 1 }}>
                                {item.sector}{' '}
                                <Typography
                                    component="span"
                                    variant="body1"
                                    sx={{ color: isPositive ? 'green' : 'red' }}
                                >
                                    {item.changesPercentage}
                                </Typography>
                            </Typography>
                            {isPositive ? (
                                <ArrowUpwardIcon style={{ color: 'green' }} />
                            ) : (
                                <ArrowDownwardIcon style={{ color: 'red' }} />
                            )}
                            <Typography variant="body1" sx={{ marginLeft: 2 }}>|</Typography> {/* Separator */}
                        </Box>
                    );
                })}
            </Marquee>
        </Box>
    );
};

export default SectorGrid;
