import React from 'react';
import { Container, Grid, Paper, Typography } from '@mui/material';
import ProfileDetails from './ProfileDetails';
import AccountSettings from './AccountSettings';
import NotificationSettings from './NotificationSettings';
import CurrentPlan from './CurrentPlan';
import { useAuth } from './AuthContext';

const ProfilePage = () => {
    const { user } = useAuth();
    console.log(user)
    const isGoogleUser = user.isGoogleUser;

    const showAccountSettings = !isGoogleUser;
    const showNotificationSettings = true;
    const showCurrentPlan = true;

    return (
        <Container>
            <Typography variant="h4" gutterBottom sx={{ mt: 3, mb: 3 }}>
                User Profile
            </Typography>
            <Grid container spacing={3} alignItems="stretch">
                <Grid item xs={12} md={6}>
                    <Paper elevation={3} style={{ padding: '16px', display: 'flex', flexDirection: 'column', height: '100%' }}>
                        <ProfileDetails />
                    </Paper>
                </Grid>

                {showAccountSettings && (
                    <Grid item xs={12} md={6}>
                        <Paper elevation={3} style={{ padding: '16px', display: 'flex', flexDirection: 'column', height: '100%' }}>
                            <AccountSettings />
                        </Paper>
                    </Grid>
                )}

                {showNotificationSettings && (
                    <Grid item xs={12} md={6}>
                        <Paper elevation={3} style={{ padding: '16px', display: 'flex', flexDirection: 'column', height: '100%' }}>
                            <NotificationSettings />
                        </Paper>
                    </Grid>
                )}

                {showCurrentPlan && (
                    <Grid item xs={12} md={6}>
                        <Paper elevation={3} style={{ padding: '16px', display: 'flex', flexDirection: 'column', height: '100%' }}>
                            <Typography variant="h6" gutterBottom>Subscription Details</Typography>
                            <CurrentPlan />
                        </Paper>
                    </Grid>
                )}
            </Grid>
        </Container>
    );
};

export default ProfilePage;
