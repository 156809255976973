// currencySymbols.js
const currencySymbols = {
  USD: '$',      // US Dollar
  EUR: '€',      // Euro
  GBP: '£',      // British Pound
  GBp: '£',      // British Pound
  JPY: '¥',      // Japanese Yen
  AUD: 'A$',     // Australian Dollar
  CAD: 'C$',     // Canadian Dollar
  CHF: 'CHF',    // Swiss Franc
  CNY: '¥',      // Chinese Yuan
  INR: '₹',      // Indian Rupee
  NZD: 'NZ$',    // New Zealand Dollar
  TRY: '₺',      // Turkish Lira
  RUB: '₽',      // Russian Ruble
  ZAR: 'R',      // South African Rand
  ZAC: 'R',      // South African Rand
  ZAc: 'R',      // South African Rand
  MXN: 'MX$',      // Mexican Peso
  BRL: 'R$',     // Brazilian Real
  KRW: '₩',      // South Korean Won
  HKD: 'HK$',    // Hong Kong Dollar
  SGD: 'S$',     // Singapore Dollar
  NOK: 'kr',     // Norwegian Krone
  SEK: 'kr',     // Swedish Krona
  DKK: 'kr',     // Danish Krone
  PLN: 'zł',     // Polish Zloty
  THB: '฿',      // Thai Baht
  MYR: 'RM',     // Malaysian Ringgit
  IDR: 'Rp',     // Indonesian Rupiah
  CZK: 'Kč',     // Czech Koruna
  HUF: 'Ft',     // Hungarian Forint
  PHP: '₱',      // Philippine Peso
  ILS: '₪',      // Israeli New Shekel
  ILA: '₪',      // Israeli New Shekel
  AED: 'د.إ',    // UAE Dirham
  SAR: '﷼',      // Saudi Riyal
  PKR: '₨',      // Pakistani Rupee
  BDT: '৳',      // Bangladeshi Taka
  VND: '₫',      // Vietnamese Dong
  EGP: '£',      // Egyptian Pound
  NGN: '₦',      // Nigerian Naira
  COP: 'COL$',      // Colombian Peso
  ARS: 'AR$',      // Argentine Peso
  CLP: 'CL$',      // Chilean Peso
  PEN: 'S/',     // Peruvian Sol
  UAH: '₴',      // Ukrainian Hryvnia
  KZT: '₸',      // Kazakhstani Tenge
  MAD: 'د.م.',   // Moroccan Dirham
  QAR: '﷼',      // Qatari Riyal
  LKR: 'Rs',     // Sri Lankan Rupee
  BHD: '.د.ب',   // Bahraini Dinar
  KWD: 'د.ك',    // Kuwaiti Dinar
  OMR: 'ر.ع.',   // Omani Rial
  JOD: 'د.ا',    // Jordanian Dinar
  TWD: 'NT$',    // New Taiwan Dollar
  IQD: 'ع.د',    // Iraqi Dinar
  DOP: 'RD$',    // Dominican Peso
  UYU: '$U',     // Uruguayan Peso
  GHS: '₵',      // Ghanaian Cedi
  ETB: 'Br',     // Ethiopian Birr
  LBP: 'ل.ل',    // Lebanese Pound
  RSD: 'РСД',    // Serbian Dinar
  BGN: 'лв',     // Bulgarian Lev
  RON: 'lei',    // Romanian Leu
  ISK: 'kr',     // Icelandic Krona
  MNT: '₮',      // Mongolian Tugrik
  GEL: '₾',      // Georgian Lari
  ALL: 'L',      // Albanian Lek
  BAM: 'KM',     // Bosnian Convertible Marka
  MKD: 'ден',    // Macedonian Denar
  MDL: 'L',      // Moldovan Leu
  KES: 'KSh',    // Kenyan Shilling
  UGX: 'USh',    // Ugandan Shilling
  TZS: 'TSh',    // Tanzanian Shilling
  ZMW: 'ZK',     // Zambian Kwacha
  ANG: 'ƒ',      // Netherlands Antillean Guilder
  AWG: 'ƒ',      // Aruban Florin
  BBD: 'Bds$',   // Barbadian Dollar
  BZD: 'BZ$',    // Belize Dollar
  FJD: 'FJ$',    // Fijian Dollar
  GIP: '£',      // Gibraltar Pound
  GMD: 'D',      // Gambian Dalasi
  HTG: 'G',      // Haitian Gourde
  JMD: 'J$',     // Jamaican Dollar
  KYD: 'CI$',    // Cayman Islands Dollar
  LAK: '₭',      // Laotian Kip
  LSL: 'L',      // Lesotho Loti
  MGA: 'Ar',     // Malagasy Ariary
  MOP: 'MOP$',   // Macanese Pataca
  MVR: 'Rf',     // Maldivian Rufiyaa
  NAD: 'N$',     // Namibian Dollar
  PAB: 'B/.',    // Panamanian Balboa
  PGK: 'K',      // Papua New Guinean Kina
  SRD: 'SRD$',      // Surinamese Dollar
  SYP: '£',      // Syrian Pound
  TND: 'د.ت',    // Tunisian Dinar
  TOP: 'T$',     // Tongan Paʻanga
  VUV: 'VT',     // Vanuatu Vatu
  WST: 'WS$',    // Samoan Tala
  YER: '﷼',      // Yemeni Rial

  undefined: ''
};

module.exports = currencySymbols;
