import React, { useState, useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
import {
    Box,
    Typography,
    Container,
    CircularProgress,
    TextField
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import axios from '../config/axiosConfig';
import TitleAndInfo from './TitleAndInfo';

const DelistedCompanies = () => {
    const theme = useTheme();
    const [companies, setCompanies] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [searchValue, setSearchValue] = useState('');

    const columns = [
        {
            field: 'symbol',
            headerName: 'Symbol',
            flex: 1,
            minWidth: 120,
            renderCell: (params) => (
                <Typography
                    sx={{
                        color: theme.palette.primary.main,
                        fontWeight: 'bold',
                        fontSize: '1rem'
                    }}
                >
                    {params.value}
                </Typography>
            ),
        },
        {
            field: 'companyName',
            headerName: 'Company Name',
            flex: 2,
            minWidth: 200,
        },
        {
            field: 'exchange',
            headerName: 'Exchange',
            flex: 1,
            minWidth: 120,
        },
        {
            field: 'ipoDate',
            headerName: 'IPO Date',
            flex: 1,
            minWidth: 120,
            valueFormatter: (params) => 
                params.value ? new Date(params.value).toLocaleDateString() : 'N/A',
        },
        {
            field: 'delistedDate',
            headerName: 'Delisted Date',
            flex: 1,
            minWidth: 120,
            valueFormatter: (params) => 
                params.value ? new Date(params.value).toLocaleDateString() : 'N/A',
        },
    ];

    useEffect(() => {
        const fetchCompanies = async () => {
            setLoading(true);
            setError(null);
            try {
                const response = await axios.get('/api/delisted-companies');
                if (response.data && response.data.data) {
                    setCompanies(response.data.data);
                }
            } catch (error) {
                console.error('Error fetching delisted companies:', error);
                setError('Failed to fetch delisted companies. Please try again later.');
            } finally {
                setLoading(false);
            }
        };

        fetchCompanies();
    }, []);

    const handleSearchChange = (event) => {
        setSearchValue(event.target.value);
    };

    const filterCompanies = () => {
        return companies.filter((company) =>
            company.symbol.toLowerCase().includes(searchValue.toLowerCase()) ||
            company.companyName?.toLowerCase().includes(searchValue.toLowerCase())
        );
    };

    if (loading) {
        return (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                <CircularProgress />
            </Box>
        );
    }

    if (error) {
        return (
            <Box sx={{ textAlign: 'center', mt: 4 }}>
                <Typography color="error">{error}</Typography>
            </Box>
        );
    }

    return (
        <Box sx={{ bgcolor: theme.palette.background.default, color: theme.palette.text.primary, py: 4 }}>
            <Container maxWidth="xl">
                <TitleAndInfo
                    titleTop="Delisted Companies"
                    title="Companies Removed from Stock Exchanges"
                    info="This list shows companies that have been delisted from various stock exchanges. Companies can be delisted for various reasons including mergers, acquisitions, bankruptcy, or failure to meet listing requirements."
                />
                <TextField
                    label="Search by Symbol or Name"
                    variant="outlined"
                    value={searchValue}
                    onChange={handleSearchChange}
                    style={{ marginBottom: '20px' }}
                />
                <DataGrid
                    rows={filterCompanies()}
                    columns={columns}
                    getRowId={(row) => row.symbol}
                    disableColumnMenu
                    autoHeight
                    paginationPosition="middle"
                    initialState={{
                        pagination: { paginationModel: { pageSize: 10 } },
                        sorting: { sortModel: [{ field: 'delistedDate', sort: 'desc' }] },
                    }}
                    pageSizeOptions={[5, 10, 25, 50]}
                    sx={{
                        '& .MuiDataGrid-cell:focus': {
                            outline: 'none',
                        },
                    }}
                />
            </Container>
        </Box>
    );
};

export default DelistedCompanies;