import React from 'react';
import { Bar } from 'react-chartjs-2';
import 'chart.js/auto'; // Ensure Chart.js is loaded
import '../styles.css';

const BarChart = ({ data }) => {
    return (
        <div className="chart-container">
            <div className="chart-wrapper">
                <Bar
                    data={data}
                    options={{
                        responsive: true,
                        maintainAspectRatio: false, // Change to false for full responsiveness
                        plugins: {
                            legend: {
                                display: true, // Show legend if needed
                            },
                            datalabels: {
                                display: false, // Hide labels inside the chart segments
                            },
                            tooltip: {
                                enabled: true, // Enable tooltips
                                callbacks: {
                                    label: function(context) {
                                        // Customize the tooltip label as needed
                                        const label = context.dataset.label || '';
                                        const value = context.raw; // Get the value of the bar
                                        return `${label}: ${value}`; // Return the label and value
                                    }
                                }
                            },
                        },
                        scales: {
                            y: {
                                ticks: {
                                    beginAtZero: true,
                                    callback: function(value) { 
                                        if (value % 1 === 0) { 
                                            return value; 
                                        } 
                                    }
                                }
                            },

                        },
                        interaction: {
                            mode: 'index', // Display tooltip for the entire dataset at the hovered index
                            intersect: false, // Allow tooltips to show when hovering over the area of the bars
                        },
                    }}
                />
            </div>
        </div>
    );
};

export default BarChart;
