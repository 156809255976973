import React, { useState, useEffect, useRef } from 'react';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, LineElement, CategoryScale, LinearScale, PointElement, Title, Tooltip, Legend } from 'chart.js';
import zoomPlugin from 'chartjs-plugin-zoom';
import {
  CircularProgress,
  Paper,
  Button,
  Box,
  Typography,
  useTheme,
  useMediaQuery
} from '@mui/material';
import axios from '../config/axiosConfig';

ChartJS.register(LineElement, CategoryScale, LinearScale, PointElement, Title, Tooltip, Legend, zoomPlugin);

const timeframes = ['5D', '1M', '6M', 'YTD', '1Y', '5Y', 'MAX'];

const StockChart = ({ symbol }) => {
  const [chartData, setChartData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [selectedTimeframe, setSelectedTimeframe] = useState('YTD');
  const [error, setError] = useState(null);
  const [historicalData, setHistoricalData] = useState([]);
  const theme = useTheme();
  const isTablet = useMediaQuery('(max-width:960px)');
  const isMobile = useMediaQuery('(max-width:600px)');

  const chartRef = useRef(null);

  // Effect to fetch data when symbol changes
  useEffect(() => {
    setChartData(null);
    setLoading(true);
    setError(null);

    axios.get(`/api/historicaldata/${symbol}`)
      .then(response => {
        let data = response.data.data[0].historical;

        // Sort historical data by date (oldest to newest)
        data = data.sort((a, b) => new Date(a.date) - new Date(b.date));

        setHistoricalData(data);
        updateChartData(data, selectedTimeframe);
        setLoading(false);
      })
      .catch(error => {
        console.error('Error fetching chart data:', error);
        setError(error.message);
        setLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [symbol]); // Only re-run when symbol changes

  // Effect to update chart data when timeframe changes
  useEffect(() => {
    if (historicalData.length) {
      updateChartData(historicalData, selectedTimeframe);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTimeframe, historicalData]);

  const updateChartData = (data, timeframe) => {
    const filteredData = filterDataByTimeframe(data, timeframe);
    const labels = filteredData.map(entry => entry.label);
    const chartData = filteredData.map(entry => entry.close);

    setChartData({
      labels,
      datasets: [{
        label: 'Stock Price',
        data: chartData,
        borderColor: 'rgba(75,192,192,1)',
        borderWidth: 2,
        datalabels: {
          display: false
        }
      }],
    });
  };

  const filterDataByTimeframe = (data, timeframe) => {
    const now = new Date();
    let filteredData;

    switch (timeframe) {
      case '1D':
        filteredData = data.slice(-1); // last day
        break;
      case '5D':
        filteredData = data.slice(-5); // last 5 days
        break;
      case '1M':
        const oneMonthAgo = new Date(now.getFullYear(), now.getMonth() - 1, now.getDate());
        filteredData = data.filter(entry => new Date(entry.date) >= oneMonthAgo);
        break;
      case '6M':
        const sixMonthsAgo = new Date(now.getFullYear(), now.getMonth() - 6, now.getDate());
        filteredData = data.filter(entry => new Date(entry.date) >= sixMonthsAgo);
        break;
      case 'YTD':
        const yearStart = new Date(now.getFullYear(), 0, 1);
        filteredData = data.filter(entry => new Date(entry.date) >= yearStart);
        break;
      case '1Y':
        const oneYearAgo = new Date(now.getFullYear() - 1, now.getMonth(), now.getDate());
        filteredData = data.filter(entry => new Date(entry.date) >= oneYearAgo);
        break;
      case '5Y':
        const fiveYearsAgo = new Date(now.getFullYear() - 5, now.getMonth(), now.getDate());
        filteredData = data.filter(entry => new Date(entry.date) >= fiveYearsAgo);
        break;
      case 'MAX':
      default:
        filteredData = data;
        break;
    }

    return filteredData;
  };

  const handleTimeframeChange = (timeframe) => {
    setSelectedTimeframe(timeframe);
    resetZoom(); // Reset zoom when timeframe changes
  };

  const resetZoom = () => {
    if (chartRef.current) {
      chartRef.current.resetZoom();
    }
  };

  if (loading) {
    return (
      <Box sx={{
        height: isMobile ? '300px' : isTablet ? '400px' : '500px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}>
        <CircularProgress />
      </Box>
    );
  }

  if (error || !chartData) {
    return (
      <Box sx={{
        height: isMobile ? '300px' : isTablet ? '400px' : '500px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}>
        <Typography variant="h6" sx={{ color: 'text.secondary' }}>
          No chart data available
        </Typography>
        <Typography variant="body2" sx={{ color: 'text.secondary', mt: 1 }}>
          Please try again later
        </Typography>
      </Box>
    );
  }

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      zoom: {
        zoom: {
          wheel: { enabled: true },
          pinch: { enabled: true },
          mode: 'x',
        },
        pan: {
          enabled: true,
          mode: 'x',
        },
      },
      legend: {
        display: false,
      }
    },
    interaction: {
      intersect: false,
      mode: 'index',
    },
    scales: {
      x: {
        display: false,
      },
      y: {
        grid: {
          color: theme.palette.mode === 'dark'
            ? 'rgba(255, 255, 255, 0.1)'
            : 'rgba(0, 0, 0, 0.1)',
        },
        ticks: {
          color: theme.palette.text.secondary,
        }
      }
    },
    elements:
    {
      line: {
        tension: 0.1
      },
      point: {
        radius: 0,
        hoverRadius: 0
      }
    }
  };


  return (
    <Paper
      elevation={3}
      sx={{
        p: { xs: 1, sm: 2 },
        height: '100%', // Changed from fixed height to 100%
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Box sx={{
        flex: 1,
        minHeight: 0, // Important for proper chart scaling
        width: '100%',
        position: 'relative',
        height: 'calc(100% - 50px)' // Subtract approximate height of buttons
      }}>
        <Line
          ref={chartRef}
          data={chartData}
          options={{
            ...options,
            maintainAspectRatio: false,
            responsive: true
          }}
        />
      </Box>

      <Box sx={{
        display: 'flex',
        flexWrap: 'wrap',
        gap: 1,
        justifyContent: 'center',
        mt: 2,
        px: { xs: 1, sm: 2 }
      }}>
        {timeframes.map((timeframe) => (
          <Button
            key={timeframe}
            variant={selectedTimeframe === timeframe ? 'contained' : 'outlined'}
            onClick={() => handleTimeframeChange(timeframe)}
            size={isMobile ? 'small' : 'medium'}
            sx={{
              minWidth: isMobile ? '40px' : '60px',
              px: { xs: 1, sm: 2 },
            }}
          >
            {timeframe}
          </Button>
        ))}
      </Box>
    </Paper>
  );
};

export default StockChart;
