import React, { useState, useEffect } from 'react';
import {
  Modal,
  Box,
  Typography,
  Button,
  IconButton,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  ListItemSecondaryAction,
  CircularProgress,
  useTheme,
  Snackbar,
  Alert,
  Tooltip,
  TextField
} from '@mui/material';
import {
  Close as CloseIcon,
  CheckCircle as CheckCircleIcon,
  RadioButtonUnchecked as UncheckedIcon,
  DeleteOutline as DeleteIcon,
  Add as AddIcon
} from '@mui/icons-material';
import axios from '../config/axiosConfig';

const AddToWatchlistModal = ({ 
  open, 
  onClose, 
  asset = {},
  userId,
  onSuccess,
  assetType = 'stock'
}) => {
  const theme = useTheme();
  const [selectedWatchlists, setSelectedWatchlists] = useState(new Set());
  const [watchlistData, setWatchlistData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isRemoving, setIsRemoving] = useState(false);
  const [actionMessage, setActionMessage] = useState({ text: '', severity: 'success' });
  const [showMessage, setShowMessage] = useState(false);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [newWatchlistName, setNewWatchlistName] = useState('');
  const [isCreateError, setIsCreateError] = useState(false);
  
  // Reset states when modal opens
  useEffect(() => {
    if (open && userId) {
      fetchWatchlistData();
      setActionMessage({ text: '', severity: 'success' });
      setShowMessage(false);
      setNewWatchlistName('');
      setIsCreateError(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, userId]);

  const showActionMessage = (message, severity = 'success') => {
    setActionMessage({ text: message, severity });
    setShowMessage(true);
  };

  const getAssetCount = (watchlist) => {
    if (!watchlist.assets) return 0;
    return watchlist.assets.length;
  };

  const isAssetInWatchlist = (watchlist) => {
    if (!watchlist.assets) return false;
    return watchlist.assets.some(
      existingAsset => existingAsset.symbol === asset.symbol
    );
  };

  const getAssetIndex = (watchlist) => {
    if (!watchlist.assets) return -1;
    return watchlist.assets.findIndex(
      existingAsset => existingAsset.symbol === asset.symbol
    );
  };

  const initializeSelectedWatchlists = (watchlists) => {
    const initialSelected = new Set();
    watchlists.forEach(watchlist => {
      if (isAssetInWatchlist(watchlist)) {
        initialSelected.add(watchlist._id);
      }
    });
    setSelectedWatchlists(initialSelected);
  };

  const fetchWatchlistData = async () => {
    try {
      setIsLoading(true);
      const response = await axios.get(`/api/watchlists/${userId}`);
      setWatchlistData(response.data);
      initializeSelectedWatchlists(response.data);
    } catch (error) {
      showActionMessage('Error fetching watchlists', 'error');
      console.error('Error fetching watchlist data:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleCreateWatchlist = async () => {
    if (!newWatchlistName.trim()) {
      setIsCreateError(true);
      return;
    }

    try {
      setIsLoading(true);
      const response = await axios.post('/api/watchlists', {
        userId,
        assets: [],
        name: newWatchlistName.trim(),
      });

      if (response.data) {
        setWatchlistData(prev => [...prev, response.data]);
        showActionMessage('Watchlist created successfully');
        setShowCreateModal(false);
        setNewWatchlistName('');
      }
    } catch (error) {
      if (error.response?.status === 403) {
        showActionMessage('You have reached the limit for your current plan.', 'error');
      } else {
        showActionMessage('Error creating watchlist', 'error');
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleRemoveFromWatchlist = async (watchlistId, event) => {
    event.stopPropagation();
    
    try {
      setIsRemoving(true);
      const watchlist = watchlistData.find(w => w._id === watchlistId);
      const assetIndex = getAssetIndex(watchlist);
      
      if (assetIndex === -1) {
        throw new Error('Asset not found in watchlist');
      }

      const response = await axios.delete(`/api/watchlist/${watchlistId}/${assetIndex}`);

      if (response.status === 200) {
        setSelectedWatchlists(prev => {
          const newSet = new Set(prev);
          newSet.delete(watchlistId);
          return newSet;
        });

        setWatchlistData(prev => prev.map(watchlist => {
          if (watchlist._id === watchlistId) {
            return {
              ...watchlist,
              assets: watchlist.assets.filter((_, index) => index !== assetIndex)
            };
          }
          return watchlist;
        }));

        showActionMessage(`${asset.symbol} removed from watchlist!`);
        
        if (onSuccess) {
          onSuccess(`${asset.symbol} removed from watchlist!`);
        }
      }
    } catch (error) {
      showActionMessage('Error removing asset from watchlist', 'error');
      console.error('Error removing asset:', error);
    } finally {
      setIsRemoving(false);
    }
  };

  const handleWatchlistToggle = async (watchlistId) => {
    if (watchlistData.find(w => w._id === watchlistId && isAssetInWatchlist(w))) {
      return;
    }

    try {
      setIsLoading(true);
      
      const response = await axios.post('/api/watchlist/addtowatchlistfromdaily', {
        watchlistId,
        asset,
        type: assetType
      });

      if (response.status === 201) {
        setSelectedWatchlists(prev => {
          const newSet = new Set(prev);
          newSet.add(watchlistId);
          return newSet;
        });
        
        setWatchlistData(prev => prev.map(watchlist => {
          if (watchlist._id === watchlistId) {
            return {
              ...watchlist,
              assets: [...(watchlist.assets || []), { ...asset, type: assetType }]
            };
          }
          return watchlist;
        }));
        
        showActionMessage(`${asset.symbol} added to watchlist!`);
        
        if (onSuccess) {
          onSuccess(`${asset.symbol} added to watchlist!`);
        }
      }
    } catch (error) {
      if (error.response?.status === 403) {
        showActionMessage('You have reached the limit for your current plan.', 'error');
      } else if (error.response?.status === 409) {
        showActionMessage('Asset already in watchlist.', 'error');
      } else {
        showActionMessage('Error adding asset to watchlist.', 'error');
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleClose = () => {
    onClose();
  };

  const renderCreateWatchlistForm = () => (
    <Box sx={{ p: 3 }}>
      <TextField
        autoFocus
        fullWidth
        label="Watchlist Name"
        value={newWatchlistName}
        onChange={(e) => {
          setNewWatchlistName(e.target.value);
          setIsCreateError(false);
        }}
        error={isCreateError}
        helperText={isCreateError ? "Name cannot be empty" : ""}
        sx={{ mb: 2 }}
      />
      <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 1 }}>
        <Button 
          onClick={() => setShowCreateModal(false)}
          disabled={isLoading}
        >
          Cancel
        </Button>
        <Button 
          variant="contained" 
          onClick={handleCreateWatchlist}
          disabled={isLoading || !newWatchlistName.trim()}
        >
          Create
        </Button>
      </Box>
    </Box>
  );

  const renderEmptyState = () => (
    <Box sx={{ 
      display: 'flex', 
      flexDirection: 'column', 
      alignItems: 'center',
      p: 3,
      textAlign: 'center'
    }}>
      <Typography variant="body1" sx={{ mb: 2 }}>
        You don't have any watchlists yet.
      </Typography>
      <Button
        variant="contained"
        startIcon={<AddIcon />}
        onClick={() => setShowCreateModal(true)}
      >
        Create Your First Watchlist
      </Button>
    </Box>
  );

  return (
    <>
      <Modal 
        open={open} 
        onClose={handleClose}
        aria-labelledby="add-to-watchlist-modal"
      >
        <Box sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '100%',
          maxWidth: '500px',
          bgcolor: 'background.paper',
          borderRadius: 2,
          boxShadow: theme.shadows[24],
          outline: 'none',
          maxHeight: '90vh',
          display: 'flex',
          flexDirection: 'column',
        }}>
          {/* Header */}
          <Box sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            p: 2,
            borderBottom: `1px solid ${theme.palette.divider}`,
          }}>
            <Typography variant="h6" sx={{ fontWeight: 600 }}>
              {showCreateModal ? "Create Watchlist" : "Manage Watchlists"}
            </Typography>
            <IconButton 
              onClick={handleClose}
              size="small"
              sx={{ color: 'text.secondary' }}
            >
              <CloseIcon />
            </IconButton>
          </Box>

          {/* Content */}
          <Box sx={{ 
            flexGrow: 1,
            overflowY: 'auto',
          }}>
            {asset.symbol && !showCreateModal && (
              <Box sx={{ p: 2, pb: 0 }}>
                <Typography variant="subtitle1" sx={{ fontWeight: 500 }}>
                  {asset.symbol}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {asset.name}
                </Typography>
              </Box>
            )}

            {isLoading ? (
              <Box sx={{ display: 'flex', justifyContent: 'center', p: 4 }}>
                <CircularProgress size={24} />
              </Box>
            ) : showCreateModal ? (
              renderCreateWatchlistForm()
            ) : watchlistData.length === 0 ? (
              renderEmptyState()
            ) : (
              <>
                <List sx={{ width: '100%', pt: 0 }}>
                  {watchlistData.map((watchlist) => {
                    const isAssetPresent = isAssetInWatchlist(watchlist);
                    const assetCount = getAssetCount(watchlist);
                    
                    return (
                      <ListItem
                        key={watchlist._id}
                        button
                        onClick={() => handleWatchlistToggle(watchlist._id)}
                        sx={{
                          borderRadius: 1,
                          '&:hover': {
                            bgcolor: 'action.hover',
                          },
                          pr: 8,
                        }}
                      >
                        <ListItemIcon>
                          {isAssetPresent || selectedWatchlists.has(watchlist._id) ? (
                            <CheckCircleIcon color="primary" />
                          ) : (
                            <UncheckedIcon sx={{ color: 'text.secondary' }} />
                          )}
                        </ListItemIcon>
                        <ListItemText 
                          primary={watchlist.name}
                          secondary={`${assetCount} ${assetCount === 1 ? 'Asset' : 'Assets'}`}
                          primaryTypographyProps={{
                            sx: { fontWeight: 500 }
                          }}
                          secondaryTypographyProps={{
                            sx: { color: 'text.secondary' }
                          }}
                        />
                        {isAssetPresent && (
                          <ListItemSecondaryAction>
                            <Tooltip title="Remove from watchlist">
                              <IconButton 
                                edge="end" 
                                aria-label="remove"
                                onClick={(e) => handleRemoveFromWatchlist(watchlist._id, e)}
                                disabled={isRemoving}
                                color="error"
                                size="small"
                              >
                                <DeleteIcon />
                              </IconButton>
                            </Tooltip>
                          </ListItemSecondaryAction>
                        )}
                      </ListItem>
                    );
                  })}
                </List>

                <Box sx={{ p: 2, textAlign: 'center' }}>
                  <Button
                    startIcon={<AddIcon />}
                    onClick={() => setShowCreateModal(true)}
                  >
                    Create New Watchlist
                  </Button>
                </Box>
              </>
            )}
          </Box>

          {/* Footer */}
          {!showCreateModal && watchlistData.length > 0 && (
            <Box sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              gap: 1,
              p: 2,
              borderTop: `1px solid ${theme.palette.divider}`,
            }}>
              <Button
                variant="outlined"
                onClick={handleClose}
                sx={{ color: 'text.secondary' }}
              >
                Close
              </Button>
            </Box>
          )}
        </Box>
      </Modal>

      {/* Action Message Snackbar */}
      <Snackbar 
        open={showMessage} 
        autoHideDuration={3000} 
        onClose={() => setShowMessage(false)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        <Alert 
          onClose={() => setShowMessage(false)} 
          severity={actionMessage.severity} 
          sx={{ width: '100%' }}
        >
          {actionMessage.text}
        </Alert>
      </Snackbar>
    </>
  );
};

export default AddToWatchlistModal;