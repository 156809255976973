import React, { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { useAuth } from './AuthContext';
import axios from '../config/axiosConfig';
import {
  Container,
  CircularProgress,
  Box,
  Typography,
  Grid
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import useExchanges from '../hooks/useExchanges';
import TitleAndInfo from './TitleAndInfo';
import { formatNumber } from '../utils/numberUtils';
import { exportToCsv } from '../utils/exportToCsv';
import QuoteFilterSystem from './QuoteFilterSystem';
import currencySymbols from '../utils/currencySymbols';

const QuotesScreener = () => {
  const { user } = useAuth();
  const exchanges = useExchanges();
  const theme = useTheme();
  const [loading, setLoading] = useState(false);
  const [stocks, setStocks] = useState([]);
  const baseUrl = window.location.origin;

  const titleTop = "Advanced Quote Screener: ";
  const title = "Comprehensive Market Data for Precision Trading";
  const info = "The Advanced Stock Quote Screener offers in-depth stock data. This tool provides essential metrics. Use this screener to access vital stock information, refine your trading strategies, and make well-informed investment decisions. Perfect for traders and investors seeking detailed market insights for effective trading.";

  const handleSubmit = async (filterData) => {
    setStocks([]);
    setLoading(true);

    try {
      const response = await axios.post('/api/filteredquotes', filterData);
      setStocks(response.data.data);
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleClearFilters = () => {
    setStocks([]);
  };

  const handleExport = () => {
    exportToCsv('advancedscreener.csv', columns, stocks);
  };

  const columns = [
    {
      field: 'symbol',
      headerName: 'Company',
      minWidth: 200,
      renderCell: (params) => (
        <div>
          <RouterLink
            to={`${baseUrl}/asset/${params.row.symbol}`}
            style={{ color: theme.palette.primary.main, textDecoration: 'none', fontWeight: 'bold' }}
          >
            {params.row.symbol}
          </RouterLink>
          <div style={{ fontSize: '0.9em', color: '#888', fontWeight: 'bold' }}>{params.row.name}</div>
        </div>
      ),
      align: 'left',
      headerAlign: 'left',
    },
    { 
      field: 'price', 
      headerName: 'Price', 
      flex: 1, 
      minWidth: 120, 
      renderCell: (params) => {
        const symbol = currencySymbols[params.row.currency] || '';
        return `${symbol}${formatNumber(params.value)}`;
      }
    },
    {
      field: 'changesPercentage',
      headerName: 'Changes Percentage',
      flex: 1,
      minWidth: 150,
      valueFormatter: (params) => formatNumber(params.value),
      renderCell: (params) => (
        <div style={{ color: params.value > 0 ? theme.palette.positive.main : params.value < 0 ? theme.palette.negative.main : theme.palette.lightGray.main }}>
          {formatNumber(params.value)}
        </div>
      ),
    },
    {
      field: 'change',
      headerName: 'Change',
      flex: 1,
      minWidth: 120,
      valueFormatter: (params) => formatNumber(params.value),
      renderCell: (params) => (
        <div style={{ color: params.value > 0 ? theme.palette.positive.main : params.value < 0 ? theme.palette.negative.main : theme.palette.lightGray.main }}>
          {formatNumber(params.value)}
        </div>
      ),
    },
    { field: 'dayLow', headerName: 'Day Low', flex: 1, minWidth: 120, valueFormatter: (params) => formatNumber(params.value) },
    { field: 'dayHigh', headerName: 'Day High', flex: 1, minWidth: 120, valueFormatter: (params) => formatNumber(params.value) },
    { field: 'yearHigh', headerName: 'Year High', flex: 1, minWidth: 120, valueFormatter: (params) => formatNumber(params.value) },
    { field: 'yearLow', headerName: 'Year Low', flex: 1, minWidth: 120, valueFormatter: (params) => formatNumber(params.value) },
    {
      field: 'marketCap',
      headerName: 'Market Cap',
      flex: 1.5,
      minWidth: 150,
      valueFormatter: (params) => formatNumber(params.value),
    },
    { field: 'exchange', headerName: 'Exchange', flex: 1, minWidth: 120 },
    { field: 'volume', headerName: 'Volume', flex: 1, minWidth: 120, valueFormatter: (params) => formatNumber(params.value) },
    { field: 'avgVolume', headerName: 'Avg Volume', flex: 1, minWidth: 120, valueFormatter: (params) => formatNumber(params.value) },
    { field: 'open', headerName: 'Open', flex: 1, minWidth: 120, valueFormatter: (params) => formatNumber(params.value) },
    { field: 'previousClose', headerName: 'Previous Close', flex: 1, minWidth: 120, valueFormatter: (params) => formatNumber(params.value) },
    { field: 'eps', headerName: 'EPS', flex: 1, minWidth: 120, valueFormatter: (params) => formatNumber(params.value) },
    { field: 'pe', headerName: 'PE', flex: 1, minWidth: 120, valueFormatter: (params) => formatNumber(params.value) },
    { field: 'sharesOutstanding', headerName: 'Shares Out.', flex: 1.5, minWidth: 150, valueFormatter: (params) => formatNumber(params.value) },
  ];

  return (
    <div>
      <Container maxWidth="xl" sx={{ mt: 4 }}>
        <TitleAndInfo titleTop={titleTop} title={title} info={info} />

        <QuoteFilterSystem
          onSearch={handleSubmit}
          onClear={handleClearFilters}
          onExport={handleExport}
          showExport={user.plan.name === 'Premium' && stocks.length > 0}
          exchanges={exchanges}
        />
      </Container>

      {loading && (
        <Box sx={{ padding: 2, borderRadius: '5px' }}>
          <Grid container justifyContent="center">
            <CircularProgress />
          </Grid>
        </Box>
      )}

      {!loading && stocks.length === 0 && (
        <Box sx={{ padding: 2, borderRadius: '5px', textAlign: 'center', marginTop: 4 }}>
          <Typography variant="body1">No results found.</Typography>
        </Box>
      )}

      {stocks.length > 0 && (
        <Container maxWidth="xxl" sx={{ mt: 4 }}>
          <DataGrid
            rows={stocks}
            columns={columns}
            getRowId={(row) => row.symbol}
            disableColumnMenu
            pagination
            paginationPosition="middle"
            initialState={{
              pagination: { paginationModel: { pageSize: 10 } },
            }}
            pageSizeOptions={[5, 10, 25]}
            sx={{
              '& .MuiDataGrid-cell': {
                fontSize: '1rem',
              },
              '& .MuiDataGrid-columnHeaders': {
                fontSize: '0.8rem',
              },
            }}
          />
        </Container>
      )}
    </div>
  );
};

export default QuotesScreener;