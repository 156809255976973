import React from 'react';
import { IconButton } from '@mui/material';
import { Instagram, LinkedIn, Reddit } from '@mui/icons-material';

const SocialIcons = () => {
    return (
        <div>
            <IconButton href="https://www.instagram.com/marketrodeo" target="_blank" color="inherit">
                <Instagram />
            </IconButton>
           {/* <IconButton href="https://www.facebook.com" target="_blank" color="inherit">
                <Facebook />
            </IconButton> */ }
            <IconButton href="https://www.linkedin.com/company/marketrodeo" target="_blank" color="inherit">
                <LinkedIn />
            </IconButton>
            <IconButton href="https://www.reddit.com/r/marketrodeo" target="_blank" color="inherit">
                <Reddit />
            </IconButton>
        </div>
    );
};

export default SocialIcons;
