import React from 'react';
import { Container, Typography, Box, Button, Link } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import logo from '../images/rodeoLogo.png';

const NotFound = () => {
  const theme = useTheme();
  const emailAddress = 'support@marketrodeo.com';

  return (
    <Container maxWidth="xl">
    <Box textAlign="center" mt={10} mb={5}>
      <Typography variant="h4" component="h1" color="white" fontWeight="bold" gutterBottom>
        Page Not Found - 404
      </Typography>
      <Link href="/" display="flex" justifyContent="center" alignItems="center">
        <img src={logo} alt="Logo" style={{ width: '80px', margin: 'auto' }} />
      </Link>
      <Typography variant="body1" color="white" mt={4} fontWeight="medium">
        The page you're looking for doesn't exist. please report it to us via
        <Link href={`mailto:${emailAddress}`} sx={{ ml: 1, color: '#1E90FF', '&:hover': { color: 'white', textDecoration: 'underline' } }}>
          {emailAddress}
        </Link>.
      </Typography>
      <Button
        href="/"
        variant="contained"
        color="primary"
        sx={{ mt: 4, py: 1.5, px: 4, borderRadius: 2, backgroundColor: theme.palette.primary.main, '&:hover': { backgroundColor: 'darkpurple' } }}
      >
        Back to Safety
      </Button>
    </Box>
    </Container>
  );
};

export default NotFound;
