import React, { useState, useEffect } from 'react';
import { useAuth } from './AuthContext';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Line } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import zoomPlugin from 'chartjs-plugin-zoom';
import {
    Box,
    Paper,
    Typography,
    CircularProgress,
    useTheme,
    useMediaQuery,
    Alert,
    Drawer,
    Fab
} from '@mui/material';
import {
    Compare as CompareIcon
} from '@mui/icons-material';
import axios from '../config/axiosConfig';
import withPlanAccess from './WithPlanAccess';
import ControlPanel from './ControlPanel';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    zoomPlugin
);

const timeframes = ['1M', '6M', 'YTD', '1Y', '5Y'];

const colors = [
    '#1976d2', // blue
    '#0eb35b', // green
    '#e62333', // red
    '#757575', // gray
    '#ffd700', // yellow
    '#9c27b0', // purple
    '#ff9800', // orange
    '#00bcd4', // cyan
];

const StockComparison = () => {
    const [searchParams] = useSearchParams();
    const symbolsParam = searchParams.get('symbols');
    const timeframeParam = searchParams.get('timeframe');

    const { user } = useAuth();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const isTablet = useMediaQuery(theme.breakpoints.down('md'));
    const navigate = useNavigate();

    const maxSymbols = user?.planName === 'Premium' ? 8 : 4;

    const [symbols, setSymbols] = useState(() => {
        if (symbolsParam) {
            const urlSymbols = symbolsParam.split(',');
            return urlSymbols.slice(0, maxSymbols);
        }
        return [];
    });

    const [selectedTimeframe, setSelectedTimeframe] = useState(() => {
        return timeframes.includes(timeframeParam) ? timeframeParam : 'YTD';
    });

    // Add cache state
    const [dataCache, setDataCache] = useState({});
    const [chartData, setChartData] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [drawerOpen, setDrawerOpen] = useState(false);

    const getCacheKey = (symbols, timeframe) => `${symbols.sort().join(',')}_${timeframe}`;

    useEffect(() => {
        const params = new URLSearchParams();
        if (symbols.length > 0) {
            params.set('symbols', symbols.join(','));
        }
        params.set('timeframe', selectedTimeframe);
        navigate(`?${params.toString()}`, { replace: true });

        if (symbols.length > 0) {
            const cacheKey = getCacheKey(symbols, selectedTimeframe);
            if (dataCache[cacheKey]) {
                const processedData = processChartData(dataCache[cacheKey]);
                setChartData(processedData);
            } else {
                fetchComparisonData();
            }
        } else {
            setChartData(null);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [symbols, selectedTimeframe, navigate]);

    const fetchComparisonData = async () => {
        try {
            setLoading(true);
            setError(null);

            const response = await axios.get('/api/stocks-comparison', {
                params: {
                    symbols: symbols.join(','),
                    timeframe: selectedTimeframe
                }
            });

            const cacheKey = getCacheKey(symbols, selectedTimeframe);
            setDataCache(prevCache => ({
                ...prevCache,
                [cacheKey]: response.data
            }));

            const processedData = processChartData(response.data);
            setChartData(processedData);
        } catch (error) {
            setError(error.response?.data?.error || 'Failed to fetch comparison data');
        } finally {
            setLoading(false);
        }
    };

    const processChartData = (data) => {
        if (!data.length) return null;

        const allDates = new Set();
        data.forEach(symbol => {
            symbol.data.forEach(point => {
                allDates.add(point.date);
            });
        });

        const sortedDates = Array.from(allDates).sort();

        let minValue = 0, maxValue = 0;
        data.forEach(symbol => {
            symbol.data.forEach(point => {
                minValue = Math.min(minValue, point.performance);
                maxValue = Math.max(maxValue, point.performance);
            });
        });

        minValue = Math.floor(minValue / 20) * 20;
        maxValue = Math.ceil(maxValue / 20) * 20;

        return {
            labels: sortedDates,
            datasets: data.map((symbol, index) => ({
                label: symbol.symbol,
                data: sortedDates.map(date => {
                    const point = symbol.data.find(p => p.date === date);
                    return point ? point.performance : null;
                }),
                borderColor: colors[index % colors.length],
                backgroundColor: colors[index % colors.length],
                borderWidth: 1.5,
                pointRadius: 0,
                tension: 0.1,
                fill: false
            }))
        };
    };

    const handleAddSymbol = (symbol) => {
        if (!symbol || typeof symbol !== 'string' || symbols.length >= maxSymbols) return;

        const formattedSymbol = symbol.toUpperCase().trim();
        if (!symbols.includes(formattedSymbol)) {
            setDataCache({});
            setSymbols([...symbols, formattedSymbol]);
            if (isMobile) {
                setDrawerOpen(false);
            }
        }
    };

    const handleRemoveSymbol = (symbolToRemove) => {
        setDataCache({});
        setSymbols(symbols.filter(symbol => symbol !== symbolToRemove));
    };

    const handleClearAll = () => {
        setSymbols([]);
        setChartData(null);
        setDataCache({});
    };

    const toggleDrawer = () => {
        setDrawerOpen(!drawerOpen);
    };

    const chartOptions = {
        responsive: true,
        maintainAspectRatio: false,
        animation: false,
        plugins: {
            legend: {
                position: 'top',
                align: 'center',
                labels: {
                    boxWidth: 12,
                    padding: 15,
                    color: theme.palette.text.primary,
                    font: {
                        size: 11
                    }
                }
            },
            tooltip: {
                mode: 'index',
                intersect: false,
                callbacks: {
                    title: function (context) {
                        const date = new Date(context[0].label);
                        return date.toLocaleDateString('en-US', {
                            year: 'numeric',
                            month: 'short',
                            day: 'numeric'
                        });
                    },
                    label: function (context) {
                        return `${context.dataset.label}: ${context.parsed.y.toFixed(2)}%`;
                    }
                },
                padding: 10,
                backgroundColor: theme.palette.mode === 'dark' ? '#424242' : '#fff',
                titleColor: theme.palette.text.primary,
                bodyColor: theme.palette.text.primary,
                borderColor: theme.palette.divider,
                borderWidth: 1,
                displayColors: true
            },
            datalabels: {
                display: false
            },
            zoom: {
                zoom: {
                    wheel: {
                        enabled: true
                    },
                    pinch: {
                        enabled: true
                    },
                    mode: 'x',
                },
                pan: {
                    enabled: true,
                    mode: 'x'
                }
            }
        },
        scales: {
            x: {
                grid: {
                    display: true,
                    color: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.1)' : 'rgba(0, 0, 0, 0.1)',
                    drawBorder: false
                },
                ticks: {
                    maxRotation: 0,
                    autoSkip: true,
                    maxTicksLimit: 8,
                    color: theme.palette.text.secondary,
                    font: {
                        size: 11
                    },
                    callback: function (value, index, ticks) {
                        const date = new Date(this.getLabelForValue(value));
                        return date.toLocaleDateString('en-US', {
                            month: 'short',
                            year: '2-digit'
                        });
                    }
                }
            },
            y: {
                position: 'right',
                grid: {
                    display: true,
                    color: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.1)' : 'rgba(0, 0, 0, 0.1)',
                    drawBorder: false
                },
                ticks: {
                    color: theme.palette.text.secondary,
                    callback: (value) => `${value}%`,
                    stepSize: 20,
                    font: {
                        size: 11
                    }
                },
                suggestedMin: -100,
                suggestedMax: 60
            }
        },
        layout: {
            padding: {
                top: 30
            }
        },
        interaction: {
            mode: 'index',
            intersect: false
        },
        elements: {
            line: {
                tension: 0.1
            },
            point: {
                radius: 0,
                hoverRadius: 0
            }
        }
    };

    if (!user || user.plan.name === 'Free') {
        return <withPlanAccess />;
    }

    return (
        <Box sx={{ position: 'relative', height: '90vh', overflow: 'hidden' }}>
            {isMobile && (
                <Fab
                    color="primary"
                    aria-label="compare"
                    onClick={toggleDrawer}
                    sx={{
                        position: 'absolute',
                        bottom: 16,
                        right: 16,
                        zIndex: 1000
                    }}
                >
                    <CompareIcon />
                </Fab>
            )}

            <Box sx={{ display: 'flex', gap: 2, height: '100%' }}>
                {isMobile ? (
                    <Drawer
                        anchor="right"
                        open={drawerOpen}
                        onClose={toggleDrawer}
                        PaperProps={{
                            sx: {
                                width: '85%',
                                maxWidth: '360px',
                                bgcolor: theme.palette.background.paper
                            }
                        }}
                    >
                        <ControlPanel
                            onAddSymbol={handleAddSymbol}
                            onRemoveSymbol={handleRemoveSymbol}
                            symbols={symbols}
                            maxSymbols={maxSymbols}
                            selectedTimeframe={selectedTimeframe}
                            setSelectedTimeframe={setSelectedTimeframe}
                            toggleDrawer={toggleDrawer}
                            isMobile={isMobile}
                            theme={theme}
                            onClearAll={handleClearAll}
                        />
                    </Drawer>
                ) : (
                    <Paper
                        elevation={0}
                        sx={{
                            width: isTablet ? '240px' : '300px',
                            height: '100%',
                            bgcolor: theme.palette.background.paper,
                            border: `1px solid ${theme.palette.divider}`,
                            overflow: 'auto'
                        }}
                    >
                        <ControlPanel
                            onAddSymbol={handleAddSymbol}
                            onRemoveSymbol={handleRemoveSymbol}
                            symbols={symbols}
                            maxSymbols={maxSymbols}
                            selectedTimeframe={selectedTimeframe}
                            setSelectedTimeframe={setSelectedTimeframe}
                            toggleDrawer={toggleDrawer}
                            isMobile={isMobile}
                            theme={theme}
                            onClearAll={handleClearAll}
                        />
                    </Paper>
                )}

                <Paper
                    elevation={0}
                    sx={{
                        flexGrow: 1,
                        height: '100%',
                        p: 2,
                        display: 'flex',
                        flexDirection: 'column',
                        bgcolor: theme.palette.background.paper,
                        border: `1px solid ${theme.palette.divider}`,
                        overflow: 'hidden'
                    }}
                >
                    {loading && (
                        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                            <CircularProgress />
                        </Box>
                    )}

                    {error && (
                        <Alert severity="error" sx={{ mb: 2 }}>{error}</Alert>
                    )}

                    {!loading && !error && chartData && (
                        <Box sx={{ flexGrow: 1, width: '100%', height: '100%', minHeight: 0 }}>
                            <Line data={chartData} options={chartOptions} />
                        </Box>
                    )}

                    {!loading && !error && !chartData && symbols.length === 0 && (
                        <Box sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                            height: '100%',
                            gap: 2
                        }}>
                            <CompareIcon sx={{ fontSize: 48, color: 'text.secondary', opacity: 0.5 }} />
                            <Typography variant="h6" color="text.secondary">
                                Start Comparing Assets
                            </Typography>
                            <Typography variant="body2" color="text.secondary" align="center">
                                Add symbols using the {isMobile ? 'button below' : 'panel on the left'} to begin comparison
                            </Typography>
                        </Box>
                    )}
                </Paper>
            </Box>
        </Box>
    );
};

const allowedPlans = ['Basic', 'Premium'];
export default withPlanAccess(StockComparison, allowedPlans);